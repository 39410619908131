* {
    transition: 0.3s all;
}

#text-slider:not(.tns-slider),
#animation-slider:not(.tns-slider) {
    display: none;
}

#text-slider {
    @media (max-width: $screen-sm) {
        padding-top: 88vw;
    }

    @media (max-width: $screen-mid) and (min-width: $screen-sm) {
        padding-top: 55vw;
    }
}

@keyframes cloud {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-150px, 85px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes cloud-reverse {
    0% {
        transform: translate(-150px, 85px);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-150px, 85px);
    }
}

.map {
    background: none;
    width: 100%;
    overflow: hidden;
    height: auto;
    padding: 0 5%;
    box-sizing: border-box;

    @media (min-width: $screen-sm) {
        padding: 0 20%;
    }

    @media (min-width: $screen-mid) {
        overflow: visible;
        padding: 90px 74px 96px 30px;
    }

    @media (min-width: $screen-lg) {
        padding: 90px 49px 96px 44px;
    }

    @media (min-width: $screen-1366) {
        padding: 90px 49px 96px 40px;
    }

    &-container {
        height: auto;

        @media (min-width: $screen-mid) {
            height: 300vh;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        direction: ltr;
        overflow: hidden;

        @media (min-width: $screen-mid) {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            flex-direction: row;
            height: 100vh;
        }

        @media (max-width: $screen-mid) {
            position: relative;
        }
    }

    &-animation-container {
        width: 100%;
        overflow: hidden;

        @media (max-width: $screen-mid) {
            position: absolute;
        }

        .map-animation-mobile {
            display: block;

            @media (min-width: $screen-mid) {
                display: none;
            }
        }

        .map-animation-desktop {
            display: none;

            @media (min-width: $screen-mid) {
                display: block;
            }
        }

        svg {
            width: 100%;
            height: auto;

            .anim {
                &-districts {
                    #District_1 {
                        transform: translate(0, -400px);
                        opacity: 0;
                    }

                    #District_2,
                    #District_7 {
                        transform: translate(400px, 200px);
                        opacity: 0;
                    }

                    #District_3 {
                        transform: translate(-400px, -200px);
                        opacity: 0;
                    }

                    #District_4,
                    #District_5 {
                        transform: translate(-400px, 200px);
                        opacity: 0;
                    }

                    #District_6 {
                        transform: translate(400px, -200px);
                        opacity: 0;
                    }
                }

                &-trees {
                    transform: translateY(100px);
                    opacity: 0;
                }

                &-buildings1 {
                    transform: translateY(100px);
                    opacity: 0;
                }

                &-buildings2 {
                    transform: translateY(100px);
                    opacity: 0;
                }

                &-people {
                    #Character_mother_with_son,
                    #Character_woman_walking,
                    #Character_with_disability {
                        transform: translate(-100px, -50px);
                        opacity: 0;
                    }

                    #Character_father_and_son_playing_football,
                    #Character_woman_pouring_flowers,
                    #Character_buisness_man,
                    #Character_family {
                        transform: translate(100px, -50px);
                        opacity: 0;
                    }

                    #Character_pair_with_computers_on_bench,
                    #Character_womans_talking {
                        transform: translate(100px, 50px);
                        opacity: 0;
                    }
                }

                &-vehicle {
                    transition-duration: 500ms, 0ms !important;
                    transform: translate(0, 0) !important;
                    opacity: 0;
                }

                &-cloud {
                    #Cloud_1,
                    #Cloud_2 {
                        opacity: 0;
                    }

                    #Cloud_3,
                    #Cloud_4 {
                        opacity: 0;
                    }
                }

                &-mobile {
                    transform: translate(-50px, 0) !important;
                    opacity: 0;
                }
            }

            #Mobile_anim > * {
                transition-duration: 1s;
            }

            #Buildings_1 {
                transition-delay: 250ms;
            }

            #Trees {
                transition-delay: 500ms;
            }

            #Ludzie_1 *,
            #Ludzie_2 *,
            #Districts *,
            #Trees,
            #Buildings_1,
            #Buildings_2,
            #Lake {
                will-change: transform, opacity;
            }

            #Ludzie_1 *,
            #Ludzie_2 * {
                transition-duration: 1.5s;
            }

            #Trees,
            #Buildings_1,
            #Buildings_2,
            #Lake {
                transition-duration: 1s;
            }

            #Cloud > path {
                will-change: transform, opacity;
                transition-delay: 500ms;
                transition-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }

            #Cloud_1,
            #Cloud_4 {
                animation-name: cloud;
                animation-duration: 50s;
            }

            #Cloud_2,
            #Cloud_3 {
                animation-name: cloud-reverse;
                animation-duration: 70s;
            }

            #Vehicle_1 {
                transition-duration: 500ms, 1.5s;
                transform: translate(250px, -143px);
            }

            #Vehicle_2 {
                transition-duration: 500ms, 2s;
                transform: translate(-300px, 170px);
            }

            #Vehicle_3 {
                transition-duration: 500ms, 2s;
                transform: translate(300px, 170px);
            }

            #Vehicle_4 {
                transition-duration: 500ms, 3s;
                transform: translate(-300px, 170px);
            }

            #Vehicle_1,
            #Vehicle_2,
            #Vehicle_3,
            #Vehicle_4 {
                transition-property: opacity, transform;
                transition-delay: 0ms, 500ms;
                will-change: opacity, transform;
                transition-timing-function: cubic-bezier(0.005, 0.165, 0.835, 1);
            }
        }

        .slider-elements {
            display: none;

            @media (min-width: $screen-lg) {
                display: block;
            }
        }
    }

    .mobile-title {
        font-size: 24px;
        color: $white;
        margin-bottom: 25px;

        @media (min-width: $screen-sm) {
            font-size: 36px;
        }

        @media (min-width: $screen-mid) {
            display: none;
        }
    }

    &-description-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-align: center;
        margin: auto;
        overflow: visible;

        @media (min-width: $screen-mid) {
            text-align: right;
        }

        &__title {
            display: none;

            @media (min-width: $screen-mid) {
                font-size: 36px;
                color: $white;
                display: block;
            }

            @media (min-width: $screen-fm) {
                font-size: 48px;
            }
        }

        &__carousel {
            margin-top: 0;
            margin-bottom: 20px;

            .slider-elements {
                display: flex;
                flex-direction: row-reverse;

                @media (min-width: $screen-mid) {
                    flex-direction: column;
                }
            }

            p {
                font-size: 16px;
                color: $white;
                width: 315px;
                height: 94px;
                margin: 0;
                direction: rtl;

                @media (min-width: $screen-sm) {
                    font-size: 18px;
                    width: 390px;
                    height: 169px;
                }

                @media (min-width: $screen-mid) {
                    width: 438px;
                    height: 130px;
                }

                @media (min-width: $screen-lg) {
                    margin-left: 40px;
                }

                @media (min-width: $screen-1366) {
                    margin-left: 44px;
                }

                @media (min-width: $screen-1920) {
                    font-size: 24px;
                    width: 508px;
                    height: 168px;
                }
            }

            @media (min-width: $screen-sm) {
                margin-top: 38px;
                margin-bottom: 0;
            }
        }

        &__button {
            border: 2px solid $brightTurquoise;
            border-radius: $border-radius-btn;
            font-size: 14px;
            color: $brightTurquoise;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 187px;
            height: 40px;
            margin: 50px auto 0;

            &:hover {
                background-color: $brightTurquoiseTransparent;
                color: $mirage;
            }

            @media (min-width: $screen-sm) {
                font-size: 16px;
                width: 180px;
                height: 46px;
            }

            @media (min-width: $screen-mid) {
                margin: 10px 0 0 auto;
            }
        }
    }

    a,
    a:visited,
    a:hover,
    a:active {
        text-decoration: none;
    }

    .navigation-controls {
        position: relative;
        bottom: 100px;
        padding: 0;

        li {
            border: none;
            border-radius: 60px;
            background: $mineShaft2;
            display: inline-block;
            margin: 4px;
            width: 10px;
            height: 10px;
            pointer-events: none;

            @media (min-width: $screen-mid) {
                border: solid 1px $osloGray;
                background: $mirage;
                display: block;
                margin: 16px 0;
                width: 14px;
                height: 14px;
                pointer-events: auto;
                cursor: pointer;
            }

            @media (min-width: $screen-1920) {
                width: 16px;
                height: 16px;
            }
        }

        li,
        li:visited {
            outline: none;
        }

        .tns-nav-active {
            background-color: $elm2;

            @media (min-width: $screen-mid) {
                background-color: $brightTurquoise;
            }
        }

        @media (min-width: $screen-sm) {
            bottom: 125px;
        }

        @media (min-width: $screen-mid) {
            list-style: none;
            direction: ltr;
            bottom: 0;
            margin: 44px 0 0 44px;
            padding: 0;
        }

        @media (min-width: $screen-1920) {
            margin-left: 52px;
        }
    }

    .scroll-content {
        position: fixed;
        top: 0;
        right: 74px;
        left: 30px;
        transition: none;

        @media (min-width: $screen-lg) {
            right: 49px;
            left: 44px;
        }

        @media (min-width: $screen-1366) {
            right: 49px;
            left: 40px;
        }
    }

    .bottom {
        position: absolute;
        bottom: 0;
        right: 74px;
        left: 30px;

        @media (min-width: $screen-lg) {
            right: 49px;
            left: 44px;
        }

        @media (min-width: $screen-1366) {
            right: 49px;
            left: 40px;
        }
    }
}
