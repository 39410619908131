body.economy,
body.product-page,
body.demography {
    overflow: hidden;

    .fp-tableCell {
        width: 100%;
    }

    // SECTOR CHARTS
    .radar-chart-container,
    .line-chart-container,
    .spending-chart-container,
    .prices-chart-container,
    .consumer-price-chart-container,
    .wholesale-price-chart-container,
    .local-production-chart-container,
    .population-over-years-chart-container,
    .commercial-establishments-chart-container {
        .main-panel {
            @media #{$tablet} {
                .chart-content {
                    flex: 1;
                }
            }
        }

        .categories-dropdown {
            .dropdown-menu {
                li {
                    span {
                        @include radarColors('.text.color-', $categories-colors, false);

                        &.checkbox {
                            .decorator {
                                @include radarBackgroundColors('.color-', $categories-colors);
                            }
                        }
                    }
                }
            }

            .dropdown-component {
                .filter-option {
                    @include radarColors('.color-', $categories-colors, false);
                }
            }

            &.sector-level-2 {
                .dropdown-menu {
                    li {
                        span {
                            @include radarColors('.text.color-', $radar-chart-subsector-group, false);

                            &.checkbox {
                                .decorator {
                                    @include radarBackgroundColors('.color-', $radar-chart-subsector-group);
                                }
                            }
                        }
                    }
                }
                .dropdown-component {
                    .filter-option {
                        @include radarColors('.color-', $radar-chart-subsector-group, false);
                    }
                }
            }
        }

        .year-dropdown {
            .btn-group.bootstrap-select.btn-group .dropdown-menu {
                li {
                    span.text {
                        color: $osloGray;
                    }

                    &.disabled {
                        span.text {
                            color: rgba($osloGray, 0.5);
                        }
                    }
                }
            }
        }

        ._chart_mg_add[data-is-trade-report='1'] {
            .dropdown-menu li span {
                @include radarColors('.text.color-', $trade-report-radar-chart-colors, false);
                @include radarBackgroundColors('.checkbox .decorator.color-', $trade-report-radar-chart-colors);
            }

            .dropdown-toggle span {
                @include radarColors('.filter-option.color-', $trade-report-radar-chart-colors, false);
            }
        }
    }

    // MAP CHARTS
    .map-chart-container,
    .treemap-chart-container,
    .employees-chart-container,
    .licenses-chart-container,
    .tornado-chart-container,
    .household-expenditure-chart-container,
    .private-establishments-chart-container {
        .main-panel {
            @media (min-width: $screen-lg) {
                margin-left: 42px;
            }
            @media (min-width: $screen-fm) {
                margin-left: 63px;
            }

            @media (min-width: $screen-hd) {
                margin-left: 66px;
            }

            .chart-content {
                @media (min-width: $screen-lg) {
                    width: calc(100% - 300px);
                }

                @media (min-width: $screen-1366) {
                    width: calc(100% - 420px);
                }

                @media (min-width: $screen-fm) {
                    width: calc(100% - 340px);
                }

                @media (min-width: $screen-1680) {
                    width: calc(100% - 514px);
                }

                @media (min-width: $screen-hd) {
                    width: calc(100% - 450px);
                }

                @media (min-width: $screen-fhd) {
                    width: calc(100% - 514px);
                }
            }

            .basic-column-description {
                .description {
                    margin-left: 8px;
                }

                @media (max-height: $screen-sm) {
                    margin-bottom: 16px;
                }
            }

            .mobile-column {
                .graph-container {
                    .description {
                        margin-bottom: 16px;

                        @media (min-width: $screen-sm) {
                            margin-bottom: 0;
                        }
                    }
                }

                .owl-dots {
                    margin-top: 0;

                    @media (min-width: $screen-sm) {
                        margin-top: inherit;
                        transform: inherit;
                    }
                }
            }

            @media #{$tablet} {
                .summary-column {
                    flex: 1;

                    .stats-summary {
                        height: 100%;
                    }
                }

                .basic-column-description {
                    margin-bottom: 20px;
                }
            }

            @media #{$tablet-large} {
                .chart-content {
                    .graph-legend {
                        margin-top: 24px;
                        margin-bottom: 56px;
                    }
                }

                .basic-column-description {
                    margin-bottom: 36px;
                }
            }
        }
    }

    @import 'reportsSections/reportSection.scss';

    @import 'reportsSections/highlights.scss';

    @import 'reportsSections/lineChart.scss';

    @import 'reportsSections/mapChart.scss';

    @import 'reportsSections/treemapChart.scss';

    @import 'reportsSections/productMultiLineChart';

    @import 'reportsSections/radarChart.scss';

    @import 'reportsSections/owlCarousel.scss';

    @import 'reportsSections/populationChart.scss';

    @import 'reportsSections/commercialEstablishmentsChart.scss';

    @import 'reportsSections/privateEstablishmentsChart.scss';

    @import 'economy/_basicInfo';

    @import 'economy/_source';
}

// different icon sizes for economy / product page highlights
body.product-page,
body.demography {
    .sector-top {
        .sector-summary {
            .icon {
                height: 52px;

                @media (min-width: $screen-sm) {
                    height: 72px;
                }
            }
        }
    }
}

body.economy {
    .sector-top {
        .sector-summary {
            .icon {
                height: 36px;

                @media (min-width: $screen-sm) {
                    height: 54px;
                }
            }
        }
    }
}

// tornado chart is used in tile charts
body.demography,
body.all-reports,
body.homepage {
    @import 'reportsSections/tornadoChart.scss';
}
