.wholesale-price-chart-container,
.local-production-chart-container {
    .category-element {
        overflow: hidden;
    }

    .category-element-marker {
        position: relative;
    }

    .category-element-container {
        padding-top: 10px;
        padding-right: 13px;
        padding-bottom: 1px;
        margin-top: -10px;
        margin-right: 7px;
        border-right: 1px solid $charcoalGrey;

        .category-element-container {
            padding-right: 15px;
            margin-right: 5px;
        }
    }

    .category-filter {
        .category-lists {
            .categories-unit-section {
                margin-bottom: 20px;

                .section-name {
                    color: $white;
                    font-size: 16px;
                    line-height: 1;
                    margin-bottom: 8px;
                }
            }

            .category-element-line {
                position: absolute;
                width: 1px;
                height: 48px;
                right: 6px;
                background-color: $charcoalGrey;
                top: 16px;
            }

            .level-1,
            .level-2 {
                .category-element-marker {
                    width: 11px;
                    min-width: 11px;
                    height: 11px;
                    border-radius: 50%;
                }
            }

            .level-1 {
                .category-element-line {
                    position: absolute;
                    width: 1px;
                    height: 48px;
                    right: 4px;
                    background-color: $charcoalGrey;
                    top: 16px;
                }
            }
        }
    }
}
