.range-select {
    border-top: 1px solid $charcoalGrey;

    @include landscape-sm {
        border: none;
        float: left;
    }

    @include landscape-lg {
        border: none;
        float: left;
    }

    .range-list-container {
        margin-top: 30px;

        @media (min-width: $screen-lg) {
            margin-top: 15px;
        }

        @media (min-width: $screen-fm) {
            margin-top: 18px;
        }

        @media (min-width: $screen-hd) {
            margin-top: 22px;
        }

        @include landscape-sm {
            margin-top: 0;
        }

        @include landscape-lg {
            margin-top: 0;
        }

        .range-list-heading {
            color: $white;
            font-size: 20px;
            line-height: 1;
            margin: 0;
            text-align: right;
        }

        .range-list {
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            list-style-type: none;
            margin-bottom: 0;
            margin-top: 20px;
            max-width: 175px;
            padding: 0;

            @media #{$desktop-sm} {
                margin-top: 12px;
            }

            .range-element {
                -webkit-align-items: center;
                align-items: center;
                cursor: pointer;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;

                .range-icon {
                    height: 17px;
                    width: 26px;

                    &::before {
                        color: $osloGray;
                        font-size: 20px;
                        transition: 0.2s all ease-in-out;
                    }
                }

                &:nth-child(2) {
                    .range-icon {
                        height: 23px;
                        width: 30px;

                        &:before {
                            font-size: 26px;
                        }
                    }
                }

                .range-value {
                    color: $osloGray;
                    font-size: 14px;
                    line-height: 2.64;
                    transition: 0.2s all ease-in-out;

                    @media (min-width: $screen-sm) {
                        font-size: 16px;
                        line-height: 2.31;
                    }

                    @media (min-width: $screen-lg) and (max-width: $screen-fm) {
                        font-size: 14px;
                        line-height: 2.64;
                    }

                    @media (min-width: $screen-hd) {
                        font-size: 18px;
                    }
                }

                &.active {
                    .range-icon {
                        &::before {
                            color: $brightTurquoise;
                        }
                    }

                    .range-value {
                        color: $brightTurquoise;
                    }
                }

                &.disabled {
                    opacity: 0.3;
                }

                &.only {
                    cursor: unset;
                }
            }
        }

        .range-span {
            color: $osloGray;
            font-size: 12px;
            line-height: 3.08;
            text-align: right;
        }
    }
}
