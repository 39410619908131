.owl-stage-outer {
    height: 100%;
    overflow: visible;

    @media (min-width: $screen-sm) {
        height: inherit;
    }

    .owl-stage {
        height: 100%;

        .owl-item {
            height: 100%;
        }
    }
}

.owl-dots {
    margin-top: 8px;
    text-align: center;

    .owl-dot {
        background: $mineShaft2;
        border-radius: 50%;
        display: inline-block;
        height: 9px;
        margin-left: 10px;
        width: 9px;

        &.active {
            background: $elm2;
        }

        &:last-child {
            margin-left: 0;
        }
    }
}
