.filter-bar {
    display: flex;
    border-bottom: solid 2px $brightTurquoise3;
    width: 100%;

    @media (min-width: $screen-sm) {
        width: 332px;
    }

    @media (min-width: $screen-lg) {
        width: 290px;
    }

    .filter-bar-btn {
        padding: 0 10px;
        color: $ghost;
        font-size: 18px;
        background: none;
        border: none;
        transition: background 0.2s ease-in-out;

        &.btn-disabled {
            &:hover {
                cursor: default;
            }
        }
    }

    .filter-bar-label {
        position: relative;
        margin: 0;
        width: 100%;
        cursor: text;

        .filter-bar-input {
            color: $white;
            font-size: 18px;
            font-weight: 500;
            line-height: 40px;
            width: inherit;
            background: none;
            border: none;

            &:focus {
                outline: none;
            }
        }

        .filter-bar-placeholder {
            z-index: -1;
            color: $osloGray;
            font-size: 18px;
            font-weight: 500;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all, 0.2s;
            white-space: nowrap;

            &.active {
                opacity: 0;
            }

            .filter-bar-placeholder-label {
                overflow: hidden;
                white-space: nowrap;

                &.start-animation {
                    animation: placeholderAnimation 5s steps(30, end) infinite;
                }
            }

            @keyframes placeholderAnimation {
                0% {
                    width: 0;
                }
                25% {
                    width: 100%;
                }
            }
        }
    }

    .filter-dropdown-list {
        display: none;
    }
}
