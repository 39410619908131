section.sector-who {
    padding-top: 85px;
    box-sizing: border-box;

    @media (min-width: $screen-sm) {
        padding-top: 74.5px;
    }

    @media (min-width: $screen-1366) {
        padding-top: 95.5px;
    }

    @media (min-width: $screen-1680) {
        padding-top: 141px;
    }
}
