.header_container {
    position: relative;
    z-index: 999;
}

header {
    .globHeaderNavLangSwitcher {
        display: none;
    }
}
