.side-menu {
    -webkit-align-items: center;
    align-items: center;
    background-color: $mirage;
    border: solid 1px $darkTwo;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 5px 22px 0 $darkThree;
    display: -webkit-flex;
    display: flex;
    height: calc(100% - 40px);
    left: 0;
    max-width: 270px;
    position: absolute;
    top: calc(50% + 20px);
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    -webkit-transition: transform 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: transform 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
    width: 100%;
    z-index: 301;

    @media (min-width: $screen-sm) {
        max-width: 300px;
        -webkit-transform: translate(calc(-100% + 28px), 0);
        transform: translate(calc(-100% + 28px), 0);
    }

    @media #{$desktop-sm} {
        margin-top: 0;
        -webkit-transform: translate(-100%, -50%);
        transform: translate(-100%, -50%);
    }

    @media (min-width: $screen-lg) {
        border-radius: 5px;
        height: inherit;
        max-width: none;
        position: relative;
        top: unset;
        -webkit-transform: unset;
        transform: unset;
        -webkit-transition: unset;
        transition: unset;
        z-index: 1;
    }

    @include landscape-sm {
        max-width: 500px;
    }

    @include landscape-lg {
        max-width: 562px;
    }

    @media #{$mobileHorizontal} {
        max-width: 256px;
    }

    @media #{$iphone8Horizontal} {
        max-width: 306px;
    }

    @media #{$iphoneXHorizontal} {
        max-width: 306px;
        min-height: 350px;
    }

    .aside-arrow {
        display: none;
        height: 100px;
        overflow: hidden;
        position: absolute;
        right: -20px;
        top: calc(50% - 45px);
        width: 20px;

        &:before,
        &:after {
            background-color: $darkTwo;
            border-radius: 3px;
            content: '';
            height: 70px;
            position: absolute;
            right: 16px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 100px;
        }

        &:after {
            background-color: $mirage;
            right: 18px;
        }
    }

    &.active {
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);

        @media (min-width: $screen-lg) {
            -webkit-transform: unset;
            transform: unset;
        }

        .rectangle {
            &-menu {
                @media (min-width: $screen-lg) {
                    display: none;
                }

                .toggle-menu-btn {
                    .tray-close {
                        display: inline;
                    }

                    .tray-open {
                        display: none;
                    }
                }
            }

            &-back {
                @include landscape-sm {
                    -webkit-transform: translate(22px, -50%);
                    transform: translate(22px, -50%);
                }

                @include landscape-lg {
                    -webkit-transform: translate(22px, -50%);
                    transform: translate(22px, -50%);
                }
            }

            &-dialog {
                -webkit-transform: translate(-110%, -50%);
                transform: translate(-110%, -50%);
                -webkit-transition: 250ms;
                transition: 250ms;
            }
        }

        .rectangle-back {
            @media #{$desktop-sm} {
                -webkit-transform: translate(34px, -50%);
                transform: translate(34px, -50%);
            }
        }
    }

    .rectangle {
        &-container {
            background-color: $mirage;
            padding: 22px 32px 22px 30px;
            position: relative;
            width: 100%;
            z-index: 12;

            @media #{$standard-mobile} {
                padding: 22px 25px 22px 36px;
            }

            @media (min-width: $screen-sm) {
                padding: 0 50px 0 54px;
            }

            @media #{$desktop-sm} {
                padding: 14px 32px 25px 54px;
            }

            @media (min-width: $screen-lg) {
                padding: 14px 21px 25px 25px;
            }

            @media (min-width: $screen-fm) {
                padding: 24px 46px 34px 40px;
            }

            @include landscape-sm {
                padding-right: 24px;
            }

            @include landscape-lg {
                padding-right: 73px;
            }

            &.no-padding {
                @media (min-width: $screen-lg) {
                    padding-bottom: 0;
                    padding-top: 0;

                    .category-filter {
                        padding-bottom: 0;
                    }
                }

                @media (min-width: $screen-fhd) {
                    padding-bottom: 10px;
                }
            }

            .data-update-container {
                padding-top: 10px;
                text-align: center;

                @media #{$desktop-sm} {
                    padding-top: 0;
                }

                @media (min-width: $screen-lg) {
                    padding-top: 10px;
                }

                @include landscape-sm {
                    float: left;
                    max-width: 189px;
                    padding-top: 43px;
                    width: 100%;
                }

                @include landscape-lg {
                    float: left;
                    max-width: 189px;
                    padding-top: 43px;
                    width: 100%;
                }

                @media #{$mobileHorizontal} {
                    padding-top: 8px;
                }

                @media #{$iphoneXHorizontal} {
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
            }
        }

        &-menu {
            height: 100%;
            right: 0;
            top: 0;
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
            -webkit-transition: right 400ms;
            transition: right 400ms;
            width: 100%;
            z-index: 11;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: $screen-sm) {
                right: -13px;
            }

            @media #{$desktop-sm} {
                right: -24px;
            }

            .toggle-menu-btn {
                border: none;
                margin: 0;
                outline: none;
                padding: 0;
                background: transparent;

                img {
                    height: 24px;
                    width: 24px;
                }

                .tray-open {
                    display: inline;
                }

                .tray-close {
                    display: none;
                }

                @media (min-width: $screen-lg) {
                    display: none;
                }
            }
        }

        &-back {
            background: $mirage;
            border: 1px solid $darkTwo;
            border-left: 0;
            border-radius: 5px;
            box-shadow: 0 5px 22px 0 $darkThree;
            display: block;
            height: 100px;
            position: absolute;
            right: 8px;
            top: 50%;
            -webkit-transform: translate(34px, -50%);
            transform: translate(34px, -50%);
            width: 28px;
            z-index: 10;

            &-clickarea {
                @include clickarea(15px);
                z-index: 11;
            }

            @media #{$ipad-small} {
                right: -5px;
                width: 42px;
            }

            @media (min-width: $screen-lg) {
                display: none;
            }
        }

        &-dialog {
            background-color: $bunker2;
            border: 1px $brightTurquoise solid;
            border-radius: 4px;
            display: none;
            height: 100px;
            position: absolute;
            right: -190px;
            top: 50%;
            // force hardware acceleration to prevent transparent background onScroll
            -webkit-transform: translate3d(34px, -50%, 0);
            transform: translate3d(34px, -50%, 0);
            -webkit-transition: 250ms;
            transition: 250ms;
            width: 190px;

            @media (min-width: $screen-sm) {
                visibility: hidden;
            }

            @media #{$tablet-small} {
                right: -202px;
            }

            &-text {
                color: $brightTurquoise;
                font-size: 14px;
                line-height: 21px;
                margin-left: 27px;
                margin-right: 27px;
                margin-top: 29px;
                text-align: center;
            }

            .arrow {
                height: 100px;
                left: -8px;
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 8px;

                &:before,
                &:after {
                    background-color: $brightTurquoise;
                    border-radius: 4px;
                    content: '';
                    height: 100px;
                    left: 20px;
                    position: absolute;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 100px;
                }

                &:after {
                    background-color: $bunker2;
                    left: 22px;
                }
            }
        }
    }

    .empty {
        margin: 32px 0;

        &__title {
            text-align: right;
            font-size: 20px;
            color: #fff;
            margin-bottom: 20px;
        }

        &__content {
            font-family: 'DIN Next LT Arabic Medium';
            font-size: 14px;
        }
    }
}
