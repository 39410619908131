.map-chart-container {
    .main-panel {
        .chart-content {
            .map-chart {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
            }
        }
    }
}
