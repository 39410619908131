.region-household-expenditure-summary {
    .summary-highlights {
        .summary-highlight {
            .summary-highlight-title {
                font-size: 20px;
                line-height: 1.35;
                text-align: right;
                margin-bottom: 16px;
            }

            &.total {
                .summary-highlight-title {
                    color: $darkSkyBlue;
                }
            }

            &.saudi {
                .summary-highlight-title {
                    color: $algaeGreen;
                }
            }

            .summary-highlight-description-container {
                display: flex;

                .summary-highlight-value-container {
                    display: flex;
                    flex-direction: column;
                    width: 50%;

                    .summary-highlight-value {
                        .value-number {
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 1;
                            color: $white;
                        }

                        .value-suffix {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 2;
                            color: $white;
                        }
                    }

                    .summary-highlight-value-label {
                        color: $osloGray;
                        font-size: 14px;

                        @media #{$tablet} {
                            font-size: 16px;
                        }

                        @media (min-width: $screen-fm) {
                            font-size: 16px;
                        }

                        @media (min-width: $screen-fhd) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}
