.toggle-select {
    &.occupation-economic {
        .toggle-select-button {
            &.active {
                &.occupation {
                    background-color: $darkSkyBlue;
                }
                &.economic {
                    background-color: $algaeGreen;
                }
            }
        }
    }

    &.total-saudi {
        .toggle-select-button {
            &.active {
                &.total {
                    background-color: $darkSkyBlue;
                }
                &.saudi {
                    background-color: $algaeGreen;
                }
            }
        }
    }

    &.employees-economic {
        .toggle-select-button {
            &.active {
                &.employees {
                    background-color: $darkSkyBlue;
                }
                &.economic {
                    background-color: $algaeGreen;
                }
            }
        }
    }
}
