.data-update-btn {
    background: transparent;
    border: 1px solid $osloGray;
    border-radius: 4px;
    color: $osloGray;
    cursor: default;
    font-size: 16px;
    line-height: 0.88;
    opacity: 0.4;
    padding: 4px 28px 8px;
    text-decoration: none;
    width: 100%;

    @media (min-width: $screen-sm) {
        font-size: 16px;
    }

    @include forAllTransition(0.2s);

    &.active {
        border-color: $brightTurquoise;
        color: white;
        cursor: pointer;
        opacity: 1;

        &:hover {
            background: $brightTurquoise;
            color: $mirage;
        }
    }
}
