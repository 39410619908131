.arrow-container {
    bottom: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 3;

    @media (min-width: $screen-lg) {
        &.floating {
            bottom: 100px;
            position: fixed;
        }
    }

    .arrow-scroll {
        animation-direction: alternate-reverse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: moveArrow;
        animation-timing-function: ease-in-out;
        color: $brightTurquoise;
        display: block;
        font-size: 18px;
        position: relative;
        top: 50px;
        z-index: 2;

        &-clickarea {
            @include clickarea(15px);
            z-index: 3;
        }

        @media #{$iPhone5} {
            top: 50px;
        }

        @media (max-width: $screen-xs) {
            font-size: 12px;
        }

        @include landscape-sm {
            top: 50px;
        }

        @include landscape-lg {
            top: 50px;
        }

        body.economy &,
        body.product-page &,
        body.demography & {
            bottom: 40px;
            top: auto;

            @media #{$iPhone5} {
                top: -30px;
            }

            @include landscape-sm {
                top: 50px;
            }

            @include landscape-lg {
                top: -20px;
            }
        }

        @include forAllTransition(0.4s);

        @keyframes moveArrow {
            0% {
                -webkit-transform: translateY(0%);
                transform: translateY(0%);
            }

            100% {
                -webkit-transform: translateY(20%);
                transform: translateY(20%);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.arrow-scroll__info {
    color: $brightTurquoise4;
}

.arrow-scroll__info-top {
    position: absolute;
    top: -65px;
    left: 50%;
    transform: translate(-50%, 0);
    width: max-content;
}

@supports (-webkit-overflow-scrolling: touch) {
    //iPhones, iPads
    body.economy .arrow-container,
    body.product-page .arrow-container,
    body.demography .arrow-container {
        bottom: 4vh;

        // iPhone X
        @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
            bottom: 5vh;
        }

        .arrow-scroll {
            top: 0;
        }

        .arrow-scroll__info-top {
            top: -25px;
        }
    }
}
