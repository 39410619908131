.categories-filters {
    .search-container {
        margin: 30px auto;
        text-align: right;

        @media (min-width: $screen-sm) {
            margin-bottom: 40px;
            margin-top: 30px;
        }

        @media (min-width: $screen-lg) {
            margin: 21px 0 0 0;
            width: 369px;
        }

        @media (min-width: $screen-1680) {
            margin-top: 66px;
            width: 20vw;
        }
    }

    .bootstrap-select {
        margin-bottom: 0;

        .dropdown-menu {
            li {
                &:nth-child(5) {
                    display: none;
                }

                a {
                    .checkbox {
                        display: none;
                    }

                    .text {
                        margin-right: 0;
                    }
                }

                &.selected {
                    a {
                        .text {
                            color: $brightTurquoise3;
                        }

                        .checkbox {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
