.report-section {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100vh;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 0;
    overflow: visible;
    padding: 80px 0 40px;
    text-align: right;

    @media #{$mobileHorizontal} {
        align-items: inherit;
        height: inherit;
        padding: 50px 0 50px 0;
    }

    @media #{$desktop-sm} {
        -webkit-flex-direction: column;
        flex-direction: column;
        padding: 82px 6.15% 82px 6.5%;
    }

    @media #{$ipadHorizontal} {
        height: inherit;
    }

    @media (min-width: $screen-lg) {
        -webkit-flex-direction: row;
        flex-direction: row;
        padding: 0 2.6% 0 4%;
    }

    @media (min-width: $screen-fm) {
        padding: 0 3%;
    }

    @media (min-width: $screen-fhd) {
        padding: 0 6%;
    }

    .chart-container-wrapper {
        height: 100%;
        width: 100%;
        @media (min-width: $screen-lg) {
            -webkit-align-items: center;
            align-items: center;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
    }

    .main-panel {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        height: calc(100%);
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        padding-bottom: 15px;
        padding-top: 15px;
        position: relative;
        width: 100%;

        @media #{$standard-mobile} {
            padding-bottom: 20px;
            padding-top: 20px;
        }

        @media (min-width: $screen-sm) {
            height: 100%;
            padding: inherit;
        }

        @media #{$tablet} {
            padding-bottom: 25px;
            padding-top: 15px;

            .chart-content,
            .basic-column-title,
            .basic-column-description,
            .summary-column,
            .source-column {
                display: flex;
                flex-direction: column;
            }

            .chart-content {
                justify-content: center;
            }

            .mobile-column {
                display: none;
            }
        }

        .right-side-panel-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }

        .chart-column {
            display: none;
            -webkit-order: 3;
            order: 3;

            @media #{$iphoneXHorizontal} {
                display: block;

                &.line {
                    margin: 0 auto;
                    width: 495px;
                }
            }

            @media #{$desktop-sm} {
                display: block;
            }

            &.line {
                @media #{$ipadHorizontal} {
                    -webkit-align-self: center;
                    align-self: center;
                }

                @media #{$desktop-sm} {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .mobile-column {
            display: flex;
            flex-direction: column;
            height: inherit;
            -webkit-order: 2;
            order: 2;

            @media #{$desktop-sm} {
                -webkit-order: unset;
                order: unset;
            }

            @media (min-width: $screen-sm) {
                display: none;
            }

            .graph-container {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin: 0 auto;
                padding: 0 30px;

                @media (min-width: $screen-sm) {
                    display: inherit;
                    height: inherit;
                }

                .graph-content {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    position: relative;
                }

                .chart-container {
                    height: 300px;
                    margin: 0 auto;
                    min-height: 300px;
                    width: 300px;

                    @media #{$small-mobile} {
                        height: 250px;
                        min-height: 250px;
                    }

                    @media #{$mobileHorizontal} {
                        height: 160px;
                        width: 620px;
                    }

                    @media (min-width: $screen-sm) {
                        height: 300px;
                    }
                }
            }
        }

        .summary-column,
        .basic-column-title,
        .basic-column-description {
            width: 100%;

            @media (min-width: $screen-lg) {
                max-width: 300px;
            }

            @media (min-width: $screen-1366) {
                max-width: 420px;
            }

            @media (min-width: $screen-fm) {
                max-width: 340px;
            }

            @media (min-width: $screen-1680) {
                max-width: 514px;
            }

            @media (min-width: $screen-hd) {
                max-width: 450px;
            }

            @media (min-width: $screen-fhd) {
                max-width: 514px;
            }
        }

        .basic-column-title {
            display: flex;
            flex-direction: column;

            @media (min-width: $screen-sm) {
                display: inherit;
                flex-direction: inherit;
            }

            @media (min-width: $screen-lg) {
                margin-top: -10px;
            }
        }

        .basic-column-description {
            display: none;

            @media #{$desktop-sm} {
                display: block;
            }
        }

        .summary-column {
            display: none;
            -webkit-order: 6;
            order: 6;

            @media (min-width: $screen-sm) {
                display: inherit;
            }

            @media #{$desktop-sm} {
                -webkit-order: 5;
                order: 5;
            }
        }

        .dots-column {
            margin-top: 0;
            -webkit-order: 5;
            order: 5;
            text-align: center;
            @media #{$desktop-sm} {
                display: none;
            }
            .owl-dot {
                background: $mineShaft2;
                border-radius: 50%;
                cursor: pointer;
                cursor: hand;
                display: inline-block;
                height: 8px;
                margin-left: 10px;
                user-select: none;
                width: 8px;
                &:last-child {
                    margin-left: 0;
                }
                &.active {
                    background: $elm2;
                }
            }
        }

        .source-column {
            display: flex;
            flex-direction: column;
            -webkit-order: 6;
            order: 6;
            padding-top: 16px;
            margin-top: 8px;

            @media #{$standard-mobile} {
                padding-top: 31px;
            }

            @media (min-width: $screen-sm) {
                display: inherit;
                flex-direction: inherit;
                padding-top: inherit;
                margin-top: 12px;
            }

            @media #{$tablet-small} {
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 12px;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 0;
            }

            @media (min-width: $screen-lg) {
                margin-top: 20px;
            }

            @media (min-width: $screen-fm) {
                margin-top: 40px;
            }

            .source {
                margin-top: 0;

                &:first-of-type:not(:only-child) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .sidemenu-column {
        max-width: 324px;
        width: 100%;
        z-index: 500;

        @media (min-width: $screen-lg) {
            max-width: 258px;
        }
        @media (min-width: $screen-fm) {
            max-width: 324px;
        }
    }

    .chart-content {
        display: flex;
        flex-direction: column;
        order: 5;

        @media (min-width: $screen-sm) {
            display: inherit;
            flex-direction: inherit;
        }

        @media (min-width: $screen-lg) {
            left: 0;
            order: inherit;
            position: absolute;
            top: calc(50%);
            transform: translateY(-50%);
        }
    }
}
