body.homepage {
    main {
        margin-top: 0;
        overflow: visible;

        .container-fluid {
            .basic-column {
                border-left: 0;
                padding-left: 0;
                padding-right: 0;
            }

            &.home {
                background-color: $mirage;
                overflow: hidden;
                padding: 0;
                position: relative;

                .basic-column {
                    background-image: linear-gradient(-180deg, #1a2027 0%, #2e3741 100%);
                    border-left: none;
                    height: 76vh;
                    max-height: 960px;
                    min-height: 480px;
                    padding-top: 0;
                    position: relative;
                    text-align: center;
                    z-index: 50;
                    overflow: hidden;

                    @include landscape-sm {
                        min-height: 250px;
                    }

                    @include landscape-lg {
                        min-height: 85vh;
                    }

                    @media #{$desktop-sm} {
                        height: 86vh;
                    }

                    @media (min-width: $screen-md) {
                        height: 92vh;
                    }

                    @media (min-width: $screen-lg) {
                        height: calc(100vh - 80px);
                    }

                    @media (min-width: $screen-1680) {
                        height: calc(100vh - 140px);
                    }

                    @media (min-width: $screen-1920) {
                        height: calc(100vh - 160px);
                    }

                    &::before {
                        @media #{$desktop-sm} {
                            bottom: 0;
                            content: '';
                            left: 0;
                            min-height: 865px;
                            position: absolute;
                            width: 100%;
                        }
                    }

                    &::after {
                        background: $brightTurquoise;
                        bottom: 0;
                        content: '';
                        height: 2px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        z-index: 2;
                    }

                    @media (max-width: 1339px) {
                        &::after {
                            display: inherit;
                        }
                    }

                    .homepage-top-logo {
                        height: 48px;
                        position: relative;
                        top: calc(20vh - 59px);

                        @media #{$landscape-mobile} {
                            top: calc(20vh - 19px);
                        }

                        @include landscape-sm {
                            top: calc(20vh - 9px);
                        }

                        @media (min-height: 375px) and (max-height: 375px) {
                            top: calc(20vh - 24px);
                        }

                        @media #{$desktop-sm} {
                            height: 76px;
                            top: calc(32vh - 96px);
                        }

                        @media (min-width: $screen-lg) {
                            height: 68px;
                            top: calc(50vh - 244px);
                        }

                        @media (min-width: $screen-fm) {
                            height: 76px;
                            top: calc(28vh - 92px);
                        }

                        @media (min-width: $screen-hd) {
                            top: calc(30.65vh - 97px);
                        }
                    }

                    .welcome-text {
                        left: 0;
                        padding: 0 30px;
                        position: absolute;
                        top: 20vh;
                        width: 100%;
                        box-sizing: border-box;

                        @media #{$landscape-mobile} {
                            top: calc(20vh + 40px);
                        }

                        @include landscape-sm {
                            top: calc(20vh + 45px);
                        }

                        @media (min-height: 375px) and (max-height: 375px) {
                            top: calc(20vh + 30px);
                        }

                        @media #{$desktop-sm} {
                            height: 20vh;
                            padding: 0;
                            top: calc(32vh + 21px);
                        }

                        @media (min-width: $screen-lg) {
                            top: calc(50vh - 146.4px);
                        }

                        @media (min-width: $screen-fm) {
                            top: calc(28.4vh + 17px);
                        }

                        @media (min-width: $screen-hd) {
                            top: calc(30.65vh + 18px);
                        }

                        .title-home {
                            color: $white;
                            font-size: 20px;
                            line-height: 2.25;
                            margin: 0 auto;
                            position: relative;
                            z-index: 2;

                            @media #{$landscape-mobile} {
                                line-height: 1.25;
                            }

                            @media #{$desktop-sm} {
                                font-size: 30px;
                                line-height: 1.57;
                                margin-bottom: 14px;
                            }

                            @media (min-width: $screen-lg) {
                                font-size: 24px;
                                line-height: 1.45;
                                margin-bottom: 11px;
                            }

                            @media (min-width: $screen-fm) {
                                font-size: 30px;
                                line-height: 1.57;
                                margin-bottom: 10px;
                                max-width: 726px;
                            }

                            @media (min-width: $screen-hd) {
                                font-size: 35px;
                                line-height: 1.44;
                                margin-bottom: 23px;
                            }
                        }

                        .subtitle {
                            color: $regentGray2;
                            line-height: 1.5;
                            margin: 6px auto 28px;
                            max-width: 100%;

                            @media #{$landscape-mobile} {
                                margin: 6px auto 14px;
                            }

                            @include landscape-lg {
                                margin: 10px auto 16px;
                            }

                            @media (min-height: 375px) and (max-height: 375px) {
                                margin: 6px auto 14px;
                            }

                            @media #{$desktop-sm} {
                                margin-bottom: 55px;
                                margin-top: 17px;
                            }

                            @media #{$desktop-sm} {
                                margin: 0 auto 40px;
                                max-width: 80%;
                            }

                            @media (min-width: $screen-lg) {
                                font-size: 16px;
                                margin-bottom: 35px;
                                max-width: 626px;
                            }

                            @media (min-width: $screen-fm) {
                                font-size: 18px;
                                margin-bottom: 40px;
                                max-width: 758px;
                            }

                            @media (min-width: $screen-hd) {
                                font-size: 20px;
                                max-width: 819px;
                            }

                            p {
                                display: none;
                                margin-bottom: 0;

                                &:first-child {
                                    display: block;
                                }
                            }
                        }

                        .subtitle-mobile {
                            display: block;

                            @media #{$desktop-sm} {
                                display: none;
                            }
                        }

                        .subtitle-desktop {
                            display: none;

                            @media #{$desktop-sm} {
                                display: block;
                            }
                        }
                    }

                    .city {
                        background: url('/frontend/img/general/homepage/city_back.png') no-repeat 50% 102%;
                        background-size: 100%;
                        height: 100%;
                        transition: background-position 1s ease-out;

                        @media (min-width: $screen-1366) {
                            background-position: 50% 145%;

                            @media (min-height: $screen-height-xs) {
                                background-position: 50% 126%;
                            }
                        }

                        @media (min-width: $screen-1680) {
                            background-position: 50% 126.5%;
                        }

                        @media (min-width: $screen-1920) {
                            background-position: 50% 130%;
                        }

                        .bridge {
                            @media #{$desktop-sm} {
                                background: url('/frontend/img/general/homepage/city.png') no-repeat 50% 104.3%;
                                background-size: contain;
                                bottom: 0;
                                content: '';
                                height: 100%;
                                left: 0;
                                position: absolute;
                                transition: background-position 1.5s ease-out 0.1s;
                                width: 100vw;

                                @media (min-width: $screen-1366) {
                                    background-position: 50% 116%;

                                    @media (min-height: $screen-height-xs) {
                                        background-position: 50% 112%;
                                    }
                                }

                                @media (min-width: $screen-1680) {
                                    background-position: 50% 111%;
                                }

                                @media (min-width: $screen-1920) {
                                    background-position: 50% 111.5%;
                                }
                            }

                            &::before {
                                @media #{$desktop-sm}, #{$landscape-mobile} {
                                    background: url('/frontend/img/general/homepage/left.svg') no-repeat bottom left;
                                    background-size: contain;
                                    bottom: 0;
                                    content: '';
                                    height: 251px;
                                    left: -1px;
                                    position: absolute;
                                    width: 33.5vw;
                                    z-index: 1;
                                }

                                @media #{$landscape-mobile} {
                                    width: 26.8vw;
                                }

                                @media (min-width: $screen-fm) {
                                    height: 393px;
                                    left: 0;
                                    width: 33vw;
                                }
                            }

                            &::after {
                                background: url('/frontend/img/general/homepage/right.svg') no-repeat bottom right;
                                background-size: contain;
                                bottom: 0;
                                content: '';
                                height: 384px;
                                position: absolute;
                                right: 2vw;
                                width: 96vw;
                                z-index: 1;

                                @media #{$desktop-sm}, #{$landscape-mobile} {
                                    background: url('/frontend/img/general/homepage/right-with-bridge.svg') no-repeat
                                        bottom right;
                                    background-size: contain;
                                    height: 215px;
                                    right: -48px;
                                    width: 36vw;
                                }

                                @media #{$landscape-mobile} {
                                    width: 28.8vw;
                                }

                                @media (min-width: $screen-lg) {
                                    right: -54px;
                                    width: 31.7vw;
                                }

                                @media (min-width: $screen-fm) {
                                    height: 295px;
                                    right: -77px;
                                    width: 35vw;
                                }

                                @media (min-width: $screen-hd) {
                                    right: -90px;
                                }
                            }
                        }

                        .metro-container {
                            bottom: 0;
                            height: 12px;
                            left: 0;
                            position: absolute;
                            right: 0;
                            width: 100vw;

                            @media #{$desktop-sm} {
                                height: 25.5px;
                            }

                            @media (min-width: $screen-fm) {
                                height: 42px;
                            }

                            .metro-train {
                                @media #{$desktop-sm} {
                                    animation: metroRun 30s ease-in-out infinite;
                                    background: url('/frontend/img/general/homepage/metro.svg') no-repeat bottom right;
                                    background-size: contain;
                                    display: block;
                                    height: 100%;
                                    left: 0;
                                    max-height: 22px;
                                    max-width: 280px;
                                    position: absolute;
                                    width: 100%;
                                }

                                @media (min-width: $screen-fm) {
                                    max-height: 34px;
                                    max-width: 457px;
                                }
                            }
                        }

                        @keyframes metroRun {
                            0%,
                            15% {
                                left: -10%;
                                @include flipTrain(forward);
                            }

                            44% {
                                left: 100%;
                                @include flipTrain(forward);
                            }

                            45%,
                            55% {
                                left: 100%;
                                @include flipTrain(backward);
                            }

                            84% {
                                left: -10%;
                                @include flipTrain(backward);
                            }

                            85%,
                            100% {
                                left: -10%;
                                @include flipTrain(forward);
                            }
                        }
                    }
                }

                .crescent {
                    position: absolute;
                    left: 0;
                    height: 44px;
                    width: auto;
                    bottom: 39vw;

                    @media (min-width: $screen-sm) {
                        top: 160px;
                        bottom: auto;
                        height: auto;
                        width: 50vw;
                    }

                    @media (min-width: $screen-lg) {
                        width: 33.5vw;
                    }

                    @media #{$iPhone5} {
                        height: 30px;
                    }

                    @media #{$standard-mobile} {
                        bottom: 37vw;
                        height: 40px;
                    }

                    img {
                        height: inherit;
                        width: auto;

                        @media (min-width: $screen-sm) {
                            width: inherit;
                        }
                    }
                }

                &::after {
                    display: none;
                }

                .scroll-down {
                    background: linear-gradient(to top, $mirage 5%, $bunkerDark6 100%);
                    overflow: hidden;
                    padding-bottom: 15px;
                    padding-top: 16px;
                    position: relative;
                    text-align: center;
                    z-index: 1;

                    &__menu {
                        display: none;
                        margin-bottom: 0;
                        padding-right: 0;

                        li {
                            display: inline-block;
                            margin-left: 55px;

                            &:last-child {
                                margin-left: 0;
                            }

                            a {
                                font-size: 16px;
                                color: $brightTurquoise5;
                                text-decoration: none;
                                line-height: 25px;

                                &:hover {
                                    color: $viridian;
                                }

                                @media (min-width: $screen-1680) {
                                    font-size: 24px;
                                }
                            }

                            @media (min-width: $screen-1366) {
                                margin-left: 78px;
                            }

                            @media (min-width: $screen-1680) {
                                margin-left: 116px;
                            }

                            @media (min-width: $screen-1920) {
                                margin-left: 159px;
                            }
                        }

                        @media (min-width: $screen-lg) {
                            display: block;
                            margin: 20px 0;
                        }

                        @media (min-width: $screen-1680) {
                            margin: 45px 0;
                        }

                        @media (min-width: $screen-1920) {
                            margin: 55px 0;
                        }
                    }

                    @media #{$desktop-sm} {
                        background: inherit;
                        padding: 14.5px 65px;
                        text-align: center;
                    }

                    @media (max-height: $screen-height-xxs) {
                        padding-top: 4px;
                        font-size: 12px;
                    }

                    @media (min-width: $screen-lg) {
                        margin-top: -40px;
                        padding: 46.5px 65px 14.5px;

                        .arrow-scroller {
                            display: none;
                        }
                    }

                    @media (height: $screen-height-m) {
                        padding-top: 4px;
                    }
                }
            }
        }

        .content {
            section {
                background: linear-gradient(to top, $mirage 75%, $bunkerDark6 100%);
                padding: 36% 5% 0;

                @media #{$desktop-sm} {
                    background: linear-gradient(to top, $mirage 68%, $bunkerDark6 100%);
                    padding: 7vw 10% 24px;
                }

                @media (min-width: $screen-lg) {
                    padding: 7vw 5% 5%;
                }

                @media (min-width: $screen-fm) {
                    padding: 12% 5% 5%;
                }

                &:first-of-type {
                    background: linear-gradient(to top, $mirage 100%, $bunkerDark6 100%);

                    @media (min-width: $screen-lg) {
                        background: linear-gradient(to top, $mirage 55%, $bunkerDark6 100%);
                    }

                    @media (min-width: $screen-hd) {
                        background: linear-gradient(to top, $mirage 46%, $bunkerDark6 100%);
                    }
                }

                &.sector-who {
                    background: linear-gradient(to top, $mirage 75%, $bunkerDark7 100%);

                    @media #{$desktop-sm} {
                        background: linear-gradient(to top, $mirage 68%, $bunkerDark7 100%);
                    }
                }

                .title {
                    line-height: 28px;

                    @media #{$desktop-sm} {
                        line-height: 53px;
                    }

                    @media (min-width: $screen-fm) {
                        line-height: 71px;
                    }
                }

                .beneficiary-title {
                    line-height: 1.2;
                    margin-bottom: 120px;

                    @media (max-width: $screen-sm-min) {
                        margin-bottom: 28px;
                    }
                }

                .container {
                    max-width: 350px;
                    direction: ltr;
                    justify-content: center;

                    * {
                        transition: 0.5s all;
                    }

                    @media (min-width: $screen-sm) {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(auto-fill, auto);
                        grid-template-rows: repeat(auto-fill, auto);
                        gap: 0 4.5vw;
                        grid-template-areas:
                            '. . . . . . . . .'
                            'tile-lens tile-lens tile-lens tile-lens . . . . .'
                            'tile-lens tile-lens tile-lens tile-lens . . . . .'
                            'tile-lens tile-lens tile-lens tile-lens . tile-arrow tile-arrow tile-arrow tile-arrow'
                            'tile-lens tile-lens tile-lens tile-lens . tile-arrow tile-arrow tile-arrow tile-arrow'
                            '. . . . . tile-arrow tile-arrow tile-arrow tile-arrow'
                            'tile-map tile-map tile-map tile-map . tile-arrow tile-arrow tile-arrow tile-arrow'
                            'tile-map tile-map tile-map tile-map . . . . .'
                            'tile-map tile-map tile-map tile-map . tile-money tile-money tile-money tile-money'
                            'tile-map tile-map tile-map tile-map . tile-money tile-money tile-money tile-money'
                            '. . . . . tile-money tile-money tile-money tile-money'
                            '. . . . . tile-money tile-money tile-money tile-money';
                    }

                    @media (min-width: $screen-lg) {
                        gap: 0 0;
                        grid-template-areas:
                            '. . . . . . . . . . . .'
                            '. . tile-lens tile-lens tile-lens tile-lens . . . . . .'
                            '. . tile-lens tile-lens tile-lens tile-lens . . . . . .'
                            '. . tile-lens tile-lens tile-lens tile-lens . tile-arrow tile-arrow tile-arrow tile-arrow .'
                            '. . tile-lens tile-lens tile-lens tile-lens . tile-arrow tile-arrow tile-arrow tile-arrow .'
                            '. . . . . . . tile-arrow tile-arrow tile-arrow tile-arrow .'
                            '. tile-map tile-map tile-map tile-map . . tile-arrow tile-arrow tile-arrow tile-arrow .'
                            '. tile-map tile-map tile-map tile-map . . . . . . .'
                            '. tile-map tile-map tile-map tile-map . tile-money tile-money tile-money tile-money . .'
                            '. tile-map tile-map tile-map tile-map . tile-money tile-money tile-money tile-money . .'
                            '. . . . . . tile-money tile-money tile-money tile-money . .'
                            '. . . . . . tile-money tile-money tile-money tile-money . .';
                    }
                }

                .grid-tile {
                    display: block;
                    width: 257px;
                    height: 366px;
                    margin-bottom: 54px;
                    position: relative;
                    transition: transform 0.8s ease;

                    &.slide-up {
                        transform: translateY(8em);
                    }

                    &:first-child {
                        margin-top: 25px;
                    }

                    &:nth-child(odd) {
                        margin-right: 0;
                        margin-left: auto;
                    }

                    @media (min-width: $screen-sm) {
                        width: 276px;
                        height: 350px;
                        margin-bottom: 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:nth-child(odd) {
                            margin-left: 0;
                        }
                    }

                    @media (min-width: $screen-lg) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 385px;
                        height: 345px;

                        &:nth-child(odd) {
                            margin-left: auto;
                        }
                    }

                    @media (min-width: $screen-1366) {
                        width: 485px;
                        height: 445px;
                    }

                    @media (min-width: $screen-1920) {
                        width: 640px;
                        height: 565px;
                    }
                }

                .beneficiary-tile {
                    width: 100%;
                    height: 100%;
                    border: 3px solid $brightTurquoise;
                    border-radius: 20px;
                    text-align: right;
                    padding: 40px 0;
                    box-shadow: 2px 1px 23px 21px rgba(130, 146, 164, 0.12);
                    position: absolute;
                    box-sizing: border-box;

                    @media (min-width: $screen-lg) {
                        padding: 50px 0;
                        box-shadow: 2px 1px 23px 10px rgba(130, 146, 164, 0.09);
                    }

                    &__container {
                        margin: 0 auto;
                        width: 190px;

                        @media (min-width: $screen-sm) {
                            width: 235px;
                        }

                        @media (min-width: $screen-lg) {
                            width: 295px;
                        }

                        @media (min-width: $screen-1366) {
                            width: 375px;
                        }

                        @media (min-width: $screen-1920) {
                            width: 510px;
                        }
                    }

                    &__icon {
                        width: auto;
                        height: 40px;

                        @media (min-width: $screen-sm) {
                            height: 50px;
                        }

                        @media (min-width: $screen-lg) {
                            height: 60px;
                            margin-top: 5px;
                        }

                        @media (min-width: $screen-1366) {
                            margin-top: 40px;
                        }

                        @media (min-width: $screen-1920) {
                            height: 80px;
                            margin-top: 70px;
                        }
                    }

                    &__title {
                        margin-top: 18px;
                        text-align: right;
                        color: $tealish;
                        font-size: 20px;
                        line-height: 24px;

                        @media (min-width: $screen-lg) {
                            font-size: 22px;
                            line-height: 1.19;
                        }

                        @media (min-width: $screen-1366) {
                            font-size: 28px;
                            line-height: 1.28;
                        }

                        @media (min-width: $screen-1920) {
                            font-size: 36px;
                            line-height: 1.39;
                        }
                    }

                    &__description {
                        text-align: right;
                        color: $regentGray2;
                        margin-top: 16px;
                        font-size: 14px;
                        line-height: 21px;

                        @media (min-width: $screen-lg) {
                            margin-top: 10px;
                            line-height: 1.38;
                        }

                        @media (min-width: $screen-1366) {
                            margin-top: 12px;
                            font-size: 18px;
                            line-height: 1.44;
                        }

                        @media (min-width: $screen-1920) {
                            margin-top: 16px;
                            font-size: 20px;
                            line-height: 1.5;
                        }
                    }

                    &__button {
                        font-size: 14px;
                        text-decoration: none;
                        color: $brightTurquoise;
                        display: inline-block;
                        margin-top: 20px;

                        &-arrow {
                            width: 13px;
                            height: 13px;
                            margin-top: -2px;
                            margin-right: 10px;
                            transform: rotate(-180deg);
                            filter: brightness(0) saturate(100%) brightness(95%) invert(100%) sepia(62%) saturate(2213%)
                                contrast(102%) hue-rotate(85deg);

                            @media (min-width: $screen-lg) {
                                display: none;
                            }
                        }

                        @media (min-width: $screen-lg) {
                            border: 2px solid $brightTurquoise;
                            border-radius: $border-radius-btn;
                            font-size: 16px;
                            text-align: center;
                            cursor: pointer;
                            display: block;
                            visibility: hidden;
                            opacity: 0;
                            transition: 0.25s;
                            width: 192px;
                            margin: 50px auto 0;
                            padding: 8.5px 0;

                            &:hover {
                                background-color: $brightTurquoiseTransparent;
                                color: $mirage;
                            }
                        }

                        @media (min-width: $screen-1366) {
                            width: 206px;
                            margin: 65px auto 0;
                            padding: 11.5px 0;
                        }

                        @media (min-width: $screen-1920) {
                            width: 272px;
                            margin: 75px auto 0;
                        }
                    }

                    &:hover {
                        @media (min-width: $screen-lg) {
                            border-color: $white;
                        }

                        .beneficiary-tile__container {
                            @media (min-width: $screen-lg) {
                                transform: scale(1.075);
                            }
                        }

                        .beneficiary-tile__icon {
                            @media (min-width: $screen-lg) {
                                margin-top: 10px;
                                transform: scale(1.05);
                                filter: grayscale(100%);
                            }

                            @media (min-width: $screen-1366) {
                                margin-top: 50px;
                            }

                            @media (min-width: $screen-1920) {
                                margin-top: 80px;
                            }
                        }

                        .beneficiary-tile__title {
                            @media (min-width: $screen-lg) {
                                color: $white;
                                margin-top: 18px;
                            }

                            @media (min-width: $screen-1366) {
                                margin-top: 20px;
                            }

                            @media (min-width: $screen-1920) {
                                margin-top: 26px;
                            }
                        }

                        .beneficiary-tile__description {
                            @media (min-width: $screen-lg) {
                                margin-top: 18px;
                            }

                            @media (min-width: $screen-1366) {
                                margin-top: 20px;
                            }

                            @media (min-width: $screen-1920) {
                                margin-top: 24px;
                            }
                        }

                        .beneficiary-tile__button {
                            @media (min-width: $screen-lg) {
                                transform: none;
                                visibility: visible;
                                opacity: 1;
                                transition-delay: 0.25s;
                            }
                        }

                        @media (min-width: $screen-lg) {
                            width: 468px;
                            height: 438px;
                        }

                        @media (min-width: $screen-1366) {
                            width: 538px;
                            height: 538px;
                        }

                        @media (min-width: $screen-1920) {
                            width: 712px;
                            height: 682px;
                        }
                    }
                }

                .tile-lens {
                    grid-area: tile-lens;

                    @media (min-width: $screen-lg) {
                        margin-left: 20%;
                    }
                }

                .tile-arrow {
                    grid-area: tile-arrow;
                }

                .tile-map {
                    grid-area: tile-map;
                }

                .tile-money {
                    grid-area: tile-money;
                }

                .subtitle {
                    margin: 17px auto 26px;
                    max-width: calc(100vw - 60px);

                    a {
                        color: $brightTurquoise;

                        @include forAllTransition(0.2s);

                        &:hover {
                            color: darken($brightTurquoise, 15%);
                            text-decoration: none;
                        }
                    }

                    @media #{$desktop-sm} {
                        margin-bottom: 62px;
                        margin-top: 8px;
                        max-width: none;
                        z-index: 2;
                    }

                    @media (min-width: $screen-lg) {
                        max-width: 64%;
                    }

                    @media (min-width: $screen-fm) {
                        margin-bottom: 90px;
                        margin-top: 20px;
                    }
                }

                &.map,
                &.sector-who {
                    &::before {
                        display: none;
                    }
                }
            }

            @import 'homepage/_map';
            @import 'homepage/_partners';
            @import 'homepage/_beneficiaries';
            @import 'homepage/_reports';
        }
    }
}
