.title {
    color: $white;
    font-size: 24px;
    line-height: 35px;
    margin: 0 auto;
    max-width: 88%;
    padding: 0 15px;
    position: relative;

    @media #{$desktop-sm} {
        font-size: 36px;
        line-height: 53px;
        z-index: 2;
    }

    @media (min-width: $screen-fm) {
        font-size: 48px;
        line-height: 71px;
        padding: 0;
    }
}

.subtitle {
    color: $ghost;
    font-family: 'DIN Next LT Arabic', sans-serif;
    font-size: 14px;
    line-height: 1.29;
    margin: 15px auto 25px;
    padding: 0 5px;
    position: relative;

    @media #{$desktop-sm} {
        font-size: 18px;
        line-height: 27px;
        max-width: 820px;
        padding: 0;
    }
}
