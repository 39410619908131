@function multiple-box-shadow($n) {
    $value: '#{random(2000)}px #{random(500)}px #29f6c8';
    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(500)}px #29f6c8';
    }
    @return unquote($value);
}

$small-stars-count: 12;
$medium-stars-count: 13;
$large-stars-count: 12;
$mobile-stars-multiplier: 6;

$shadows-small: multiple-box-shadow($small-stars-count);
$shadows-medium: multiple-box-shadow($medium-stars-count);
$shadows-large: multiple-box-shadow($large-stars-count);

$shadows-small-mobile: multiple-box-shadow($small-stars-count * $mobile-stars-multiplier);
$shadows-medium-mobile: multiple-box-shadow($medium-stars-count * $mobile-stars-multiplier);
$shadows-large-mobile: multiple-box-shadow($large-stars-count * $mobile-stars-multiplier);

.stars-container {
    bottom: 0;
    height: 500px;
    left: 0;
    position: absolute;
    top: -50vh;
    z-index: 0;

    @media all and (orientation: landscape) {
        top: -100vh;
    }

    @media (min-height: 550px) {
        top: -30vh;
    }

    @media (min-height: 650px) {
        top: -15vh;
    }

    @media (min-height: 750px) {
        top: 0;
    }

    #small-stars {
        animation: animStar 5s ease-in-out infinite;
        animation-delay: 4s;
        background: transparent;
        border-radius: 50%;
        box-shadow: $shadows-small-mobile;
        height: 1px;
        opacity: 1;
        width: 1px;
        zoom: 1;

        @media #{$desktop-sm} {
            box-shadow: $shadows-small;
        }
    }

    #medium-stars {
        animation: animStar 5s ease-in-out infinite;
        background: transparent;
        border-radius: 50%;
        box-shadow: $shadows-medium-mobile;
        height: 1px;
        opacity: 1;
        width: 1px;
        zoom: 1;

        @media #{$desktop-sm} {
            box-shadow: $shadows-medium;
            height: 2px;
            width: 2px;
        }
    }

    #large-stars {
        animation: animStar 5s ease-in-out infinite;
        animation-delay: 8s;
        background: transparent;
        border-radius: 50%;
        box-shadow: $shadows-large-mobile;
        height: 1px;
        opacity: 1;
        width: 1px;
        zoom: 1;

        @media #{$desktop-sm} {
            box-shadow: $shadows-large;
            height: 3px;
            width: 3px;
        }
    }
}

@keyframes animStar {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes animStar {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
