.chart-tooltip {
    box-shadow: 0 1px 3px rgba($black, 0.25);
    color: $tundora;
    display: block;
    font-family: 'DIN Next LT Arabic';
    font-size: 12px;
    font-weight: 200;
    margin-top: -30px;
    max-width: 250px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2000;

    &.custom-tooltip {
        box-shadow: 0 5px 22px 0 rgba($black, 0.5);
        box-sizing: border-box;
        max-width: initial;
        width: initial;
    }

    .tooltip-header {
        position: relative;
        z-index: 1;

        .tooltip-main-title {
            color: $mirage;
            display: block;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            margin: 0 0 12px 0;
            text-align: center;
        }

        .tooltip-content {
            align-items: center;
            direction: rtl;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 5px;
            overflow: hidden;

            .tooltip-decorator {
                background: $chenin;
                border-radius: 2px;
                height: 10px;
                margin-left: 10px;
                max-width: 10px;
                width: 100%;
            }

            .tooltip-title {
                color: $paleSky;
                direction: rtl;
                font-size: 14px;
                margin-left: 12px;
                width: 100%;
            }

            .tooltip-data-value {
                color: $paleSky;
                direction: rtl;
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }

    .tooltip-container {
        background-color: $white;
        border-radius: 4px;
        padding: 16px 20px 19px;
        width: auto !important;
    }

    .tooltip-arrow {
        background-color: $white;
        bottom: -5px;
        box-shadow: rgba($black, 0.25) 0 1px 3px;
        height: 10px;
        left: 50%;
        margin-left: -5px;
        margin-top: auto;
        position: absolute;
        right: auto;
        top: auto;
        transform: rotate(45deg);
        width: 10px;
        z-index: -1;
    }
}

.navigation-tooltip {
    display: block;
    position: absolute;
    font-family: 'DIN Next LT Arabic', serif;
    font-size: 14px;
    font-weight: 200;
    color: $white;
    pointer-events: none;
    z-index: 2000;
    box-shadow: 0 1px 3px rgba($black, 0.25);

    .tooltip-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $brightTurquoise;
        background-color: $mirage;
        border-radius: 4px;

        .tooltip-value {
            padding: 2px 8px 6px 8px;
        }

        @media (min-width: $screen-fm) {
            padding: 8px 16px;
        }
    }

    .tooltip-arrow {
        position: absolute;
        top: 50%;
        left: 0;
        height: 10px;
        width: 10px;
        margin-top: auto;
        z-index: 1;
        background-color: $mirage;
        border: 1px solid;
        border-color: $brightTurquoise;
        border-top: none;
        border-left: none;
        box-shadow: rgba($black, 0.25) 0 1px 3px;
        transform: translate(-50%, -50%) rotate(135deg);
    }
}
