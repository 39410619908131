.reports-container {
    background-color: $mirage;
    margin: 0 0 100px;
    padding: 0;

    @media (min-width: $screen-lg) {
        display: flex;
        flex-direction: row;
        cursor: grab;

        &.active {
            cursor: grabbing;
        }
    }

    @media (max-width: 1199px) {
        padding-top: 40px;
    }

    @media (min-width: 2560px) {
        cursor: initial;

        &.active {
            cursor: initial;
        }
    }

    a {
        text-decoration: none;

        @media (min-width: $screen-lg) {
            &:not(.clickable) {
                pointer-events: none;
            }
        }
    }

    .tile {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        margin: 0;
        padding: 0 31px 31px;
        width: 100%;
        height: 321px;

        @media (min-width: $screen-lg) {
            padding-right: 30px;
            min-width: 341px;
            height: 639px;
            box-shadow: inset -40px 0 20px -30px rgba(0, 0, 0, 0.2);
        }

        @media (min-width: $screen-1366) {
            padding-right: 35px;
            min-width: 372px;
            height: 693px;
        }

        @media (min-width: $screen-1680) {
            padding-right: 55px;
            min-width: 450px;
            height: 873px;
        }

        @media (min-width: $screen-1920) {
            padding-right: 65px;
            min-width: 538px;
            height: 997px;
        }

        &--product {
            background-image: url('/frontend/img/reports_product_bg.webp'),
                linear-gradient(147deg, #232a32 15%, #0c0e11 106%);
            background-repeat: no-repeat, no-repeat;
            background-size: 256px auto, auto;
            background-position: left 30px, left top;

            @media (min-width: $screen-lg) {
                background-image: url('/frontend/img/reports_product_bg_2.webp'),
                    linear-gradient(155deg, #232a32 -19%, #0c0e11 117%);
                background-repeat: no-repeat, no-repeat;
                background-size: auto 340px, auto;
                background-position: right 45px, left top;
            }

            @media (min-width: $screen-1366) {
                background-size: auto 390px, auto;
            }

            @media (min-width: $screen-1680) {
                background-size: auto 450px, auto;
                background-position: right 70px, left top;
            }

            @media (min-width: $screen-1920) {
                background-size: auto 565px, auto;
            }
        }

        &--sector {
            background-image: url('/frontend/img/reports_sector_bg.webp'),
                linear-gradient(142deg, #232a32 21%, #0c0e11 71%);
            background-repeat: no-repeat, no-repeat;
            background-size: 338px auto, auto;

            @media (min-width: $screen-lg) {
                background-image: url('/frontend/img/reports_sector_bg_2.webp'),
                    linear-gradient(155deg, #232a32 -19%, #0b0d10 116%);
                background-repeat: no-repeat, no-repeat;
                background-size: auto 380px, auto;
                background-position: right top, left top;
            }

            @media (min-width: $screen-1366) {
                background-size: auto 430px, auto;
            }

            @media (min-width: $screen-1680) {
                background-size: auto 520px, auto;
            }

            @media (min-width: $screen-1920) {
                background-size: auto 675px, auto;
            }
        }

        &--region {
            background-image: url('/frontend/img/reports_region_bg.webp');
            background-repeat: no-repeat;
            background-size: cover;
        }

        &--all {
            background-color: $bunkerDark6;

            @media (min-width: $screen-lg) {
                background-image: linear-gradient(153deg, #1e242b -3%, #101317 112%);
                background-repeat: no-repeat;
                background-size: auto;
                background-position: left top;
                order: -1;
            }
        }

        &__row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__header {
            font-size: 28px;
            color: $white;
            line-height: 26px;
            margin-bottom: 55px;
            max-width: 302px;

            @media (min-width: $screen-lg) {
                font-size: 24px;
                line-height: normal;
                margin-bottom: 46px;
                max-width: 258px;
            }

            @media (min-width: $screen-1366) {
                font-size: 28px;
                max-width: 271px;
            }

            @media (min-width: $screen-1680) {
                font-size: 32px;
                max-width: 312px;
            }

            @media (min-width: $screen-1920) {
                font-size: 36px;
                max-width: 342px;
            }

            &--uppercase {
                line-height: 28px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
        }

        &__arrow {
            margin-right: 25px;
            width: 25px;
            height: 25px;
            transform: rotate(180deg);

            @media (min-width: $screen-lg) {
                display: none;
            }
        }

        &__description {
            font-size: 16px;
            color: $alto;
            line-height: 18px;
            max-width: 312px;

            @media (min-width: $screen-lg) {
                font-size: 14px;
                line-height: 25px;
                max-width: 270px;
            }

            @media (min-width: $screen-1366) {
                max-width: 291px;
            }

            @media (min-width: $screen-1680) {
                font-size: 18px;
                max-width: 326px;
            }

            @media (min-width: $screen-1920) {
                max-width: 398px;
            }
        }

        &__link {
            display: none;

            @media (min-width: $screen-lg) {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: $brightTurquoise;
                margin-bottom: 48px;

                &:hover {
                    color: $viridian;
                }

                &:hover > .tile__link-arrow {
                    margin-right: 24px;
                }
            }

            @media (min-width: $screen-1366) {
                font-size: 18px;
                margin-bottom: 40px;
            }

            @media (min-width: $screen-1680) {
                font-size: 24px;
                margin-bottom: 100px;
            }

            @media (min-width: $screen-1920) {
                margin-bottom: 96px;
            }

            &-arrow {
                margin-right: 16px;
                width: 20px;
                height: 20px;
                transform: rotate(180deg);
                filter: brightness(0) saturate(100%) brightness(95%) invert(100%) sepia(62%) saturate(2213%)
                    contrast(102%) hue-rotate(85deg);
            }
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 2px solid $brightTurquoise;
            border-radius: $border-radius-btn;
            font-size: 16px;
            color: $brightTurquoise;
            margin-bottom: 25px;
            width: 181px;
            height: 45px;

            @media (min-width: $screen-lg) {
                border-radius: $border-radius-btn;
                margin-bottom: 88px;
                width: 176px;
                height: 44px;

                &:hover {
                    background-color: $brightTurquoiseTransparent;
                    color: $mirage;
                }
            }

            @media (min-width: $screen-1366) {
                font-size: 20px;
                margin-bottom: 95px;
                width: 212px;
                height: 48px;
            }

            @media (min-width: $screen-1680) {
                border-radius: $border-radius-btn;
                margin-bottom: 115px;
                width: 232px;
                height: 58px;

                &:hover {
                    background-color: $brightTurquoiseTransparent;
                    color: $mirage;
                }
            }

            @media (min-width: $screen-1920) {
                margin-bottom: 153px;
            }

            &:hover {
                background-color: $brightTurquoiseTransparent;
                color: $mirage;
            }
        }
    }
}
