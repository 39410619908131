.spending-chart-container,
.prices-chart-container,
.consumer-price-chart-container,
.wholesale-price-chart-container,
.local-production-chart-container,
.population-over-years-chart-container,
.commercial-establishments-chart-container {
    @media #{$tablet} {
        .main-panel {
            .summary-column {
                flex: 1;

                .stats-summary {
                    height: 100%;

                    .chart-stats-summary {
                        height: 100%;
                    }
                }
            }

            .basic-column-description {
                margin-bottom: 20px;
            }

            .chart-content {
                flex: inherit;
            }

            .source-column {
                padding-top: 0;

                .source {
                    margin-top: 0;
                }
            }
        }

        .sidemenu-column {
            .side-menu {
                .rectangle-dialog {
                    visibility: inherit;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        .summary-container {
            .chart-stats-summary {
                display: flex;
            }
        }
    }
}
