.categories-filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: center;

    @media (min-width: $screen-sm) {
        margin-bottom: 40px;
    }

    @media (min-width: $screen-lg) {
        margin-bottom: 20px;
        flex-direction: row;
    }

    ul {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        @media (min-width: $screen-lg) {
            margin-top: 21px;
        }

        @media (min-width: $screen-1680) {
            margin-top: 66px;
            text-align: center;
        }

        .owl-stage-outer {
            overflow: visible;

            .owl-stage {
                min-width: 631px;

                @media #{$landscape-mobile} {
                    margin: auto;
                }
            }
        }

        .owl-item {
            &:last-child {
                li {
                    padding-left: 0;
                }
            }
        }

        li {
            display: inline-block;
            margin: 0 8px;
            position: relative;

            @media (min-width: $screen-sm) {
                margin: 0 7px;

                &:first-child {
                    margin-right: 3.5px;
                }

                &:last-child {
                    margin-left: 3.5px;
                }
            }

            @media (min-width: $screen-lg) {
                &:first-child {
                    margin-right: 0;
                }
            }

            .filter-link {
                font-size: 16px;
                font-weight: bold;
                line-height: 14px;
                padding: 16px 38px;
                position: relative;

                &:hover {
                    text-decoration: none;
                    border-color: $brightTurquoise;
                    background: $brightTurquoiseTransparent;
                    color: $mirage;
                }

                @include forAllTransition(0.3s);

                @media (min-width: $screen-sm) {
                    padding: 12px 46px;
                }

                @media (min-width: $screen-lg) {
                    padding: 16px 35px;
                }

                @media (min-width: $screen-1680) {
                    padding: 16px 48px;
                }
            }

            &.active {
                .filter-link {
                    border-color: $white;
                    color: $white;

                    &:hover {
                        cursor: default;
                    }
                }
            }
        }
    }
}
