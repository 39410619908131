.line-of-business-summary {
    .stats-content {
        .stats-position {
            @include lineStatsColors('.color-', $categories-colors-wo-average);

            &.two-values {
                @media (min-width: $screen-sm) {
                    margin-top: -4px;
                    width: 23%;
                }

                @media (min-width: $screen-lg) {
                    margin-bottom: 25px;
                    width: 33.5%;
                }

                @media (min-width: $screen-1366) {
                    width: 44.5%;
                }

                @media (min-width: $screen-fm) {
                    margin-bottom: 50px;
                    width: 33.5%;
                }

                @media (min-width: $screen-1680) {
                    width: 44.5%;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 7px;

                    @media (min-width: $screen-sm) {
                        margin-top: -4px;
                    }

                    @media (min-width: $screen-lg) {
                        margin-top: 7px;
                    }

                    @media (min-width: $screen-fm) {
                        margin-top: 3px;
                    }
                }

                .value {
                    margin-bottom: 8px;

                    @media (min-width: $screen-lg) {
                        font-size: 24px;
                        margin-bottom: 6px;
                    }

                    @media (min-width: $screen-fm) {
                        font-size: 36px;
                        margin-bottom: 9px;
                    }
                }

                .value-2 {
                    .value-suffix {
                        margin-right: -1px;

                        @media (min-width: $screen-fm) {
                            margin-right: -3px;
                        }
                    }
                }

                .description {
                    -webkit-align-items: baseline;
                    align-items: baseline;
                    -webkit-align-self: baseline;
                    align-self: baseline;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-direction: row;
                    flex-direction: row;
                    -webkit-justify-content: flex-start;
                    justify-content: flex-start;
                    position: relative;

                    img {
                        margin-left: 6px;
                        max-width: 21px;
                    }

                    span {
                        line-height: 1.07;

                        @media (min-width: $screen-lg) {
                            line-height: 1.29;
                        }
                    }
                }

                .description-2 {
                    img {
                        max-width: 15px;
                    }

                    span {
                        line-height: 1.29;
                    }
                }
            }
        }
    }
}
