section.allreports-categories {
    background: $mirage;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 0;
    min-height: 100vh;
    padding: 70px 20px 18%;
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    @media (min-width: $screen-sm) {
        padding: 80px 36px 100px;
    }

    @media (min-width: $screen-lg) {
        background: linear-gradient(to top, $mirage 72%, $bunkerDark6 100%);
        padding-top: 105px;
    }

    @media (min-width: $screen-1680) {
        padding-top: 100px;
        padding-left: 52px;
        padding-right: 52px;
    }

    @media (min-width: $screen-fhd) {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    &::before {
        background-color: $ebonyClay;
        bottom: inherit;
        content: '';
        display: none;
        left: 0;
        padding-bottom: 100vw;
        position: absolute;
        top: 18vw;
        -webkit-transform: rotate(-90deg) skewX(-15deg) translateX(0);
        transform: rotate(-90deg) skewX(-15deg) translateX(0);
        transform-origin: 0 0;
        width: 100%;
        z-index: 0;

        @media (min-width: $screen-lg) {
            background-color: $mirage;
            display: block;
            padding-bottom: 100vw;
            top: 80px;
            -webkit-transform: rotate(-90deg) skewX(-10deg) translateX(0);
            transform: rotate(-90deg) skewX(-10deg) translateX(0);
            z-index: -1;
        }
    }

    .title {
        height: 32px;
        padding: 0;
        margin: 0;
        line-height: 26px;
        font-size: 24px;
        font-weight: 500;
        text-align: right;

        @media (min-width: $screen-sm) {
            font-size: 36px;
            line-height: 0.9;
            margin-right: 3.5px;
        }

        @media (min-width: $screen-lg) {
            font-size: 48px;
            height: 71px;
            line-height: 1.7;
            margin-right: 0;
        }

        @media (min-width: $screen-1680) {
            padding-right: 0;
        }
    }

    .categories-filters {
        @media (min-width: $screen-lg) {
            padding-left: 4.4vw;
            padding-right: 4.4vw;
            text-align: right;
        }

        @media (min-width: $screen-1680) {
            align-items: center;
            margin-bottom: 30px;
            padding-left: 0;
            padding-right: 0;
        }

        ul {
            text-align: left;
        }
    }

    .categories-tiles .categories-container {
        background: none;
        margin-bottom: 42px;

        @media (min-width: $screen-sm) {
            margin-bottom: 56px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 74px;
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 100px;
        }

        .categories-thumbnail-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            @media (min-width: $screen-lg) {
                padding-right: 1.7vw;
            }

            @media (min-width: $screen-1680) {
                padding-right: 0;
            }

            &::after {
                content: '';
                flex-basis: 33%;

                @media (min-width: $screen-sm) {
                    flex-basis: 512px;
                }
            }

            .category-item {
                float: none;
                margin-top: 0;
                padding: 0;

                @media (min-width: $screen-sm) {
                    margin-top: 55px;
                }

                @media (min-width: $screen-lg) {
                    margin-top: 60px;
                }

                .category-content {
                    height: 75px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    background: none;
                    border-bottom: 2px solid $outerSpace3;
                    border-radius: 0;
                    box-shadow: none;

                    .report-titles {
                        margin: 0;

                        @media (min-width: $screen-sm) {
                            margin-top: 40px;
                        }

                        @media (min-width: $screen-lg) {
                            margin-right: 0;
                            margin-top: 60px;
                        }

                        @media (min-width: $screen-fm) {
                            margin-top: 45px;
                        }

                        .report-title {
                            @media (min-width: $screen-fm) {
                                font-size: 20px;
                            }
                        }

                        .category-title {
                            display: block;
                        }
                    }

                    .icon {
                        width: 36px;
                        height: 36px;
                        position: initial;
                        margin-right: 0;
                    }

                    @media (min-width: $screen-sm) {
                        align-items: inherit;
                        background: $mirage;
                        border-bottom: none;
                        border-radius: 5px;
                        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
                        flex-direction: column;
                        height: 571px;

                        .icon {
                            width: auto;
                            height: 70px;
                            position: absolute;
                            top: -35px;
                            margin-right: 30px;

                            @media (min-width: $screen-lg) {
                                margin-right: 50px;
                            }

                            @media (min-width: $screen-1680) {
                                margin-right: 32px;
                            }
                        }
                    }

                    @media (min-width: $screen-fm) {
                        height: 700px;
                    }
                }

                .chart-and-legend-container {
                    display: none;

                    @media (min-width: $screen-sm) {
                        display: flex;
                    }

                    @media (min-width: $screen-1680) {
                        padding: 0 32px;
                    }
                }
            }
        }
    }
}

@import '../../js/controllers/AllReports/AllReports';
@import '../../components/ReportsFilters/ReportsFilters';
@import '../../components/ReportsBreadcrumbs/ReportsBreadcrumbs';
