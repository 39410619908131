$footerContentWidthMobile: 618px;
$footerContentWidth: 1160px;
$footerDividerColor: #25303b;
$footerBackgroundColor: #192129;
$color-white: #ffffff;

$footerActiveLinkColor: #00fac5;

$font-family-regular: 'DIN Next LT Arabic', Ubuntu, Helvetica, Calibri, sans-serif;

.Footer {
    float: right;
    width: 100%;
    background-color: $footerBackgroundColor;
    padding: 0 20px;

    @media (min-width: 1024px) {
        padding: 40px 20px 16px;
    }

    &__content {
        margin: auto;
        max-width: $footerContentWidth;
        display: flex;
        flex-direction: column;

        @media (max-width: 1024px) {
            max-width: $footerContentWidthMobile;
        }

        &__section {
            display: flex;
            align-items: flex-end;
            gap: 16px;
            padding: 30px 0;

            &.hidden {
                display: none;
            }

            &:first-of-type {
                flex-direction: column;
                align-items: center;
                border-bottom: 1px solid $footerDividerColor;

                @media (min-width: 1024px) {
                    padding: 40px 0;
                    gap: 0;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            @media (min-width: 1024px) {
                padding: 40px 0;
                gap: 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .logo-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
                margin-left: auto;

                @media (min-width: 1024px) {
                    margin-left: unset;
                }

                &__divider {
                    width: 1px;
                    height: 40px;
                    background-color: $footerDividerColor;
                }

                &__text {
                    color: $color-white;
                    font-family: $font-family-regular;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .links-container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 40px;

                @media (min-width: 1024px) {
                    flex-direction: row;
                    align-items: flex-start;
                }

                &__icons {
                    display: flex;
                    gap: 8px;
                }

                &__links {
                    display: flex;
                    gap: 24px;

                    a {
                        text-decoration: none;
                        color: $color-white;
                        font-family: $font-family-regular;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }

        &__section--mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            padding: 30px 0;

            &.hidden {
                display: none;
            }

            .links-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 16px;

                @media (min-width: 1024px) {
                    margin-left: unset;
                }

                &__icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    a {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                    }
                }

                &__logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    &__text {
                        color: $color-white;
                        font-family: $font-family-regular;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }

            .pages-container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 40px;

                @media (min-width: 1024px) {
                    flex-direction: row;
                    align-items: flex-start;
                }

                &__links {
                    display: flex;
                    gap: 24px;

                    a {
                        text-decoration: none;
                        color: $color-white;
                        font-family: $font-family-regular;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }

        &__banner {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;

            @media (min-width: 1024px) {
                padding-bottom: 0;
            }

            img {
                width: 225px;
            }
        }
    }
}

.FooterNav {
    display: flex;
    gap: 32px;

    @media (min-width: 1024px) {
        gap: 16px;
    }
}

.FooterNavItem {
    text-decoration: none;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__underline {
            width: 0;
            height: 2px;
            background-color: $color-white;
            &.active {
                width: 100%;
                background-color: $footerActiveLinkColor;
            }
        }

        &__text {
            color: $color-white;
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
        }
    }
}
