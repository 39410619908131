.product-message-container {
    direction: ltr;

    left: 0;
    position: absolute;
    right: 0;
    top: 34px;

    visibility: visible;
    opacity: 1;
    transition: opacity 200ms, visibility 200ms linear;

    @media (min-width: $screen-lg) {
        left: 100%;
        top: 0;
    }

    &.remove {
        visibility: hidden;
        opacity: 0;
    }

    .triangle {
        background-color: $cabaret;
        border-radius: 3px;
        height: 20px;
        left: 50%;
        margin-left: 10px;
        position: absolute;
        top: -5px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 20px;

        @media (min-width: $screen-lg) {
            left: 0;
            top: 4px;
        }
    }

    .message {
        background-color: $cabaret;
        border-radius: 3px;
        color: $white;
        display: inline-block;
        font-size: 14px;
        padding: 5px 13px 5px 13px;
        position: relative;
        white-space: nowrap;

        @media (min-width: $screen-lg) {
            left: 13px;
            top: 0;
        }
    }
}
