.viz {
    position: relative;

    #d3plus_graph_background,
    #bg {
        fill-opacity: 0;
    }

    .d3plus_data {
        opacity: 1;
    }

    &::before {
        border-bottom: 1px solid $abbey;
        bottom: 18px;
        content: '';
        left: 10px;
        position: absolute;
        width: calc(100% - 10px);
        z-index: 1;
    }
}

html[dir='rtl'] {
    .viz {
        margin-right: -10px;

        &::before {
            left: inherit;
            right: 10px;
        }
    }

    .d3plus_tester {
        left: 0 !important;
        position: relative !important;
    }
}

@import 'line';
