.report-summary {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    @media #{$standard-mobile} {
        margin-top: 24px;
    }

    @media #{$tablet} {
        justify-content: flex-start;
    }
}
