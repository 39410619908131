$iconWidthMobile: 45px;
$iconWidthTablet: 60px;

.search-bar {
    display: flex;
    height: 40px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media #{$desktop-sm} {
        height: 50px;
    }

    &.open {
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 3%;
            right: 3%;
            bottom: 0;
            border-bottom: 2px solid $brightTurquoise5;
            opacity: 0.2;
        }
    }

    .search-bar-input {
        color: $white;
    }

    .search-bar-btn {
        background-color: transparent;
        border: none;
        height: 100%;
        padding: 0;
        width: $iconWidthMobile;

        @media #{$desktop-sm} {
            width: $iconWidthTablet;
        }

        &.icon-search {
            color: $tealish;
            float: right;
        }

        &.icon-x {
            opacity: 0;

            &::before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                margin: auto;
                background: url('/frontend/img/icons/close-x.svg');
                filter: invert(84%) sepia(11%) saturate(182%) hue-rotate(174deg) brightness(101%) contrast(85%);
                cursor: pointer;
            }
        }

        &.btn-disabled {
            &:hover {
                cursor: default;
            }

            &:before {
                opacity: 0.5;
            }
        }
    }

    .search-bar-label {
        -webkit-flex-grow: 1;
        flex-grow: 1;
        font-weight: normal;
        margin: 0;

        @media (min-width: $screen-fm) {
            font-size: 18px;
        }

        @media (min-width: $screen-hd) {
            font-size: 20px;
        }

        .search-bar-input {
            background: transparent;
            border: none;
            height: 35px;
            line-height: 23px;
            width: 100%;

            @media #{$desktop-sm} {
                height: 45px;
            }

            &:focus {
                outline: none;
            }
        }

        .search-bar-placeholder {
            color: $regentGray2;
            display: block;
            font-size: 14px;
            opacity: 1;
            padding: 0;
            pointer-events: none;
            position: absolute;
            right: $iconWidthMobile;
            text-transform: capitalize;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-transition: all, 0.2s;
            transition: all, 0.2s;
            user-select: none;
            white-space: nowrap;

            @media #{$desktop-sm} {
                font-size: 18px;
                right: $iconWidthTablet;
            }

            @media (min-width: $screen-lg) {
                font-size: 16px;
            }

            @media (min-width: $screen-fm) {
                font-size: 18px;
            }

            @media (min-width: $screen-hd) {
                font-size: 20px;
            }

            &.active {
                opacity: 0;
            }

            .search-bar-placeholder-label {
                display: block;
                float: right;
                margin: 0 auto;
                overflow: hidden;
                white-space: nowrap;

                &.start-animation {
                    animation: searchPlaceholderAnimation 5s steps(150, end);
                }

                span {
                    display: block;
                    text-align: right;
                    width: 300px;
                }
            }

            @keyframes searchPlaceholderAnimation {
                0% {
                    width: 0;
                }
                50% {
                    width: 100%;
                }
            }
        }
    }
}
