//--------------------------------------
//  $Sass Helpers
//--------------------------------------
$SliderBlack: #07262f;
$thumb-w: 42px;
$thumb-h: 54px;
$track-h: 4px;
$track-border-radius: 4.5px;
$pointer-h: 10px;
//--------------------------------------
//  $Section
//--------------------------------------

body {
    background-color: $SliderBlack;
}

.chart-slider {
    margin: 0 auto;
    overflow: visible;
    position: relative;
    width: 100%;

    @media (min-width: $screen-sm) {
        direction: ltr;
        display: block;
    }
}

.slider-column {
    -webkit-order: 5;
    order: 5;
    padding: 0 11%;

    @media #{$iphoneXHorizontal} {
        margin-right: 158px;
        padding: 0;
        width: 464px;
    }

    @media #{$desktop-sm} {
        margin-bottom: 40px;
        -webkit-order: 4;
        order: 4;
        padding: inherit;
    }

    @media #{tablet} {
        display: flex;
        justify-content: center;
    }

    @media (min-width: $screen-lg) {
        clear: both;
        margin-bottom: 0;
    }

    .year-dropdown {
        .dropdown-toggle {
            margin-top: 0;

            @media (min-width: $screen-sm) {
                margin-top: 10px;
            }
        }

        span.filter-option.center {
            color: $aquamarine2;
        }

        .text.decorator {
            color: $turquoise !important;
        }
    }
}

//--------------------------------------
//  $Slider Reset
//--------------------------------------

.range-input {
    -webkit-appearance: none;
    background: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &::after {
        background: $aquamarine2;
        border-radius: $track-border-radius;
        content: '';
        cursor: pointer;
        height: $track-h;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 0;

        @media (min-width: $screen-sm) {
            height: 5px;
        }

        @media (min-width: $screen-lg) {
            height: $track-h;
        }
    }

    &:focus {
        outline: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &::-ms-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
        cursor: pointer;
        width: 100%;
    }
}

//--------------------------------------
//  $Thumb
//--------------------------------------
@mixin thumb($width, $height) {
    background: url('frontend/img/slider/pinezka-cien.svg') no-repeat bottom center;
    border: none;
    cursor: ew-resize;
    height: $height;
    overflow: visible;
    position: relative;
    width: $width;
    z-index: 1;
}

.range-input {
    &::-webkit-slider-thumb {
        @include thumb($thumb-w, $thumb-h);
        -webkit-appearance: none;
    }

    &::-moz-range-thumb {
        @include thumb($thumb-w, $thumb-h);
        transform: translateY(6px);
    }

    &::-ms-thumb {
        @include thumb($thumb-w, $thumb-h);
        transform: translateY(6px);
    }

    .custom-thumb {
        @include thumb($thumb-w, $thumb-h);
        position: absolute;

        // Safari browser selector
        @supports (-webkit-backdrop-filter: blur(1px)) {
            background-image: none;
            background-color: $aquamarine2;
            border-radius: 50%;
            border: 3px solid #1a2027;
            box-shadow: 0 0 0 1px $aquamarine2;
            height: 24px;
            width: 24px;
            margin-top: -10px !important;
        }
    }
}

//--------------------------------------
//  $Track
//--------------------------------------

$track-xs: 489px;
$track-sm: 599px;
$track-lg: 496px;
$track-fm: 606px;
$track-lgmin: 795px;
$track-iPad: 656px;

@mixin track($track-xs, $track-sm, $track-lg, $track-fm, $track-lgmin) {
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    height: $track-h;
    margin-bottom: 6px;
    margin-top: 6px;
    max-width: $track-xs;
    width: 100%;
    z-index: 1;

    @media (min-width: $screen-sm) {
        height: 5px;
        max-width: $track-sm;
    }

    @media #{$tablet} {
        max-width: unset;
    }

    @media (min-width: $screen-lg) {
        height: $track-h;
        max-width: $track-lg;
    }

    @media (min-width: $screen-fm) {
        max-width: $track-fm;
    }

    @media (min-width: $screen-hd) {
        max-width: $track-lgmin;
    }
}

.range-input {
    &::-webkit-slider-runnable-track {
        margin-bottom: 6px;
    }

    &::-moz-range-track {
        background: $aquamarine2;
    }

    &::-ms-track {
        border-width: 16px 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: $aquamarine2;
    }

    &::-ms-fill-upper {
        background: $aquamarine2;
    }

    .custom-track {
        background: $aquamarine2 !important;
    }
}

//--------------------------------------
//  $Slider labels
//--------------------------------------

.range-labels {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;

    &-item {
        color: $osloGray;
        cursor: pointer;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.2s color ease-in-out;
        white-space: nowrap;

        @media (min-width: $screen-sm) {
            font-size: 16px;
            height: 23px;
        }

        @media (min-width: $screen-lg) {
            font-size: 14px;
            line-height: 21px;
        }

        @media (min-width: $screen-fm) {
            font-size: 16px;
            line-height: 0.83;
        }

        @media (min-width: $screen-hd) {
            font-size: 18px;
        }

        &.item-disabled {
            color: rgba($osloGray, 0.5);
        }

        &.active {
            color: $aquamarine2;
        }
    }

    .year-slider & {
        &-item {
            &:nth-child(even) {
                display: none;
            }

            &:last-child {
                display: inherit;
            }
        }
    }
}

//--------------------------------------
//  $Custom styles
//--------------------------------------

.chart-slider {
    &.radar {
        @media (min-width: $screen-sm) {
            margin: 20px auto 0;
            max-width: 606px;
        }

        @media #{$desktop-sm} {
            margin-bottom: 2px;
        }

        @media (min-width: $screen-lg) {
            margin-top: 26px;
            max-width: 501px;
        }

        @media (min-width: $screen-fm) {
            margin-top: 60px;
            max-width: 606px;
        }

        .range-input {
            margin-bottom: 18px;
            max-width: 501px;

            @media (min-width: $screen-sm) {
                margin-bottom: 15px;
                max-width: 606px;
            }

            @media #{$desktop-sm} {
                margin-bottom: 10px;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 16px;
                max-width: 501px;
            }

            @media (min-width: $screen-fm) {
                max-width: 606px;
            }

            &::after {
                max-width: 489px;
                top: 8px;

                @media (min-width: $screen-sm) {
                    max-width: 609px;
                }

                @media (min-width: $screen-lg) {
                    max-width: 489px;
                }

                @media (min-width: $screen-fm) {
                    max-width: 599px;
                }
            }

            &::-webkit-slider-runnable-track {
                @include track(489px, 609px, 496px, 606px, 606px);
            }

            &::-moz-range-track {
                @include track(489px, 609px, 496px, 606px, 606px);

                background: $aquamarine2;
            }

            &::-ms-track {
                @include track(489px, 599px, 496px, 606px, 606px);
            }

            .custom-track {
                @include track(489px, 599px, 496px, 606px, 606px);
                position: relative;
                background: $aquamarine2;
            }

            &::-webkit-slider-thumb {
                margin-top: -19px;
            }

            &::-moz-range-thumb {
                transform: translateY(6px);
            }

            &::-ms-thumb {
                transform: translateY(6px);
            }

            .custom-thumb {
                margin-top: -19px;
                position: absolute;
            }
        }

        .range-labels {
            max-width: 485px;

            @media (min-width: $screen-sm) {
                max-width: 599px;
            }

            @media (min-width: $screen-lg) {
                max-width: 485px;
            }

            @media (min-width: $screen-fm) {
                max-width: 599px;
            }
        }
    }

    &.line {
        margin-top: -8px;
        width: calc(100% - 28px);
        margin-left: 28px;

        @-moz-document url-prefix() {
            .year-slider {
                margin-left: 28px;
            }
        }

        .range-input {
            margin-bottom: 18px;

            @media (min-width: $screen-lg) {
                margin-bottom: 23px;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 26px;
            }

            .custom-track {
                @include track(489px, 599px, 548px, 676px, 795px);
                position: relative;
                margin-left: auto;
                margin-right: 0;
            }

            .custom-thumb {
                margin-top: -19px;
                position: absolute;
            }

            .custom-range-container {
                display: flex;
            }

            &::after {
                left: inherit;
                right: 0;
                top: 8px;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(0%, -50%);

                @media (min-width: $screen-lg) {
                    max-width: 548px;
                }

                @media (min-width: $screen-fm) {
                    max-width: 676px;
                }

                @media (min-width: $screen-hd) {
                    max-width: 794px;
                }
            }

            &::-webkit-slider-runnable-track {
                @include track(489px, 599px, 548px, 676px, 795px);

                margin-left: auto;
                margin-right: 0;
            }

            &::-moz-range-track {
                @include track(489px, 599px, 548px, 676px, 795px);

                background: $aquamarine2;
                margin-left: auto;
                margin-right: 0;
            }

            &::-ms-track {
                @include track(489px, 599px, 548px, 676px, 795px);

                margin-left: auto;
                margin-right: 0;
            }

            &::-webkit-slider-thumb {
                margin-top: -19px;
            }

            &::-moz-range-thumb {
                transform: translateY(6px);
            }

            &::-ms-thumb {
                transform: translateY(6px);
            }
        }

        .range-labels {
            margin-left: auto;
            margin-right: 4px;

            &-item {
                @media (min-width: $screen-lg) {
                    font-size: 14px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                }

                @media (min-width: $screen-hd) {
                    font-size: 18px;
                }
            }
        }
    }

    @media #{$mobileHorizontal} {
        margin-bottom: 50px;
    }
}

._chart_mg_add.radar-chart-container[data-is-trade-report='1'] {
    .chart-slider.radar {
        @media (min-width: $screen-sm) {
            margin-top: 19px;
        }

        @media (min-width: $screen-lg) {
            margin-top: 21px;
        }

        @media (min-width: $screen-fm) {
            margin-top: 15px;
        }

        @media (min-width: $screen-fhd) {
            margin-top: 19px;
        }

        .range-input {
            display: none;
        }

        .range-labels {
            justify-content: center;

            .range-labels-item {
                cursor: pointer;
                font-size: 16px;
                line-height: 21px;
                padding: 0 10px;
                position: relative;

                @media (min-width: $screen-lg) {
                    font-size: 14px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                }

                @media (min-width: $screen-hd) {
                    font-size: 18px;
                }

                &:first-of-type:after {
                    border-right: 1px solid $brightTurquoise;
                    bottom: 5px;
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 5px;

                    @media (min-width: $screen-lg) {
                        bottom: 6px;
                        top: 6px;
                    }

                    @media (min-width: $screen-fm) {
                        bottom: 5px;
                        top: 5px;
                    }
                }
            }
        }
    }
}
