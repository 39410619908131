.gender-nationality-toggle {
    .toggle-select-button {
        &.active {
            background-color: $brightTurquoise;
        }

        &.disabled {
            opacity: 0.3;
        }
    }

    .nationality-no-data-tooltip {
        position: absolute;
        left: 20px;
        background-color: $cabaret;
        padding: 8px 12px;
        margin-top: 8px;
        border-radius: 4px;

        font-size: 14px;
        line-height: 1.43;
        color: $white;
        display: none;

        &.active {
            display: block;
        }

        &::after {
            background-color: $cabaret;
            content: '';
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            height: 16px;
            left: 48px;
            width: 16px;
            top: -8px;
        }

        @media (min-width: $screen-lg) {
            left: 40px;

            &::after {
                left: 32px;
            }
        }
    }
}
