.year-chooser {
    padding-top: 30px;
    margin-bottom: 30px;

    .year-chooser-title {
        text-align: right;
        font-size: 20px;
        color: $white;
        margin-bottom: 12px;
    }

    &.with-border {
        border-top: 1px solid $charcoalGrey;
    }

    @mixin rangeTrack {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: $osloGray;
    }

    @mixin rangeThumb {
        width: 18px;
        height: 18px;
        margin: -8px 0 0;
        border-radius: 50%;
        background: $brightTurquoise;
        border: 3px solid $mirage;
        box-shadow: 0 0 0 1px $brightTurquoise;
        cursor: pointer;
    }

    .year-chooser-range-input {
        -webkit-appearance: none;
        direction: ltr;
        padding: 12px 0 20px;
        background: transparent;
        cursor: pointer;
        z-index: 9999;

        &:focus {
            background: none;
            outline: none;
        }

        // Thumb
        &::-webkit-slider-thumb {
            -webkit-appearance: none; // needed again for Chrome & Safari
            @include rangeThumb;
        }

        &::-moz-range-thumb {
            @include rangeThumb;
        }

        &::-ms-thumb {
            @include rangeThumb;
        }

        .custom-thumb {
            @include rangeThumb;
            position: absolute;
        }

        // Track
        &::-webkit-slider-runnable-track {
            @include rangeTrack;
        }

        &::-moz-range-track {
            @include rangeTrack;
        }

        &::-ms-track {
            @include rangeTrack;
        }

        .custom-track {
            @include rangeTrack;
            position: relative;
        }
    }

    .range-labels {
        direction: ltr;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -16px;
        pointer-events: none;

        .range-labels-item {
            font-size: 14px;
            line-height: 1.43;
            width: 20%;
            opacity: 0;

            &.start {
                padding-left: 4px;
            }

            &.end {
                padding-right: 4px;
            }

            &.always-show {
                opacity: 1;
            }

            &.active {
                font-size: 18px;
                line-height: 1.33;
                color: $brightTurquoise;
                margin-top: 8px;
                opacity: 1;

                &.start {
                    padding-right: 8px;
                    padding-left: 0;
                }

                &.end {
                    padding-left: 4px;
                    padding-right: 0;
                }
            }
        }
    }

    .year-dropdown {
        .btn-group.bootstrap-select.btn-group {
            .dropdown-menu {
                &.open {
                    margin-top: 40px;
                }
            }
        }
    }
}
