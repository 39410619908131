.navigation {
    list-style-type: none;
    padding: 0;
    position: fixed;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    @media #{$ipad-small} {
        right: 18px;
    }

    @media (min-width: $screen-lg) {
        left: 1.65vw;
        right: inherit;
    }

    @media (min-width: $screen-fm) {
        left: 1vw;
    }

    @media (min-width: $screen-fhd) {
        left: 2.5vw;
    }

    li {
        &:last-child {
            display: none;
        }

        margin: 3px 0;

        @media #{$ipad-small} {
            margin: 5px 0;
        }

        @media (min-width: $screen-lg) {
            margin: 12px 0;
        }

        .navigation-item {
            background-color: $cornflowerBlue;
            border: solid 1px $osloGray;
            border-radius: 50%;
            display: block;
            height: 6px;
            width: 6px;

            @media #{$ipad-small} {
                height: 10px;
                width: 10px;
            }

            @media (min-width: $screen-lg) {
                height: 16px;
                width: 16px;
            }

            &:hover {
                cursor: pointer;
            }

            &.active {
                background-color: $brightTurquoise;
            }
        }
    }
}
