.breadcrumbs-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .breadcrumb-part {
        font-size: 16px;
        font-weight: 500;
        text-align: right;
        color: $white;
        line-height: 1.75;

        @media (min-width: $screen-lg) {
            line-height: 1.5;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .breadcrumb-separator {
        margin: 0 4px;
    }
}
