.legend-container {
    color: #e2e36c;
    display: flex;
    flex-flow: column nowrap;
    font-size: 14px;
    justify-content: center;
    align-content: flex-start;
    line-height: 1.5;
    min-height: unset;
    text-align: right;
    padding-bottom: 15px;
    min-height: 70px;

    @media (min-width: $screen-xs) {
        min-height: 130px;
    }

    @media (min-width: $screen-lg) {
        flex-flow: row wrap;
        min-height: 80px;
        width: 100%;
    }

    @media (min-width: $screen-fm) {
        min-height: 120px;
    }

    &.color-group-1 {
        @include homepageLegendColors($radar-chart-homepage-colors-group-1);
    }

    &.color-group-2 {
        @include homepageLegendColors($radar-chart-homepage-colors-group-2);
    }

    &.color-group-3 {
        @include homepageLegendColors($radar-chart-homepage-colors-group-3);
    }

    &.color-group-demography-1 {
        @include homepageLegendColors($chart-gender-colors);
    }

    &.color-group-demography-2 {
        @include homepageLegendColors($chart-nationality-colors);
    }

    &.color-group-product-1 {
        @include homepageLegendColors($line-chart-homepage-colors-group-1);
    }

    &.color-group-product-2 {
        @include homepageLegendColors($line-chart-homepage-colors-group-2);
    }

    .category-element {
        -webkit-align-items: baseline;
        align-items: baseline;
        -webkit-align-self: baseline;
        align-self: baseline;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row-reverse;
        flex-direction: row-reverse;
        font-size: 14px;
        -webkit-justify-content: center;
        justify-content: center;
        line-height: 1;
        margin-top: 1vh;
        margin-top: calc(var(--vh, 1vh) * 1);
        position: relative;

        @media (min-width: $screen-sm) {
            line-height: 1.5;
            margin-top: 0.8vh;
            margin-top: calc(var(--vh, 1vh) * 0.8);
        }

        @media (min-width: $screen-lg) {
            margin-top: 8px;
            padding: 0 10px;
        }

        .category-element-marker {
            background-color: yellow;
            border-radius: 100px;
            display: inline-block;
            height: 10px;
            margin-left: 4px;
            min-width: 19px;
        }
    }
}
