.static-x-axis {
    background: $aquamarine2;
    border-radius: $track-border-radius;
    display: block;
    height: 3px;

    @media (min-width: $screen-sm) {
        display: none;
    }
}

.labels {
    direction: ltr;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    list-style: none;
    margin: 13px auto 0;
    padding: 0;

    @media (min-width: $screen-8plus) {
        margin-top: 17px;
    }

    .label-item {
        color: $osloGray;
        font-size: 14px;
        line-height: 21px;
    }

    &.odd-last-year {
        padding: 0 0 0 38px;

        .label-item:last-of-type {
            margin-right: 4px;
        }
    }
}
