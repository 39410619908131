.tile.see-more {
    .category-content {
        display: flex;
        justify-content: center;
        text-align: center;

        .icon {
            height: 38px;
            position: relative;
            top: inherit;
        }

        .link-title,
        .link-description {
            line-height: 1.5;
        }

        .link-title {
            color: $white;
            font-size: 20px;
            margin-bottom: 12px;
            margin-top: 14px;
        }

        .link-description {
            color: $regentGray2;
            font-size: 14px;
            margin-bottom: 25px;
        }

        .link-btn {
            margin: 0 auto;
            padding: 10px;
            width: 65%;
        }
    }
}
