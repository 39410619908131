.switch {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 52px;
    height: 28px;
    border-radius: 4px;
    border: solid 1px $charcoalGrey;
    margin: 0;
    cursor: pointer;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    width: 20px;
    height: 20px;
}

.slider:after {
    position: absolute;
    content: url('/frontend/img/components/toggle/cross.svg');
    left: 8px;
    bottom: 2px;
    -webkit-transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
}

input:checked + .slider:after {
    content: url('/frontend/img/components/toggle/tick.svg');
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.slider:before {
    position: absolute;
    content: '';
    background-color: $osloGray;
    width: 20px;
    height: 20px;
    left: 28px;
    border-radius: 2px;
    -webkit-transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
}

input:checked + .slider:before {
    background-color: $algaeGreen;
    -webkit-transform: translateX(-24px);
    -ms-transform: translateX(-24px);
    transform: translateX(-24px);
}
