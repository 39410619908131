.radar-chart-container {
    .main-panel {
        @media (min-width: $screen-lg) {
            display: block;
            margin-left: 80px;
        }
        @media (min-width: $screen-fm) {
            margin-left: 100px;
        }

        @media (min-width: $screen-hd) {
            margin-left: 92px;
        }

        .chart-content {
            @media (min-width: $screen-lg) {
                top: calc(50% + 16.5px);
            }

            @media (min-width: $screen-fm) {
                top: calc(50% + 23px);
            }
        }

        .chart-column,
        .slider-column {
            @media (min-width: $screen-lg) {
                width: 440px;
            }

            @media (min-width: $screen-fm) {
                margin-left: 28px;
                width: 540px;
            }

            @media (min-width: $screen-hd) {
                margin-left: 0;
                width: 670px;
            }

            .range-input {
                @media (min-width: $screen-lg) {
                    max-width: 473px;
                }

                @media (min-width: $screen-fm) {
                    max-width: 568px;
                }

                &::after {
                    @media (min-width: $screen-lg) {
                        max-width: 461px;
                    }

                    @media (min-width: $screen-fm) {
                        max-width: 561px;
                    }
                }
            }

            .range-labels {
                @media (min-width: $screen-lg) {
                    max-width: 429px;
                }

                @media (min-width: $screen-fm) {
                    max-width: 532px;
                }

                @media (min-width: $screen-hd) {
                    max-width: 565px;
                }
            }
        }

        .mobile-column {
            .owl-dots {
                margin-top: 0;
                transform: translateY(-28px);

                @media #{$standard-mobile} {
                    transform: translateY(-38px);
                }

                @media (min-width: $screen-sm) {
                    margin-top: inherit;
                    transform: inherit;
                }
            }

            .graph-container .chart-container {
                transform: translateY(-6px);

                @media #{$standard-mobile} {
                    transform: translateY(-12px);
                }
            }
        }

        .summary-container .categories-dropdown {
            margin-bottom: 0;
            padding-bottom: 28px;

            @media #{$standard-mobile} {
                padding-bottom: 48px;
            }

            @media (min-width: $screen-sm) {
                margin-bottom: inherit;
                padding-bottom: inherit;
            }
        }
    }

    .owl-stage-outer {
        @media #{$standard-mobile} {
            height: 100%;
        }
    }
}
