.private-establishments-chart-container {
    @media #{$standard-mobile} {
        .chart-container-wrapper {
            .main-panel {
                .source-column {
                    padding-top: 13px;
                }
            }
        }
    }
}
