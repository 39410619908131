section.sector-title {
    padding: 35px 10%;

    @media #{$desktop-sm} {
        background-image: linear-gradient(to bottom, $bunkerDark6 0%, $mirage 80%);
        margin-top: 0;
        padding: 40px 10%;
        transform: none;
    }

    @media (min-width: $screen-fhd) {
        padding: 54px $multichart-horizontal-margin-lg;
    }

    .icon {
        display: inline-block;
        height: 55px;
        width: auto;

        @media #{$desktop-sm} {
            height: 80px;
            width: auto;
        }

        @media (min-width: $screen-fm) {
            height: 100px;
            width: auto;
        }
    }

    .main-report-title {
        color: $white;
        font-size: 24px;
        line-height: 24px;
        margin: 3px auto 0;
        max-width: 100%;

        @media #{$desktop-sm} {
            font-size: 48px;
            line-height: 90px;
            margin: 0;
        }

        @media (min-width: $screen-lg) {
            line-height: 85px;
        }

        @media (min-width: $screen-fm) {
            font-size: 60px;
            line-height: 90px;
            margin: 20px 0 0;
        }
    }

    .subtitle {
        display: none;
        margin-bottom: 0;

        @media #{$desktop-sm} {
            display: block;
            margin-top: 14px;
        }

        @media (min-width: $screen-fm) {
            margin-top: 20px;
        }

        p {
            margin-bottom: 0;
        }
    }

    h1,
    p,
    .icon {
        position: relative;
        z-index: 2;
    }
}
