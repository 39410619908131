.filters-container {
    margin-top: 60px;

    .filter-category-container {
        display: flex;
        flex-direction: row;
        width: 290px;
        min-height: 40px;
        border-radius: 24px;
        border: solid 2px $brightTurquoise;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &.active {
            border: solid 2px $white;
            margin-bottom: 12px;

            .filter-label {
                color: $white;
            }
        }

        .filter-label {
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            color: $brightTurquoise;
            margin: 0 16px;
            text-align: right;
        }
    }

    .sub-filter-category-container {
        display: flex;
        flex-direction: row;
        width: 290px;
        min-height: 20px;
        justify-content: space-between;
        margin-bottom: 16px;

        &:hover {
            cursor: pointer;
            text-decoration: none;

            @media (min-width: $screen-lg) {
                .name {
                    color: $white;
                }
            }
        }

        &.active {
            .filter-label {
                color: $white;
            }
        }

        .filter-label {
            font-size: 14px;
            font-weight: bold;
            color: $osloGray;
            margin: 0 16px;
            text-align: right;
        }

        &.lower {
            margin-bottom: 0;
            border-right: 1px solid $charcoalGrey;
            width: 270px;
            margin-right: 20px;

            .filter-label {
                margin-right: 12px;
                margin-bottom: 16px;
            }

            &:last-child {
                margin-bottom: 32px;

                .filter-label {
                    margin-bottom: 0;
                }
            }
        }
    }

    .lower-filters-header {
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: $brightTurquoise;
        height: 20px;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        margin-right: 16px;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        .filters-right-arrow {
            margin-left: 8px;
        }
    }
}

.all-reports-filters-container {
    position: fixed;
    width: 328px;
    right: -100%;
    top: 40px;
    bottom: 0;
    background-color: $mirage;
    overflow: hidden;
    z-index: 10;
    box-shadow: 0 -8px 22px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 -8px 22px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    will-change: transform;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $charcoalGrey;
    border-top: 1px solid $charcoalGrey;

    @media (min-width: $screen-sm) {
        width: 375px;
    }

    .filters-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 56px;
        border-bottom: 1px solid $charcoalGrey;

        .filters-close-button {
            width: 24px;
            height: 24px;
            margin-left: 16px;
            background: url('/frontend/img/icons/close-x.svg');
            filter: invert(58%) sepia(6%) saturate(271%) hue-rotate(179deg) brightness(89%) contrast(88%);
            cursor: pointer;
        }

        .filters-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            color: $white;
            text-align: right;
            margin-right: 16px;
        }
    }

    .filters-main-container {
        height: 100%;
        overflow: visible;

        .filters-container {
            margin-top: 20px;
            width: 100%;
            height: calc(100% - 104px);
            max-height: calc(100% - 104px);
            overflow: auto;
            outline: none;

            &.lower {
                display: block;

                .sub-filter-category-container {
                    width: 100%;
                    margin-right: 0;

                    &.lower {
                        width: calc(100% - 20px);
                        margin-right: 20px;
                    }
                }
            }

            .lower-filters-header {
                width: calc(100% - 52px);
            }

            .filter-category-container,
            .sub-filter-category-container {
                width: calc(100% - 32px);
                margin-right: 16px;
            }
        }

        .filters-show-button-container {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 24px;
            background-image: linear-gradient(to bottom, rgba(26, 32, 39, 0), rgb(26, 32, 39) 50%);

            .filters-show-button {
                min-height: 44px;
                border-radius: 4px;
                background-color: $brightTurquoise;
                width: calc(100% - 32px);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 16px 32px;

                .filters-show-button-label {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    color: $mirage;
                }
            }
        }

        .nano-pane {
            background: $brightGray2;
            left: 0;
            right: inherit;
            width: 4px;

            .nano-slider {
                background: $brightTurquoise3;
                border-radius: 5px;
                margin: 0;
                width: 4px;
            }
        }
    }
}

.filters-button-container {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 2px $brightTurquoise;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .filters-button-label {
        font-size: 16px;
        text-align: center;
        color: $brightTurquoise;
        font-weight: bold;
        margin-right: 8px;
    }

    .filters-button-icon {
        background: url('/frontend/img/icons/allReports/filters-button-icon.svg');
        width: 24px;
        height: 24px;
    }
}

.all-reports-filters-container-underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $mirage;
    opacity: 0.5;
    z-index: 5;
}
