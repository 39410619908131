.toggle-select {
    &.with-border {
        border-top: 1px solid $charcoalGrey;
    }

    @include landscape-sm {
        border: none;
        float: left;
    }

    @include landscape-lg {
        border: none;
        float: left;
    }
}

.toggle-select-container {
    margin-top: 30px;
    margin-bottom: 30px;

    @media (min-width: $screen-lg) {
        margin-top: 15px;
    }

    @media (min-width: $screen-fm) {
        margin-top: 18px;
    }

    @media (min-width: $screen-hd) {
        margin-top: 22px;
    }

    @include landscape-sm {
        margin-top: 0;
    }

    @include landscape-lg {
        margin-top: 0;
    }

    .toggle-title {
        text-align: right;
        font-size: 20px;
        color: $white;
        margin-bottom: 20px;
    }

    .toggle-select-outer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: solid 1px #37414c;
        height: 36px;
        padding-inline-start: 0;
        padding-right: 0;

        .toggle-select-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            width: 50%;
            height: 28px;
            margin: 4px;
            color: $white;
            cursor: pointer;

            &.active {
                color: $charcoalGrey;
                border-radius: 2px;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.6);
            }
        }
    }

    .reexport-toggle-container {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .reexport-title {
            font-size: 14px;
            text-align: right;
            color: $white;
        }
    }
}
