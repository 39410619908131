@mixin activeButton {
    background-color: $brightTurquoise;

    .default-button {
        display: none;
    }

    .active-button {
        display: block;
    }

    .single-button {
        filter: invert(0);
    }
}

.highlights {
    .highlights-descriptions {
        list-style: none;
        overflow: hidden;
        padding: 0 8%;
        text-align: right;

        @media #{$desktop-sm} {
            padding: 0 51px;
            text-align: right;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 0;
            padding: 0;
        }

        .highlights-description {
            @include landscape-sm {
                text-align: right;
            }

            @include landscape-lg {
                text-align: right;
            }

            &.active {
                -webkit-align-items: center;
                align-items: center;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: column;
                flex-direction: column;
                -webkit-justify-content: center;
                justify-content: center;

                @media #{$desktop-sm} {
                    -webkit-align-items: right;
                    align-items: right;
                }
            }

            .highlights-description-value {
                color: $brightTurquoise;
                display: inherit;
                font-size: 50px;
                line-height: 1;
                margin-bottom: 14px;

                @media (min-width: $screen-360) {
                    font-size: 60px;
                    line-height: 0.83;
                    margin-bottom: 17px;
                }

                @media (min-width: $screen-8plus) {
                    font-size: 70px;
                    line-height: 0.71;
                }

                @media #{$desktop-sm} {
                    font-size: 96px;
                    white-space: nowrap;
                    line-height: 1;
                    margin-bottom: 14px;
                    margin-right: 0;
                }

                @media (min-width: $screen-lg) {
                    line-height: 1;
                    margin-bottom: 18px;
                }

                @media (min-width: $screen-hd) {
                    font-size: 112px;
                    line-height: 0.9;
                    margin-bottom: 22px;
                }

                .value-suffix {
                    display: inline-block;
                    font-size: 20px;
                    margin-bottom: 4px;
                    vertical-align: bottom;

                    @media (min-width: $screen-360) {
                        font-size: 30px;
                        line-height: 0.67;
                    }

                    @media (min-width: $screen-8plus) {
                        font-size: 35px;
                        line-height: 0.57;
                        margin-bottom: 2px;
                    }

                    @media (min-width: $screen-sm) {
                        font-size: 40px;
                        line-height: 1;
                        margin-bottom: 12px;
                    }

                    @media (min-width: $screen-fm) {
                        font-size: 50px;
                        margin-bottom: 11px;
                    }

                    @media (min-width: $screen-hd) {
                        margin-bottom: 9px;
                    }
                }
            }

            .highlights-description-text {
                color: $white;
                font-size: 14px;
                line-height: 1.71;

                @media #{$desktop-sm} {
                    font-size: 30px;
                    line-height: 1.57;
                    margin-top: 5px;
                }

                @media (min-width: $screen-lg) {
                    font-size: 30px;
                    line-height: 1.57;
                    margin-top: 0;
                    max-width: 460px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 36px;
                    line-height: 1.31;
                    margin-top: 10px;
                    max-width: 660px;
                }

                @media (min-width: $screen-fhd) {
                    margin-top: 6px;
                    max-width: 760px;
                }
            }
        }
    }
    .highlights-buttons {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        list-style: none;
        margin-top: 24px;
        padding: 0 5%;

        @media (min-width: $screen-360) {
            margin-top: 27px;
        }

        @media (min-width: $screen-8plus) {
            margin-top: 26px;
        }

        @media #{$desktop-sm} {
            -webkit-justify-content: right;
            justify-content: right;
            margin-top: 38px;
            padding: 0 46px;
        }

        @media (min-width: $screen-lg) {
            margin-top: 42px;
            padding: 0;
        }

        @media (min-width: $screen-fm) {
            margin-top: 50px;
        }

        @media (min-width: $screen-fhd) {
            margin-top: 62px;
        }

        @media (max-width: $screen-xs) {
            justify-content: right;
            padding: 0 6%;
        }

        @media #{$iphone8} {
            justify-content: right;
            padding: 0 6%;
        }

        @include landscape-sm {
            margin-top: 28px;
        }

        @include landscape-lg {
            margin-top: 28px;
        }

        .highlights-button {
            background-color: $ebonyClayNew;
            border-radius: 5px;
            box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.5);
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            height: 36px;
            -webkit-justify-content: center;
            justify-content: center;
            margin: 0 5px;
            width: 42px;
            z-index: 1;

            @media (min-width: $screen-xs) {
                height: 60px;
                width: 70px;
            }

            @media #{$iphone8} {
                height: 42px;
                width: 50px;
            }

            @include landscape-sm {
                height: 44px;
                width: 60px;
            }

            @include landscape-lg {
                height: 44px;
                width: 60px;
            }

            .active-button {
                display: none;
            }

            .single-button {
                filter: invert(1);
            }

            &.active {
                @include activeButton;
            }

            @media (min-width: $screen-md) {
                &:hover {
                    @include activeButton;
                }
            }

            &.active {
                position: relative;

                &:before {
                    border-color: transparent transparent $brightTurquoise transparent;
                    border-style: solid;
                    border-width: 0 5px 7px 5px;
                    content: '';
                    height: 0;
                    left: calc(50% - 5px);
                    position: absolute;
                    top: -6px;
                    width: 0;
                }
            }

            &:hover {
                cursor: pointer;
            }

            img {
                height: 28px;
                margin: auto;
                max-height: 50%;
                max-width: 50%;
                width: 35px;
            }
        }
    }
}

