.rectangle-container {
    .category-filter {
        .prices-filter {
            .categories-list {
                overflow: scroll !important;
                overflow-x: hidden !important;
                max-height: 60vh;

                @media (min-width: $screen-lg) {
                    max-height: 328px;
                }

                .category-element {
                    @media #{$standard-mobile} {
                        padding-left: 16px;
                    }

                    @media (min-width: $screen-sm) {
                        padding-left: 24px;
                    }

                    @media #{$desktop-sm} {
                        padding-left: 24px;
                    }

                    @media (min-width: $screen-lg) {
                        padding-left: 16px;
                    }

                    @media (min-width: $screen-fm) {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}
