.treemap-chart-container,
.employees-chart-container,
.household-expenditure-chart-container,
.licenses-chart-container,
.private-establishments-chart-container {
    .chart-content {
        @media (min-width: $screen-lg) {
            padding-right: 16px;
        }
    }

    .main-panel {
        .summary-column {
            @media #{$tablet} {
                margin-top: 28px;
            }

            @media #{$tablet-large} {
                margin-top: 56px;
            }
        }

        .treemap-label-container {
            max-width: 100%;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            font-size: 11px;
            color: #1a2027;
            padding: 8px;

            @media (min-width: $screen-sm) {
                font-size: 14px;
            }

            .treemap-label {
                display: block;
                max-width: inherit;
                width: fit-content;
                height: fit-content;
                font-family: 'DIN Next LT Arabic Medium';
                word-break: break-word;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .treemap-label-text {
                width: fit-content;
                height: fit-content;
            }
        }
    }

    .mobile-column {
        .graph-container {
            .graph-content {
                margin-bottom: 12px;
            }
        }
    }
}
