.trade-traffic-filter {
    .categories-list {
        .category-element:nth-child(2) {
            margin-bottom: 6px;
        }
    }

    .reexport-category-element {
        @include categoryColors('.color-', $categories-colors, true);

        .category-element-marker {
            margin-right: 20px;
        }

        .l-shape {
            background: $osloGray;
            right: 7px;
            width: 8px;
            height: 1px;
            position: absolute;
            top: 13px;
        }
        .l-shape::after {
            background: $osloGray;
            bottom: 0;
            content: '';
            height: 18px;
            position: absolute;
            width: 1px;
        }
    }
}
