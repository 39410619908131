.product-toggle {
    .toggle-select-button {
        &.active {
            background-color: $brightTurquoise;

            &.import {
                background-color: $darkSkyBlue;
            }
            &.export {
                background-color: $algaeGreen;
            }
        }
    }
}
