svg.radar:not(:root) {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: visible;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 5px;
    width: 100%;

    @media (min-width: $screen-sm) {
        padding: 0;
        top: 0;
    }
}

.radar-chart {
    height: 284px;
    position: relative;

    @media (min-width: $screen-8plus) {
        height: 310px;
    }

    @media (min-width: $screen-sm) {
        height: 350px;
    }

    @media #{$iphoneXHorizontal} {
        height: 170px;
    }

    @media #{$ipadHorizontal} {
        height: 160px;
    }

    @media #{$tablet-large} {
        height: 410px;
    }

    @media (min-width: $screen-lg) {
        height: 410px;
    }

    @media (min-width: $screen-fm) {
        height: 540px;
    }
}

.radar-chart.small-graph {
    height: 235px;

    @media (min-width: $screen-sm) {
        height: 212px;
    }

    @media (min-width: $screen-lg) {
        height: 258px;
    }

    @media (min-width: $screen-fm) {
        height: 330px;
    }

    @media (min-width: $screen-1680) {
        svg.radar {
            padding: 32px 0;
        }
    }

    .axis {
        .legend {
            font-size: 36px;
            line-height: 2.14;

            @media (min-width: $screen-sm) {
                font-size: 32px;
            }

            @media (min-width: $screen-lg) {
                font-size: 28px;
            }

            @media (min-width: $screen-fm) {
                font-size: 22px;
            }
        }
    }
}

.radar {
    display: block;

    .radar-chart {
        .focused {
            fill-opacity: 0.6;
            stroke-width: 4;
        }
    }
    .area {
        fill-opacity: 0.1;
        stroke-width: 2;
        transition: fill-opacity 0.3s, stroke-width 0.5s;
        &.blur {
            fill-opacity: 0.05;
            stroke-width: 1;
        }
    }

    @include radarColors('.color-', $categories-colors);

    .edge-line {
        fill-opacity: 0;
        opacity: 0;
        stroke: $osloGray;
        stroke-width: 6;
    }

    .level {
        stroke: $osloGray;
        stroke-dasharray: 5, 5;
        stroke-width: 1;
    }
    .axis {
        line {
            display: none;
        }

        .legend {
            fill: $osloGray;
            font-family: 'DIN Next LT Arabic';
            font-size: 28px;
            line-height: 1.36;
            text-transform: uppercase;

            @media (min-width: $screen-sm) {
                font-size: 23px;
                line-height: 1.25;
            }

            @media (min-width: $screen-lg) {
                font-size: 17px;
                line-height: 1.29;
            }

            @media (min-width: $screen-fm) {
                font-size: 15px;
            }

            @media (min-width: $screen-hd) {
                font-size: 17px;
                line-height: 1.28;
            }

            &.top {
                dy: 1em;
            }

            &.left,
            &.right {
                text-align: left;
                text-anchor: start;
            }

            &.middle {
                text-anchor: middle;
            }
        }
    }

    .tooltip {
        font-family: 'DIN Next LT Arabic';
        font-size: 13px;
        opacity: 0;
        transition: opacity 200ms;

        &.visible {
            opacity: 1;
        }
    }
}

.chart-container.sector-level-2 .radar {
    @include radarColors('.color-', $radar-chart-subsector-group);
}

//custom styles for radar graph on tiles
.chart-and-legend-container {
    svg.radar {
        padding-top: 25px;

        .area {
            stroke-width: 3;
        }
        .axis {
            .legend {
                fill: $regentGray2;
            }
        }
    }

    .color-group-1 {
        @include radarColors('.radar-chart-serie', $radar-chart-homepage-colors-group-1);
    }
    .color-group-2 {
        @include radarColors('.radar-chart-serie', $radar-chart-homepage-colors-group-2);
    }
    .color-group-3 {
        @include radarColors('.radar-chart-serie', $radar-chart-homepage-colors-group-3);
    }
}

._chart_mg_add[data-is-trade-report='1'] {
    .chart-column,
    .mobile-column {
        .radar-chart.chart-container .radar {
            @include radarColors('.color-', $trade-report-radar-chart-colors, true);
        }
    }
}
