section.sector-summary {
    padding: 36px 0 64px;
    position: relative;

    @media #{$desktop-sm} {
        background: transparent;
        padding: 36px 10% 40px;
    }

    @media (min-width: $screen-fm) {
        padding: 40px 10% 120px;
    }

    @media (min-width: $screen-fhd) {
        padding: 52px $multichart-horizontal-margin-lg 100px;
    }

    &::before {
        display: block;
    }

    .position-container {
        display: block;
        opacity: 0;
        z-index: 1;

        &.visible {
            opacity: 1;
        }

        @media #{$desktop-sm} {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: center;
            justify-content: center;
            margin: auto;
            opacity: 1;
            position: relative;
        }

        .owl-item {
            float: left;
            opacity: 0.5;

            @include forAllTransition(0.2s);

            &.current {
                opacity: 1;
            }
        }

        .owl-nav {
            display: none;
        }

        .owl-dots {
            bottom: -50px;
            position: absolute;
            width: 100%;

            .owl-dot {
                background-color: $mineShaft2;
                border-radius: 50%;
                display: inline-block;
                height: 8px;
                margin-left: 10px;
                width: 8px;

                &:last-child {
                    margin: 0;
                }

                &.active {
                    background-color: $elm2;
                }
            }
        }

        figure {
            @for $i from 1 to 3 {
                &:nth-of-type(#{$i}) {
                    -webkit-order: $i;
                    order: $i;
                }
            }

            @media #{$desktop-sm} {
                -webkit-order: 1 !important;
                order: 1 !important;
            }

            img {
                display: inline;
                height: 55px;
                width: auto;

                &.flag {
                    height: 45px;
                    margin-top: 9px;
                }
            }

            figcaption {
                color: $white;
                font-size: 18px;
                line-height: 24px;
                margin-top: 17px;

                @media #{$desktop-sm} {
                    background: transparent;
                    font-size: 24px;
                    margin-top: 24px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 36px;
                    line-height: 36px;
                    margin-top: 40px;
                }
            }
        }

        p {
            color: $ghost;
            font-family: 'DIN Next LT Arabic Light', sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            margin-bottom: 0;
            margin-top: 8px;
            padding: 0 20px;

            @for $i from 1 to 3 {
                &:nth-of-type(#{$i}) {
                    -webkit-order: $i;
                    order: $i;
                }
            }

            @media #{$desktop-sm} {
                margin-bottom: 0;
                -webkit-order: 2 !important;
                order: 2 !important;
                width: 50%;
            }

            @media (min-width: $screen-fm) {
                font-size: 18px;
                line-height: 27px;
                margin-top: 22px;
            }
        }

        .position-cell {
            width: 100%;

            @media #{$desktop-sm} {
                max-width: none;
                width: 25%;
            }
        }
    }
}
