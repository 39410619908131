.source {
    color: $osloGray;
    font-size: 10px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;

    @media #{$desktop-sm} {
        font-size: 12px;
        margin-top: 0;
    }

    @media (min-width: $screen-lg) {
        font-size: 12px;
        margin-top: 40px;
        text-align: right;
    }

    a {
        color: $osloGray;
    }
}
