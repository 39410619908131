$legendMarkerMargin: 20px;

.chart-legend {
    // line legend with square list decoration - in used with line charts
    &.line-legend {
        direction: ltr;
        height: 22px;
        padding: 20px 0;

        .item-container,
        .item-name,
        .item-rect {
            display: inline-block;
        }

        .item-container {
            margin: 0 auto;
            padding-right: 30px;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .item-name {
            color: $paleSky;
            font-size: 12px;
            text-transform: capitalize;
            vertical-align: top;
        }

        .item-rect {
            border-radius: 3px;
            height: 15px;
            margin-right: 10px;
            width: 15px;
        }
    }

    &.legend-center {
        float: none;
    }

    &.legend-left {
        float: left;
        margin: 0 0 30px 10px;
    }

    &.legend-right {
        float: right;
        margin: 0 20px 30px 0;
    }
}

.sector-multichart,
.sector-multichart-narrow {
    .chart-legend {
        &.legend-left,
        &.legend-right,
        &.legend-second,
        &.legend-first {
            padding-top: 10px;
        }
    }
}
