.modal-category-filters {
    @include native-element-reset();
    @include categoryColors('.category-element-', $turquoise2, true);

    .category-element {
        align-items: center;
        align-self: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 8px;
        position: relative;

        .category-element-wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
        }

        .category-element-value {
            font-size: 14px;
            line-height: 20px;
            color: $osloGray;
            transition: 0.2s all ease-in-out;

            @media (min-width: $screen-fhd) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .category-element-marker {
            border: 1px solid $osloGray;
            border-radius: 100px;
            height: 10px;
            margin-left: 8px;
            width: 16px;
            transition: 0.2s all ease-in-out;
        }

        .category-element-warning {
            margin-left: 16px;
            margin-right: -40px;
            height: 20px;
        }

        .category-element-warning-wrapper {
            position: relative;

            .modal-tooltip {
                right: -64px;
                width: 224px;
                margin-top: 12px;

                &::after {
                    right: 28px;
                }
            }

            &:hover {
                .modal-tooltip {
                    display: block;
                }
            }
        }

        .category-element-tooltip {
            position: absolute;
            left: 40px;
            background-color: $cabaret;
            padding: 8px 12px;
            margin-top: 8px;
            border-radius: 4px;
            font-size: 14px;
            line-height: 1.43;
            color: $white;

            &::after {
                background-color: $cabaret;
                content: '';
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                height: 16px;
                left: 32px;
                width: 16px;
                top: -8px;
            }
        }

        @media (min-width: $screen-hd) {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.modal {
    &__download-icon {
        width: 8px;
        height: 10px;
        margin-left: 8px;

        @media (min-width: $screen-hd) {
            width: 12px;
            height: 14px;
        }
    }

    &__format-container {
        margin-top: 20px;

        @media (min-width: $screen-hd) {
            margin-top: 32px;
        }
    }

    &--whole-report {
        padding: 40px 68px;
        width: 596px;
        min-height: 280px;

        .modal__header {
            margin-bottom: 20px;
        }

        .modal__description {
            margin-top: 0;
            margin-bottom: 28px;

            &--intro {
                margin-bottom: 48px;
            }
        }

        @media (min-width: $screen-fhd) {
            padding: 48px 76px;
            width: 736px;
            min-height: 350px;

            .modal__description {
                margin-top: 0;
                margin-bottom: 40px;

                &--intro {
                    margin-bottom: 56px;
                }
            }

            .modal__header {
                margin-bottom: 32px;
            }
        }

        @media (max-width: $screen-sm) {
            width: 100%;
            height: 100%;
            top: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .modal__header {
                margin-top: 15px;
                margin-bottom: 20px;
            }

            .modal-category-filters {
                margin-right: 37px;
            }

            .modal__actions--pickers {
                display: flex;
                flex-wrap: nowrap;
                justify-content: stretch;
                margin-top: 20px;
                gap: 10px;
                flex: 1 1 0;
            }

            .modal__actions--pickers .modal__button {
                margin-left: 0;
                width: 100%;
            }

            .modal__button .modal__button--csv {
                width: 100%;
                margin-left: 0;
            }

            .modal__button--tooltip {
                width: 100%;
                margin-left: 0;
            }

            .modal__button--emphasized {
                width: 100%;
            }

            .modal__actions {
                margin-top: 0;
            }
        }

        @media (max-width: $screen-xxs) {
            .modal__actions--pickers {
                justify-content: space-between;
                margin-bottom: 20px;
            }
            .modal__button--tooltip {
                width: 108px !important;
            }

            .modal__button--csv {
                width: 108px !important;
            }

            .modal__format-container {
                margin-top: 0;
            }

            .modal__header-top {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        @media (max-width: $screen-iphone) {
            .modal__actions--pickers {
                justify-content: space-between;
                margin-bottom: 20px;
            }
            .modal__button--tooltip {
                width: 96px !important;
            }

            .modal__button--csv {
                width: 96px !important;
            }
        }
    }

    &--picking {
        .modal__header {
            margin-bottom: 16px;
            text-align: right;

            @media (min-width: $screen-fhd) {
                margin-bottom: 24px;
            }
        }
    }

    &__actions {
        &--pickers {
            justify-content: flex-start;
            margin-bottom: 48px;

            button {
                border: solid 1px $white;
                color: $white;
            }

            .modal__button--tooltip {
                position: relative;

                .modal-tooltip {
                    width: 300px;
                    left: 64px;
                    margin-top: 16px;
                }

                &:hover {
                    .modal-tooltip {
                        display: block;
                    }
                }
            }

            .modal__button {
                margin-left: 20px;

                .modal__icon {
                    display: none;

                    &--inactive {
                        display: block;
                    }
                }

                &--active {
                    color: $brightTurquoise;
                    border-color: $brightTurquoise;

                    .modal__icon {
                        display: block;

                        &--inactive {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    margin-left: 0;
                }
            }

            @media (min-width: $screen-fhd) {
                margin-bottom: 56px;
            }
        }

        &--progress {
            @include flex-center;
            display: none;
        }
    }
}

.modal-tooltip {
    position: absolute;
    background-color: $cabaret;
    padding: 8px 12px;
    margin-top: 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.43;
    color: $white;
    z-index: 100;
    display: none;

    &::after {
        background-color: $cabaret;
        content: '';
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        height: 16px;
        left: 32px;
        width: 16px;
        top: -8px;
    }
}

.side-menu.report-download-side-menu {
    .rectangle-menu .toggle-menu-btn .tray-open {
        width: 14px;
        height: 14px;
    }

    .rectangle-container {
        padding: 14px 25px 25px 25px;
    }

    button {
        font-size: 14px;
        border: solid 1px $white;
        color: $white;
        border-radius: 5px;
        height: 40px;
        width: 208px;
        margin-top: 10px;
    }

    .side-menu {
        &__title {
            width: 197px;
            height: 63px;
            font-size: 20px;
            line-height: 1.4;
            text-align: center;
            color: $white;
            margin: 65px 0 17px;
        }

        &__file-type-info {
            width: 216px;
            margin: 17px 0 31px;
            text-align: center;
            font-size: 14px;
            line-height: 1.71;
            color: $osloGray;
        }

        &__settings {
            width: 216px;
            text-align: center;
            margin: 31px 0 65px;
            font-size: 14px;
            line-height: 1.71;
            color: $white;
        }

        &__button--continue-download {
            background-color: $brightTurquoise;
            color: $mirage;
            border: solid 1px $turquoise2;
        }

        &__button--return {
            margin: 20px 0 90px;
            background-color: $mirage;
            color: $brightTurquoise;
            border: solid 1px $turquoise2;
        }
    }

    .side-menu-description,
    .side-menu-message {
        font-size: 14px;
        line-height: 20px;
    }

    .side-menu-description {
        color: $osloGray;
        margin-bottom: 24px;
    }

    .side-menu-message {
        color: $white;
    }

    @media (min-width: $screen-lg) {
        display: none;
    }
}

svg.progress-ring {
    width: 104px;
    height: 104px;
    overflow: visible;

    @media (min-width: $screen-fhd) {
        width: 120px;
        height: 120px;
    }
}

.progress-ring {
    &__wrapper {
        @include flex-center;
        position: relative;
    }

    &__information {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        display: flex;
        align-items: flex-end;
    }

    &__value,
    &__unit {
        color: $brightTurquoise;
    }

    &__unit {
        font-size: 14px;
        line-height: 17px;
    }

    &__value {
        font-size: 24px;
        line-height: 24px;
    }

    &__circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke-linecap: round;
        stroke-width: 8;
        fill: none;
        stroke: $brightTurquoise;

        &--lg {
            display: none;
        }

        &--shadow {
            stroke: $darkTwo;
            fill: rgba(26, 32, 39, 0.7);
        }
    }
}

@media (min-width: $screen-fhd) {
    .progress-ring__unit {
        font-size: 18px;
        line-height: 21px;
    }

    .progress-ring__value {
        font-size: 32px;
        line-height: 32px;
    }

    .progress-ring__circle {
        display: none;

        &--lg {
            display: block;
        }
    }
}
