.errorContainer {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;

    .zaddLogo {
        height: 40px;
        margin-bottom: 28px;

        @media (min-width: $screen-sm) {
            height: 60px;
            margin-bottom: 32px;
        }

        @media (min-width: $screen-1920) {
            height: 80px;
            margin-bottom: 48px;
        }
    }

    .errorIcon,
    .maintenanceIcon {
        height: 144px;

        @media (min-width: $screen-sm) {
            height: 164px;
        }

        @media #{$tablet-large} {
            height: 288px;
        }

        @media (min-width: $screen-1920) {
            height: 288px;
        }
    }

    .maintenanceIcon {
        margin-bottom: 28px;

        @media (min-width: $screen-sm) {
            margin-bottom: 32px;
        }

        @media #{$tablet-large} {
            margin-bottom: 48px;
        }

        @media (min-width: $screen-1920) {
            margin-bottom: 48px;
        }
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 12px;
        margin-top: 0;
        max-width: 315px;

        @media (min-width: $screen-sm) {
            font-size: 28px;
            margin-bottom: 12px;
            max-width: 532px;
        }

        @media (min-width: $screen-1920) {
            max-width: 660px;
            font-size: 36px;
            margin-bottom: 16px;
        }
    }

    .description {
        font-size: 16px;
        margin-bottom: 20px;
        max-width: 315px;
        line-height: 1.5;

        &.top {
            margin-bottom: 4px;
        }

        @media #{$tablet-large} {
            &.top {
                margin-bottom: 4 8px;
            }
        }

        @media (min-width: $screen-sm) {
            font-size: 20px;
            max-width: 532px;
            margin-bottom: 28px;

            &.top {
                margin-bottom: 4px;
            }
        }

        @media (min-width: $screen-1920) {
            font-size: 24px;
            max-width: 660px;
            margin-bottom: 32px;

            &.top {
                margin-bottom: 8px;
            }
        }
    }

    .error-button {
        background-color: $turquoise2;
        color: $mirage;
        width: 228px;
        height: 44px;
        padding: 12px 24px 16px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 28px;
        border-radius: 22px;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);

        @media (min-width: $screen-sm) {
            margin-bottom: 32px;
        }

        @media (min-width: $screen-1920) {
            margin-bottom: 48px;
        }
    }

    .callToAction {
        color: $turquoise2;

        font-size: 16px;
        margin-bottom: 28px;

        @media (min-width: $screen-sm) {
            font-size: 20px;
            margin-bottom: 32px;
        }

        @media (min-width: $screen-1920) {
            font-size: 24px;
            margin-bottom: 48px;
        }
    }

    .ninetenthsLogo {
        width: 80px;

        @media (min-width: $screen-sm) {
            width: 100px;
        }
    }
}
