main {
    min-height: 498px;

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        .basic-column {
            padding-left: 65px;
            padding-right: 65px;
            padding-top: 96px;

            .title-sector {
                color: $white;
                font-size: 48px;
            }

            .intro-sector {
                font-size: 16px;
                margin-top: 25px;
                max-width: 878px;
            }

            .chart-content {
                margin-top: 50px;
                min-height: 300px;
                position: relative;

                .additional-content {
                    margin: 0 14px 0 14px;
                    vertical-align: middle;
                    visibility: hidden;

                    .btn {
                        background-color: $mako;
                        border: none;
                        border-right: 2px solid $outerSpace;
                        color: $white;
                        font-size: 12px;
                        margin-top: 46px;
                        padding: 4px 42px 6px;

                        .triangle {
                            float: right;
                            margin-left: 10px;
                            margin-top: 3px;

                            &::before {
                                font-size: 10px;
                            }
                        }
                    }

                    .small-margin-top {
                        margin-top: 10px;
                    }

                    p.title {
                        color: $white;
                        font-size: 16px;
                    }

                    &.active {
                        display: inline-block;
                        visibility: visible;
                    }

                    &.switcher {
                        clear: both;
                        text-align: center;
                        visibility: hidden;

                        &.active {
                            display: block;
                            visibility: visible;
                        }

                        &:not(.active) ~ .viz .d3plus_rect {
                            cursor: pointer;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }

                .legend-data {
                    font-size: 11px;
                    margin-bottom: 20px;
                    margin-top: 25px;
                }
            }
        }

        .extended-column {
            padding-left: 65px;
            padding-top: 104px;

            .col-up,
            .col-down {
                position: relative;
                text-align: center;

                .graph {
                    min-height: 196px;

                    .title {
                        font-size: 12px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 140px;
                    }

                    .number {
                        color: $white;
                        font-size: 48px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 160px;
                    }

                    &::before {
                        color: $sharkLight;
                        content: '\e906';
                        font-family: 'icons';
                        font-size: 175px;
                    }
                }

                .description {
                    font-size: 18px;
                    margin-bottom: 130px;
                    margin-top: -10px;
                }
            }

            .col-up {
                padding-top: 24px;

                &::after {
                    background-color: $brightGray;
                    bottom: 0;
                    content: '';
                    height: 1px;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    width: 45%;
                }
            }

            .col-down {
                padding-top: 24px;
            }
        }

        &.scope {
            background: linear-gradient(to bottom, rgba(21, 25, 31, 1) 0%, rgba(27, 32, 39, 1) 100%);

            .basic-column {
                border-left: 1px solid $tuna;
            }
        }

        &.trends {
            background-color: $mirage;

            .basic-column {
                background: linear-gradient(to bottom, $bunkerDark 0%, $mirage 100%);
                border-left: none;
                margin-top: 82px;
                padding-bottom: 150px;
                -webkit-transform: skew(0deg, -5deg);
                transform: skew(0deg, -5deg);

                .content {
                    margin-top: -82px;
                    -webkit-transform: skew(0deg, 5deg);
                    transform: skew(0deg, 5deg);
                }

                .additional-chart {
                    float: none;

                    .content-mobile {
                        margin-bottom: -20px;
                        margin-top: 40px;

                        .trend-graph .viz {
                            margin-right: 0;

                            #container {
                                -webkit-transform: translateX(-20px);
                                transform: translateX(-20px);
                            }
                        }
                    }
                }
            }

            .extended-column {
                background: linear-gradient(to bottom, $bunkerDark 15%, $mirage 100%);
                margin-top: 100px;
                min-height: 1000px;
                -webkit-transform: skew(0deg, 10deg);
                transform: skew(0deg, 10deg);

                .content {
                    margin-top: 36px;
                    -webkit-transform: skew(0deg, -10deg);
                    transform: skew(0deg, -10deg);

                    .col-up {
                        margin-top: 100px;
                    }
                }

                &::before {
                    background-color: $tuna;
                    content: '';
                    height: 140px;
                    position: absolute;
                    right: -1px;
                    top: -142px;
                    width: 1px;
                }

                .intro {
                    color: $paleSky;
                    margin-bottom: 26px;
                }

                .trend-graph {
                    margin-top: 50px;
                    position: relative;

                    .title {
                        color: $white;
                        font-size: 14px;
                        margin-right: 21px;
                    }
                }
            }
        }

        &.landscape,
        &.landscape-multi {
            background: linear-gradient(to bottom, $bunkerDark 5%, $mirage 20%);

            .basic-column {
                border-left: none;
                float: none;
                margin: 0 auto;
                padding-bottom: 98px;
                text-align: center;

                .intro-sector {
                    margin: 25px auto 10px;
                }

                .viz {
                    margin-right: -2px;
                }
            }
        }

        &.dataset {
            background: linear-gradient(to bottom, rgba(21, 25, 31, 1) 0%, rgba(27, 32, 39, 1) 100%);

            .basic-column {
                border-left: none;
                padding-bottom: 98px;
                text-align: center;

                .intro-sector {
                    margin: 25px auto 10px;
                }

                .blocks {
                    float: none;
                    margin: 80px auto 0;
                    overflow: hidden;

                    .block {
                        .thumbnail {
                            -webkit-align-items: center;
                            align-items: center;
                            background-color: $mako;
                            border: none;
                            border-bottom: 2px solid $brightTurquoise;
                            border-radius: 5px;
                            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.44);
                            color: $white;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-justify-content: center;
                            justify-content: center;
                            line-height: 26px;
                            min-height: 72px;
                            text-align: center;

                            &:hover {
                                background-color: #535c65;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

html[dir='ltr'] {
    .container-fluid {
        .basic-column {
            border-left: none;
            border-right: 1px solid $tuna;

            &::before {
                left: 556px;
                right: inherit;
                -webkit-transform: scale(-1, 1);
                transform: scale(-1, 1);
            }
        }

        .extended-column {
            padding-right: 65px;
        }

        &.home {
            &::after {
                left: inherit;
                right: 0;
            }
        }

        &.trends {
            .basic-column {
                &::after {
                    left: inherit;
                    right: -1px;
                }
            }

            .extended-column {
                margin-top: 66px;

                &::before {
                    left: -1px;
                    right: inherit;
                }

                .trend-graph {
                    &::before {
                        left: 61px;
                    }

                    .title {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    .viz {
                        margin-left: -42px;
                    }
                }
            }
        }

        &.landscape,
        &.dataset {
            .basic-column {
                border-right: none;
            }
        }
    }
}

@media (max-width: 1024px) {
    main {
        .container-fluid {
            .basic-column {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

@media (max-width: $screen-sm-min) {
    main {
        .container-fluid {
            .basic-column {
                border-left: none;
                padding-top: 68px;

                .title-sector {
                    font-size: 18px;
                }
            }

            &.scope {
                margin-top: 8px;
                overflow: hidden;

                &::before {
                    background: #15191f;
                    content: '';
                    height: 50px;
                    left: 0;
                    position: absolute;
                    top: -8px;
                    width: 100%;
                }

                .basic-column {
                    border-left: none;
                }
            }

            &.landscape {
                margin-bottom: 0;
            }

            &.scope,
            &.trends,
            &.landscape,
            &.dataset {
                background: #1b2027;

                .basic-column {
                    background: linear-gradient(to bottom, #15191f 0%, #1b2027 100%);
                    margin-top: 17px;
                    padding-bottom: 30px;
                    -webkit-transform: skew(0deg, 8deg);
                    transform: skew(0deg, 8deg);

                    .content {
                        margin-top: -17px;
                        -webkit-transform: skew(0deg, -8deg);
                        transform: skew(0deg, -8deg);

                        .viz {
                            min-height: auto;
                        }
                    }
                }
            }
        }
    }

    html[dir='ltr'] {
        main {
            .container-fluid {
                .basic-column {
                    border-right: none;
                }

                &.scope,
                &.trends,
                &.landscape,
                &.dataset {
                    .basic-column {
                        -webkit-transform: skew(0deg, -8deg);
                        transform: skew(0deg, -8deg);

                        .content {
                            -webkit-transform: skew(0deg, 8deg);
                            transform: skew(0deg, 8deg);
                        }
                    }
                }
            }
        }
    }
}
