html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html[dir='rtl'] {
    direction: rtl;
    height: 100%;

    [class^='col-xs-'],
    [class^='col-sm-'],
    [class^='col-md-'],
    [class^='col-lg-'] {
        float: right;
    }
}

body {
    background-color: $mirage;
    color: $regentGray;
    font-family: 'DIN Next LT Arabic' !important;
    font-size: 16px;
    height: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
    }

    a {
        color: $niagara;
    }

    p:empty {
        margin: 0;
    }

    button {
        &:focus,
        &:active {
            outline: 0 !important;
        }
    }

    .page {
        overflow: visible;
        -webkit-transition: transform 0.3s;
        transition: transform 0.3s;
        width: 100%;
    }

    .additional-content-mobile {
        opacity: 0;
        position: absolute;
        -webkit-transition: opacity 1s;
        transition: opacity 1s;

        .logo-mobile {
            float: left;
        }

        .alpha {
            color: $paleSky;
            float: left;
            margin-left: 24px;
            margin-top: 7px;
            width: 34px;

            p {
                font-size: 8px;
                margin-top: 8px;
            }
        }
    }

    &.show-menu-mobile {
        .additional-content-mobile {
            left: 0;
            opacity: 1;
            padding-left: 32px;
            padding-top: 26px;
            position: fixed;
            text-align: left;
            top: 0;
            -webkit-transition: opacity 1s;
            transition: opacity 1s;
            width: 100%;

            .navbar-nav {
                &.menu {
                    border-bottom: 1px solid $brightGray;
                    margin-bottom: 46px;
                    margin-top: 40px;
                    padding-bottom: 20px;

                    li {
                        a {
                            color: $paleSky;
                            font-size: 30px;
                            line-height: 42px;

                            &:hover,
                            &:focus {
                                background: none;
                                color: $white;
                            }
                        }

                        &.active {
                            color: $white;

                            a {
                                background: none;
                                color: $white;
                            }
                        }

                        .dropdown-menu {
                            float: left;
                            padding-left: 30px;
                            position: relative;
                            width: 246px;

                            li {
                                float: left;

                                a {
                                    color: $paleSky;
                                    font-size: 21px;
                                    line-height: 24px;
                                    margin-bottom: 18px;
                                    white-space: normal;
                                }

                                &.active-submenu {
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                &.language {
                    li {
                        float: left;

                        &.active {
                            a {
                                color: $white;
                            }

                            &:hover {
                                a {
                                    color: $white;
                                }
                            }
                        }

                        &:hover {
                            a {
                                color: $white;
                            }
                        }

                        a {
                            color: $paleSky;
                            font-size: 21px;
                            line-height: 24px;
                            padding: 10px 0;
                            padding-left: 12px;
                            padding-right: 12px;
                            text-transform: capitalize;

                            &:hover,
                            &:focus {
                                background: none;
                                color: $white;
                            }
                        }

                        &:first-child {
                            position: relative;

                            &::after {
                                background-color: $paleSky;
                                content: '';
                                display: block;
                                height: 19px;
                                position: absolute;
                                right: 0;
                                top: 15px;
                                width: 2px;
                            }
                        }
                    }
                }
            }
        }

        .page {
            box-shadow: 12px 15px 30px 43px rgba(0, 0, 0, 0.35);
            -webkit-transform: translate3d(267px, 42px, 0);
            transform: translate3d(267px, 42px, 0);
        }
    }
}

html[dir='ltr'] {
    body {
        &.show-menu-mobile {
            .additional-content-mobile {
                left: inherit;
                padding-left: 0;
                padding-right: 32px;
                right: 0;
                text-align: right;

                .logo-mobile {
                    float: right;
                }

                .alpha {
                    float: right;
                    margin-left: 0;
                    margin-right: 24px;
                }

                .navbar-nav {
                    &.menu {
                        li {
                            &.active {
                                .dropdown-menu {
                                    float: right;
                                    padding-left: 0;
                                    padding-right: 14px;

                                    li {
                                        float: right;

                                        a {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.language {
                        li {
                            float: right;

                            &:first-child {
                                &::after {
                                    left: 0;
                                    right: inherit;
                                }
                            }
                        }
                    }
                }
            }

            .page {
                -webkit-transform: translate3d(-267px, 42px, 0);
                transform: translate3d(-267px, 42px, 0);
            }
        }
    }
}

section {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

    &:last-child {
        margin-bottom: 70px;

        @media (max-width: $screen-sm) {
            margin-bottom: 0;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 20px 2% 18%;
    }
}
