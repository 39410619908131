.select-data {
    display: none;

    @media #{$desktop-sm} {
        background: $bunkerDark4;
        border-radius: 4px;
        display: inline-block;
    }

    &.mobile {
        border: none;
        display: block;
        position: relative;

        @media #{$desktop-sm} {
            display: none;
        }

        select {
            background-color: $bunkerDark4;
            border: 1px solid $makoDark;
            box-shadow: none;
            color: $iron;
            font-size: 12px;
            height: 32px;
            padding: 5px 13px 10px;
        }
    }

    .btn,
    .btn:active {
        @media #{$desktop-sm} {
            background-color: transparent;
            border: 1px solid $mako;
            color: $regentGray2;
            font-size: 14px;
            height: 30px;
            line-height: 14px;
            padding: 5px 26px;
        }
    }

    .btn-active {
        border-bottom: 1px solid $brightTurquoise;
        color: $brightTurquoise;
    }

    &[dir='rtl'] {
        .btn {
            float: right;
            margin-left: -1px;

            &:first-child:not(:last-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 0;
                border-top-right-radius: 4px;
            }

            &:last-child:not(:first-child) {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 0;
                border-top-left-radius: 4px;
                border-top-right-radius: 0;
            }
        }
    }
}
