.pagination {
    .categories-pagination-container {
        direction: ltr;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        padding: 0;

        .pagination-arrow,
        .pagination-element,
        .pagination-ellipsis {
            display: inline-block;
            height: 50px;
            line-height: 50px;
            margin: 0 4px;
            text-align: center;
            width: 50px;
        }

        .pagination-arrow,
        .pagination-element {
            border: solid 1px $brightTurquoise;
            border-radius: 4px;
        }

        .pagination-ellipsis {
            color: $white;
        }

        .pagination-element {
            &.active {
                border: solid 1px $dark;
            }

            .pagination-link {
                color: $white;
                display: block;
                text-decoration: none;
                width: 50px;
            }
        }

        .pagination-arrow {
            color: $brightTurquoise;
            display: block;
            line-height: 50px;

            &::before {
                font-size: 25px;
                line-height: 1;
            }

            &.previous,
            &.next {
                &::before {
                    display: inline-block;
                    width: 50px;
                }
            }

            &.previous,
            &.next-ltr {
                &::before {
                    content: '\2192';
                }
            }

            &.next,
            &.previous-ltr {
                &::before {
                    content: '\2190';
                }
            }

            &.inactive {
                @media (min-width: $screen-lg) {
                    cursor: default;
                    opacity: 0.2;
                }
            }
        }
    }
}
