.footer {
    background: url('/frontend/img/footer-gradients.jpg') no-repeat center right;
    float: left;
    overflow: hidden;
    padding: 0 !important;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &::before {
        background: rgba(29, 32, 39, 0.7);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .footer-wrapper {
        float: none;
        margin: 0 auto;
        padding-top: 29px;

        .social {
            text-align: left;

            .icon {
                color: $mineShaftDark;
                cursor: pointer;
                display: inline-block;
                text-decoration: none;

                @media (max-width: 481px) {
                    svg {
                        height: 40px;
                        width: 40px;
                    }
                }

                .svg-inject .cls-3 {
                    fill: $abbeyDark;
                }

                .svg-inject .cls-4 {
                    fill: $mineShaftDark;
                }

                &:hover {
                    .svg-inject .cls-3 {
                        fill: $silverChalice;
                    }
                }
            }
        }

        .partners {
            margin-bottom: 20px;
            margin-top: 130px;
            text-align: center;

            .hrdf {
                margin-left: 40px;
            }

            .smea,
            .hrdf {
                img {
                    height: 54px;
                    max-width: 100%;

                    @media (max-width: 481px) {
                        height: 40px;
                    }

                    @media (max-width: 360px) {
                        height: 35px;
                    }
                }
            }
        }
    }

    .links {
        background-color: $sharkDark;
        border-top: 1px solid $emperor;
        padding: 10px 0 15px;

        .links-wrapper {
            float: none;
            margin: 0 auto;
            text-align: center;

            .link {
                color: $white;
                font-size: 16px;
                font-weight: normal;
                margin: 0 10px;
                text-decoration: none;

                @media (max-width: $screen-sm) {
                    font-size: 14px;
                    margin: 0 8px;
                    padding: 0;
                }

                &:hover {
                    color: $mantis;
                    cursor: pointer;
                }
            }
        }
    }
}

html[dir='ltr'] {
    .footer {
        .footer-wrapper {
            .social {
                float: right;
                text-align: right;
            }

            .partners {
                .hrdf {
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }

        .links {
            .link {
                text-align: left;
            }
        }
    }
}
