.license-filter {
    &.category-list-heading {
        margin-top: 32px;
        margin-bottom: 24px !important;
    }

    .categories-list {
        .category-element {
            &.active {
                .category-element-value {
                    color: $white;
                }

                .category-element-marker {
                    background: $white;
                    border-color: $white;
                }
            }
        }
    }
}
