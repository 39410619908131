.basic-column,
.graph-container {
    &-title {
        margin-bottom: 8px;
        order: 1;
        padding: 0 30px;

        @media #{$standard-mobile} {
            margin-bottom: 16px;
        }

        @media #{$desktop-sm} {
            font-size: 36px;
            padding: 0;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 5px;
            padding: 0;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 8px;
        }

        .title {
            font-size: 24px;
            line-height: 24px;
            margin: 0;
            max-width: 100%;
            padding: 0;

            @media (min-width: $screen-sm) {
                margin: inherit;
            }

            @media #{$ipad-small} {
                margin: 0;
            }

            @media #{$desktop-sm} {
                font-size: 36px;
                line-height: 53px;
            }
        }
    }

    &-description {
        margin-bottom: 24px;
        padding: 0 30px;
        order: 2;

        @media #{ipad} {
            margin-bottom: 38px;
        }

        @media #{$desktop-sm} {
            margin-bottom: 32px;
            padding: 0;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 46px;
            padding: 0;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 66px;
        }

        @media (min-width: $screen-hd) {
            margin-bottom: 68px;
        }

        .description {
            margin-bottom: 0;
            color: $osloGray;
            text-align: right;
            font-size: 14px;
            line-height: 21px;

            @media #{$tablet} {
                font-size: 16px;
            }

            @media (min-width: $screen-fm) {
                font-size: 16px;
                line-height: normal;
            }

            @media (min-width: $screen-fhd) {
                font-size: 18px;
                line-height: 27px;
            }
        }
    }

    .description {
        color: $osloGray;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        text-align: right;

        @media #{$mobileHorizontal} {
            margin-bottom: 0;
        }

        @media (min-width: $screen-sm) {
            margin: 0 0 24px;
        }
    }
}
