.summary-highlight {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

    @media #{$tablet-large} {
        flex: 0 1 50%;

        &:not(:last-of-type) {
            padding-left: 20px;
        }
    }

    @media (max-width: $screen-sm) {
        margin-bottom: 15px;
    }

    @media (min-width: $screen-lg) {
        width: 50%;
    }

    @media #{$tablet-small} {
        width: 50%;
        padding-left: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: $screen-lg) {
        width: initial;

        @media (max-height: 768px) {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .summary-highlight-header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;

        img,
        svg {
            height: 20px;
            width: auto;
            margin-left: 8px;
            align-self: center;
        }

        .summary-name {
            font-size: 20px;
            color: $brightTurquoise;
        }

        .summary-index {
            font-size: 18px;
            color: $white;
            margin-left: 8px;
        }
    }

    .summary-highlight-description {
        display: flex;
        font-size: 24px;
        color: $white;

        .value {
            margin-bottom: 9px;
        }

        .value-suffix {
            font-size: 12px;
        }

        .description {
            font-size: 14px;
            color: $osloGray;

            @media #{$tablet} {
                font-size: 16px;
            }

            @media (min-width: $screen-1680) {
                font-size: 16px;
            }

            @media (min-width: $screen-1920) {
                font-size: 18px;
            }
        }

        .summary-highlight-description-value {
            width: 170px;
        }
    }
}

.summary-highlights {
    display: flex;
    flex-direction: column;

    @media #{$tablet-large} {
        justify-content: space-between;
        flex-wrap: nowrap !important;
    }

    @media (min-width: $screen-sm) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    @media (min-width: $screen-lg) {
        flex-direction: column;
    }
}
