$white: #fff;
$whiteGrey: #f9f9f9;
$Azure: #f1f3f3;
$OuterSpace: #31373d;
$innerSpace: #2b343e;
$black: #000;
$mirage: #1a2027;
$alto: #d7d7d7;
$mako: #464e57;
$mercury: #e5e5e5;
$paleSky: #6d737b;
$paleSkyDark: #6c737b;
$regentGray: #818c9a;
$iron: #e3e4e5;
$silverChalice: #adadad;
$brightGray: #3a3f45;
$brightGray2: #37404e;
$ashGray: #31373d;
$emperor: #555;
$tundora: #444;
$fiord: #445467;
$fadedJade: #448185;
$abbey: #484d52;
$abbeyDark: #555557;
$abbeyDark2: #484c52;
$shuttleGray: #5a5e62;
$gray22: #383838;
$osloGray: #83868b;
$osloGray2: #8e9196;
$sharkLight: #1e232a;
$greyLight: #3b424c;
$sharkDark: #1b1d22;
$sharkDark4: #272b2f;
$sharkDark5: #202329;
$brightGray: #363c43;
$charade: #2a2f37;
$bunker: #161b21;
$bunker2: #14191f;
$bunkerLight: #141920;
$bunkerDark: #15191f;
$bunkerDark4: #14191e;
$bunkerDark5: #0b0f13;
$bunkerDark6: #0b0e13;
$bunkerDark7: #13171d;
$woodSmoke: #111315;
$ebonyClayLight: #2b3440;
$tuna: #353a40;
$tundora: #444444;
$tunaDark: #32373e;
$ghost: #cad1da;
$makoDark: #404851;
$mineShaft: #222;
$mineShaft2: #11151a;
$mineShaftDark: #333;
$outerSpace: #2e343c;
$outerSpace2: #2c3533;
$outerSpace3: #30363e;
$mantis: #8fbc5a;
$honeysuckle: #fafa7c;
$mosque: #004c47;
$niagara: #08a992;
$brightTurquoise: #29f6c8;
$brightTurquoiseTransparent: rgba($brightTurquoise, 0.5);
$brightTurquoise2: #43f3f8;
$brightTurquoise3: #2aefc3;
$brightTurquoise4: #00ffc2;
$brightTurquoise5: #0bf7c7;
$turquoise: #2cddb5;
$turquoise2: #00f6c9;
$viridian: #4B9584;
$strongTurquoise: #1fc6a1;
$elm: #218472;
$elm2: #17a283;
$sushi: #8dc63f;
$java: #26ceaa;
$aquamarine: #49ffd6;
$aquamarine2: #49ffd7;
$timberGreenDark: #1b2f33;
$malibu: #90a2fa;
$chenin: #e3e274;
$lavenderMagenta: #e567df;
$mischka: #ccd3dd;
$ebonyClayNew: #242b35;
$darkBlackBlue: #262b31;
$oxfordBlue: #353f4c;
$regentGray2: #919ba7;
$brightBlue: #4a90e2;
$blueBell: #948fca;
$brightPink: #ff7296;
$algaeGreen: #1dcf53;
$lemon: #f9ff52;
$paleYellow: #fffe7e;
$cloudyGray: #c4c9cf;
$slateGray: #5e646c;
$tealish: #20cea7;
$dustyGray: rgba(#979797, 0.2);
$dark: #242b35;
$darkTwo: #202934;
$darkThree: #13181f;
$ebonyClay: #262d36;
$trout: #4f5a69;
$charcoalGrey: #37414c;
$purply: #6d52ff;
$avocadoGreen: #b2e03d;
$treeGreen: #00ff16;
$darkSkyBlue: #00b6ff;
$denim: #0060ff;
$cornflowerBlue: #191e25;
$darkMauve: #ff005b;
$dirtyBlue: #948fca;
$darkBlueGrey: #00ffee;
$brownishOrange: #f88f1c;
$warmPurple: #e64770;
$cabaret: #d34469;
$pinky: #e86bde;
$mintGreen: #8af994;
$zest: #e2841d;
$orchid: #d079c9;
$torchRed: #ff0052;
$darkPink: rgba($brightPink, 0.3);
$darkBlue: rgba($brightBlue, 0.3);
$darkGreen: rgba($algaeGreen, 0.3);
$darkYellow: rgba($lemon, 0.3);

$chart-education-colors: $algaeGreen, $lemon, $blueBell, $brightTurquoise;
$chart-education-legend-colors: $algaeGreen, $lemon, $blueBell, $brightTurquoise;
$chart-gender-colors: $brightBlue, $brightPink;
$chart-nationality-colors: $algaeGreen, $lemon;
$chart-activity-colors: $brightTurquoise;
$categories-colors: $white, $paleYellow, $algaeGreen, $denim, $darkMauve, $darkSkyBlue, $mintGreen, $purply, $pinky,
    $zest;
$categories-colors-wo-average: $paleYellow, $algaeGreen, $denim, $darkMauve, $darkSkyBlue, $mintGreen, $purply, $pinky,
    $zest;
$radar-chart-homepage-colors-group-1: $avocadoGreen, $mintGreen, $darkBlueGrey;
$radar-chart-homepage-colors-group-2: $orchid, $torchRed, $brownishOrange;
$radar-chart-homepage-colors-group-3: $lemon, $darkSkyBlue, $algaeGreen;
$line-chart-homepage-colors-group-1: $strongTurquoise;
$line-chart-homepage-colors-group-2: $darkSkyBlue $algaeGreen;
$radar-chart-subsector-group: $white, $lemon, $darkSkyBlue, $algaeGreen;
$trade-report-radar-chart-colors: $white, $darkBlueGrey, $mintGreen, $avocadoGreen, $darkSkyBlue;
$trade-report-line-chart-colors: $darkBlueGrey, $mintGreen, $avocadoGreen, $darkSkyBlue;
$product-categories-colors: $darkSkyBlue, $algaeGreen;
