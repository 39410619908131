.radar-summary,
.line-of-business-summary {
    color: $osloGray;
    font-size: 18px;
    text-align: right;

    .categories-dropdown {
        float: left;
    }

    .value,
    .description {
        @include radarColors('.color-', $categories-colors, false);
        margin-bottom: 0;
    }

    .value {
        @include radarColors('.color-', $categories-colors, false);
        color: $white;
    }

    .description {
        font-size: 14px;
        line-height: 1.25;

        @media (min-width: $screen-sm) {
            font-size: 16px;
            line-height: 1.25;
        }

        @media (min-width: $screen-lg) {
            font-size: 14px;
            line-height: 1.07;
        }

        @media (min-width: $screen-fm) {
            font-size: 16px;
            line-height: 0.94;
        }

        @media (min-width: $screen-fhd) {
            font-size: 18px;
            line-height: 0.83;
        }

        span,
        img {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            margin-left: 2px;
            max-width: 18px;
            vertical-align: sub;
            width: unset;
        }
    }

    .stats-title {
        color: $white;
        font-size: 17px;
        letter-spacing: -0.5px;
        line-height: 25px;
        margin-bottom: 28px;
        margin-top: 0;
        white-space: nowrap;

        @media #{$standard-mobile} {
            margin-bottom: 26px;
        }

        @media (min-width: $screen-sm) {
            font-size: 20px;
            letter-spacing: normal;
            margin-bottom: 26px;
            white-space: nowrap;
        }

        @media (min-width: $screen-lg) {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 16px;
            white-space: normal;
        }

        @media (min-width: $screen-1366) {
            white-space: nowrap;
        }

        @media (min-width: $screen-fm) {
            font-size: 20px;
            margin-bottom: 26px;
        }

        @media (min-width: $screen-fhd) {
            margin-bottom: 20px;
        }
    }

    .stats-main {
        margin-bottom: 21px;

        @media #{$standard-mobile} {
            margin-bottom: 24px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 38px;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 44px;
        }

        @media (min-width: $screen-fhd) {
            margin-bottom: 56px;
        }

        .value {
            @include radarColors('.category-color', $categories-colors, false);
            font-family: 'DIN Next LT Arabic Medium', sans-serif;
            font-size: 40px;
            line-height: 0.6;
            margin-bottom: 3px;

            @media (min-width: $screen-sm) {
                font-size: 48px;
                line-height: 0.5;
                margin-bottom: 13px;
            }

            @media #{$desktop-sm} {
                margin-bottom: 2px;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 3px;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 0;
            }

            @media (min-width: $screen-fhd) {
                line-height: 1;
                margin-bottom: 6px;
            }

            .value-suffix {
                font-size: 18px;
                line-height: 1.33;
            }
        }

        .description {
            font-size: 14px;
            line-height: 1.25;

            @media (min-width: $screen-sm) {
                font-size: 16px;
                line-height: 0.94;
            }

            @media (min-width: $screen-lg) {
                font-size: 14px;
                line-height: 1.07;
            }

            @media (min-width: $screen-fm) {
                font-size: 16px;
                line-height: 0.94;
            }

            @media (min-width: $screen-fhd) {
                font-size: 18px;
                line-height: 0.83;
            }
        }
    }

    .stats-content {
        align-content: start;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 34px;
        text-align: right;

        @media (min-width: $screen-sm) {
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            text-align: center;
        }

        @media (min-width: $screen-lg) {
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            text-align: right;
        }

        .stats-position {
            margin-bottom: 18px;
            text-align: right;
            width: 50%;

            @media #{$standard-mobile} {
                margin-bottom: 26px;
            }

            @media (min-width: $screen-sm) {
                margin-bottom: 16px;
                width: 25%;
            }

            @media #{$mobileHorizontal} {
                width: 25%;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 32px;
                width: 50%;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 40px;
            }

            @media (min-width: $screen-fhd) {
                margin-bottom: 44px;
            }

            .value {
                @include radarColors('.category-color', $categories-colors, false);
                font-family: 'DIN Next LT Arabic Medium', sans-serif;
                font-size: 20px;
                line-height: 1.2;
                margin-bottom: -2px;

                @media (min-width: $screen-sm) {
                    font-size: 24px;
                    line-height: 1;
                    margin-bottom: 12px;
                }

                @media #{$desktop-sm} {
                    margin-bottom: -2px;
                }

                @media (min-width: $screen-lg) {
                    font-size: 20px;
                    line-height: 1.2;
                    margin-bottom: 2px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 24px;
                    line-height: 1;
                    margin-bottom: 0;
                }

                @media (min-width: $screen-fhd) {
                    margin-bottom: 3px;
                }

                .value-suffix {
                    font-size: 12px;
                    line-height: 2;
                    margin-right: 3px;

                    @media (min-width: $screen-lg) {
                        margin-right: 1px;
                    }
                }
            }
        }
    }
}

.line-of-business-summary {
    .stats-main {
        margin-bottom: 19px;
        margin-top: 22px;

        @media #{$standard-mobile} {
            margin-bottom: 25px;
            margin-top: 18px;
        }

        @media (min-width: $screen-sm) {
            margin-top: 0;
        }

        @media #{$ipad-small} {
            margin-bottom: 40px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 19px;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 42px;
        }

        @media (min-width: $screen-fhd) {
            margin-bottom: 52px;
        }

        .value {
            @media (min-width: $screen-fm) {
                margin-bottom: 7px;
            }
        }

        .description {
            @media (min-width: $screen-fhd) {
                font-size: 18px;
                line-height: 1.07;
            }
        }
    }

    .stats-content {
        .stats-position {
            .value {
                font-size: 24px;
            }
        }
    }

    .stats-title {
        display: none;

        @media (min-width: $screen-sm) {
            display: inherit;
        }
    }

    .stats-content {
        margin-bottom: 0;
        min-height: 260px;

        @media #{$tablet} {
            margin-bottom: 12px;
            min-height: 172px;
        }

        @media (min-width: $screen-lg) {
            min-height: 210px;
        }

        @media (min-width: $screen-1366) {
            min-height: 250px;
        }

        @media (min-width: $screen-fhd) {
            min-height: 310px;
        }

        .stats-position {
            margin-bottom: 17px;
            min-height: 67px;
            width: 50%;

            @media #{$standard-mobile} {
                margin-bottom: 21px;
            }

            @media (min-width: $screen-sm) {
                margin-bottom: 16px;
                min-height: 0;
                width: 20%;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 16px;
                width: 33.333%;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 10px;
            }

            @media (min-width: $screen-fhd) {
                margin-bottom: 24px;
            }

            &.hovered {
                opacity: 0.5;
            }

            .value {
                margin-bottom: -4px;

                @media (min-width: $screen-lg) {
                    font-size: 20px;
                    line-height: 1.2;
                    margin-bottom: -4px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 24px;
                    line-height: 1;
                    margin-bottom: 0;
                }

                @media (min-width: $screen-fhd) {
                    margin-bottom: -2px;
                }

                .value-suffix {
                    font-size: 12px;
                }
            }

            .category-element {
                -webkit-align-items: baseline;
                align-items: baseline;
                -webkit-align-self: baseline;
                align-self: baseline;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
                font-size: 14px;
                -webkit-justify-content: flex-end;
                justify-content: flex-end;
                line-height: 1.43;
                position: relative;

                @media (min-width: $screen-sm) {
                    font-size: 16px;
                    line-height: 1.31;
                }

                @media (min-width: $screen-lg) {
                    font-size: 14px;
                    line-height: 1.43;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                    line-height: 1.31;
                }

                @media (min-width: $screen-fhd) {
                    font-size: 18px;
                    line-height: 1.39;
                }

                .category-element-value {
                    color: $osloGray;
                    padding-left: 20px;
                    transition: 0.2s all ease-in-out;

                    @media (min-width: $screen-lg) {
                        padding-left: 12px;
                    }
                }

                .category-element-marker {
                    border: 1px solid $osloGray;
                    border-radius: 100px;
                    height: 10px;
                    margin-left: 4px;
                    min-width: 15px;
                    transition: 0.2s all ease-in-out;
                }
            }
        }
    }
}

.radar-summary {
    .stats-main {
        @media #{$standard-mobile} {
            margin-bottom: 30px;
        }
    }
}

.summary-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0 8%;

    @media (min-width: $screen-sm) {
        height: inherit;
        padding: inherit;
    }

    .categories-dropdown {
        margin-bottom: 0;
        order: 2;
        padding: 0 3.8% 50px 3.4%;

        @media (min-width: $screen-sm) {
            margin-bottom: inherit;
            padding-bottom: inherit;
        }
    }

    .chart-stats-summary {
        height: 100%;

        @media (min-width: $screen-sm) {
            height: inherit;
        }
    }
}

.stats-summary {
    width: 100%;

    .categories-dropdown {
        @media (min-width: $screen-sm) {
            float: left;
            margin-top: 49px;
            min-width: 280px;
        }
    }
}

.categories-dropdown,
.year-dropdown {
    .btn-group.bootstrap-select {
        &.open {
            .dropdown-toggle {
                border-radius: 4px;
            }
        }
        &.btn-group .dropdown-menu {
            max-height: 187px;
            width: calc(100% + 2px);
        }
    }
}

.chart-stats-summary {
    &.sector-level-2 {
        .radar-summary {
            @media (min-width: $screen-lg) {
                min-height: 295px;
            }

            @media (min-width: $screen-1680) {
                min-height: 332px;
            }

            @media (min-width: $screen-fhd) {
                min-height: 370px;
            }

            .value,
            .description {
                @include radarColors('.color-', $radar-chart-subsector-group, false);
            }

            .stats-main {
                .value:not(.color-1):not(.color-2):not(.color-3):not(.color-4) {
                    @media (min-width: $screen-lg) {
                        line-height: 37px;
                    }

                    @media (min-width: $screen-fhd) {
                        line-height: 49px;
                    }
                }
            }

            .stats-content {
                .value:not(.color-1):not(.color-2):not(.color-3):not(.color-4) {
                    @media (min-width: $screen-lg) {
                        line-height: 28px;
                    }

                    @media (min-width: $screen-1680) {
                        line-height: 30px;
                    }
                }
            }
        }

        .line-of-business-summary .stats-content .stats-position {
            @include lineStatsColors('.color-', $radar-chart-homepage-colors-group-3);
        }

        .line-of-business-summary {
            .stats-title {
                @media (min-width: $screen-fhd) {
                    margin-bottom: 9px;
                }
            }

            .stats-main {
                margin-bottom: 30px;

                @media #{$standard-mobile} {
                    margin-bottom: 44px;
                }

                @media (min-width: $screen-sm) {
                    margin-bottom: 36px;
                }

                @media (min-width: $screen-lg) {
                    margin-bottom: 32px;
                }

                @media (min-width: $screen-1680) {
                    margin-bottom: 44px;
                }

                @media (min-width: $screen-fhd) {
                    margin-bottom: 40px;
                }
            }

            .stats-content {
                -webkit-flex-direction: row;
                flex-direction: row;

                @media (min-width: $screen-lg) {
                    -webkit-flex-direction: column;
                    flex-direction: column;
                }

                @media (min-width: $screen-fhd) {
                    min-height: 276px;
                }

                .stats-position {
                    min-height: 45px;
                    width: 100%;

                    @media #{$standard-mobile} {
                        min-height: 56px;
                    }

                    @media (min-width: $screen-sm) {
                        width: 27%;
                    }

                    @media (min-width: $screen-lg) {
                        min-height: 48px;
                        width: 100%;
                    }

                    @media (min-width: $screen-1680) {
                        min-height: 68px;
                    }

                    @media (min-width: $screen-fhd) {
                        min-height: 68px;
                    }

                    .value {
                        font-size: 20px;

                        @media (min-width: $screen-fhd) {
                            font-size: 36px;
                            margin-bottom: -5px;
                        }
                    }
                }
            }
        }
    }
}

.chart-stats-summary {
    &.sector-level-3 {
        .line-of-business-summary {
            .stats-content {
                @media (min-width: $screen-sm) {
                    min-height: 196px;
                }

                @media (min-width: $screen-lg) {
                    min-height: 272px;
                }

                @media (min-width: $screen-fm) {
                    min-height: 293px;
                }

                @media (min-width: $screen-hd) {
                    min-height: 342px;
                }
            }
        }
    }
}

._chart_mg_add[data-is-trade-report='1'] {
    .radar-summary {
        .value,
        .description {
            @include radarColors('.color-', $trade-report-radar-chart-colors, false);
        }
    }

    .line-of-business-summary .stats-content .stats-position {
        margin-bottom: 20px;
        min-height: 0;
        text-align: right;
        width: 50%;

        @media (min-width: $screen-sm) {
            margin-bottom: 16px;
            width: 25%;
        }

        @media #{$mobileHorizontal} {
            width: 25%;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 21px;
            width: 50%;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 30px;
        }

        @media (min-width: $screen-fhd) {
            margin-bottom: 40px;
        }

        @include lineStatsColors('.color-', $trade-report-line-chart-colors);
    }
}
