.overlay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 32, 39, 0.7);
    z-index: 1000;

    @include flex-center;
}

.modal {
    background-color: $mirage;
    border: solid 1px $darkTwo;
    border-radius: 5px;
    box-shadow: 0 5px 22px 0 $darkThree;
    padding: 48px 88px 64px 88px;
    position: relative;
    width: 598px;
    min-height: 312px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: visible;

    &__wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__close {
        @include native-element-reset();
        @include flex-center;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__header {
        margin: 18px 0 0;
        line-height: 1.4;
        color: $white;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: normal;
        font-size: 20px;
    }

    &__description {
        margin: 20px 0 0;
        font-size: 14px;
        text-align: center;
        color: $osloGray;

        &--intro {
            color: $white;
        }
    }

    &__button {
        @include native-element-reset();
        width: 124px;
        height: 40px;
        border: solid 1px $turquoise2;
        color: $turquoise2;
        font-size: 14px;
        line-height: 1.71;
        box-sizing: border-box;
        border-radius: 5px;
        position: relative;

        @include flex-center;

        &--emphasized {
            border: solid 1px $turquoise2;
        }

        .modal__icon {
            filter: brightness(0) invert(1);

            &--active {
                filter: invert(0);
            }
        }

        &:disabled {
            color: $osloGray;
            border-color: $osloGray;

            .modal__icon {
                filter: brightness(0) invert(0.5);
            }
        }

        &--emphasized {
            background-color: $brightTurquoise;
            color: $mirage;

            &:hover {
                background-color: $white;
                color: $mirage;

                .modal__icon {
                    filter: none;
                }
            }
        }

        &--big {
            width: 180px;
        }

        &--biggest {
            width: 200px;
        }
    }

    &__icon {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 20px;

        &--success {
            width: 46px;
            height: auto;
            align-self: center;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        position: relative;

        .modal__button--tooltip {
            position: relative;

            .modal-tooltip {
                width: 400px;
                left: 42px;
                margin-top: 16px;
            }

            &:hover {
                .modal-tooltip {
                    display: block;
                }
            }
        }

        &--loading {
            display: none;
            height: 40px;
        }

        &--success {
            justify-content: center;
        }
    }

    &__link {
        @include native-element-reset;
        color: $brightTurquoise;
        cursor: pointer;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    @media (min-width: $screen-fhd) {
        padding: 80px 132px;
        width: 810px;
        min-height: 426px;

        .modal__header {
            font-size: 24px;
            line-height: 1.33;
        }

        .modal__description {
            margin-top: 24px;
            font-size: 18px;
            line-height: 1.78;
        }

        .modal__button {
            width: 160px;
            height: 48px;
            font-size: 20px;
            line-height: 30px;

            &--big {
                width: 232px;
            }

            &--biggest {
                width: 272px;
            }
        }

        .modal__icon {
            width: 27px;
            height: 32px;
        }

        .modal__close {
            top: 24px;
            right: 24px;
        }
    }
}

.modal-trigger-container {
    @include flex-center;
    margin-top: 16px;
    display: none;

    &.highlight {
        margin-top: 24px;
        justify-content: flex-start;
    }
}

.modal-download-icon {
    margin-left: 8px;
}

.modal-trigger-button {
    @include native-element-reset();
    color: $brightTurquoise;
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: $white;

        .modal-download-icon {
            filter: brightness(0) invert(1);
        }
    }
}

@media (min-width: $screen-lg) {
    .modal-trigger-container {
        display: flex;
    }
}

@media (min-width: $screen-fhd) {
    .modal-trigger-container {
        margin-top: 24px;

        &.highlight {
            margin-top: 36px;

            .modal-download-icon {
                margin-left: 8px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .modal-download-icon {
        margin-left: 16px;
    }

    .modal-trigger-button {
        font-size: 20px;
        line-height: 1.45;
    }
}

.force-ltr {
    direction: ltr !important;
}

.modal__wrapper.modal__wrapper--error {
    .modal__icon--error {
        width: 46px;
        height: auto;
        align-self: center;
    }

    .modal__description.modal__description {
        line-height: 1.71;
    }

    .modal__actions.modal__actions {
        justify-content: center;
    }
}

.modal__button {
    &--pdf,
    &--png,
    &--csv {
        border: solid 1px $white;
        color: $white;
    }
}

@media (max-width: $screen-sm) {
    .modal__actions--error,
    .modal__actions--success {
        position: absolute;
        margin: auto;
        right: 0;
        bottom: 120px;
        left: 0;
        > .modal__button--big {
            width: calc(100% - 60px);
        }
    }
}
