.region-chart-summary {
    height: 100%;
    display: flex;

    .region-summary {
        .summary-highlight {
            .summary-highlight-header {
                .summary-name {
                    color: $white;
                }
            }
        }
    }
}
