$wideHeader: 1900px;
$headerDividerColor: #25303b;
$headerHeight: 90px;
$color-bright-turquoise: #00f6c9;
$color-viridian: #4b9584;
$color-ghost: #cad1da;
$color-dusty-grey: #999;
$color-mirage: #1a2027;
$color-white: #fff;
$new-ui-color: #192129;

$font-family-regular: 'DIN Next LT Arabic', Ubuntu, Helvetica, Calibri, sans-serif;

.HeaderNav {
    top: $headerHeight;
    left: 0;
    position: fixed;
    height: 0;
    width: 100%;
    overflow: hidden;
    padding-left: 45px;
    padding-right: 40px;
    background-image: linear-gradient(to bottom, #0b0f13, #1a2027);
    font-size: 16px;
    transition: all 500ms ease-out;

    @media only screen and (orientation: landscape) {
        overflow: scroll;
    }

    @media (min-width: $screen-sm), #{$landscape-mobile} {
        flex-direction: row;
        align-items: center;
        position: relative;
        top: inherit;
        left: inherit;
        height: inherit;
        overflow: visible;
        padding-right: 0;
        padding-left: 7px;
        background: none;
        text-align: left;
    }

    @media (min-width: 1024px) {
        padding-left: 0;
    }

    @media (min-width: 1024px) and (max-width: 1400px) {
        padding-left: 0;
    }

    &.header-menu,
    .header-submenu {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm), #{$landscape-mobile} {
            flex-direction: row;
        }

        div.header-menu-position {
            padding: 0;

            @media (min-width: $screen-sm), #{$landscape-mobile} {
                padding: initial;
            }
        }

        .header-menu-position,
        .header-submenu-position {
            text-decoration: none;
            padding: 20px 74px 20px 0;
            color: $color-bright-turquoise;
            line-height: 1.88;
            position: relative;
            text-wrap: nowrap;

            &[class*='icon-']::before {
                position: absolute;
                top: 50%;
                right: 0;
                font-size: 50px;
                transform: translateY(-50%);
                margin: 0;
                width: initial;
            }

            &.icon-homepage::before {
                font-size: 40px;
                right: -5px;
                top: 45%;
            }

            &.icon-allreports::before,
            &.icon-sectors::before,
            &.icon-locations::before,
            &.icon-area::before {
                font-size: 46px;
            }
        }

        .header-menu-position--active,
        .header-submenu-position--active {
            color: $color-viridian;
            text-decoration: underline;

            &[class*='geo-icon-']::before {
                color: $color-viridian;
                text-decoration: none;
            }

            &::after {
                display: none;
            }
        }
    }

    &.header-menu {
        a.header-toggle-link {
            display: none;
            text-decoration: none;
            color: inherit;

            @media (min-width: $screen-sm), #{$landscape-mobile} {
                display: inline-block;

                &.active {
                    color: $color-bright-turquoise;
                    text-decoration: none;
                }
            }

            img {
                filter: invert(91%) sepia(13%) saturate(161%) hue-rotate(174deg) brightness(94%) contrast(85%)
                    opacity(0.7);
                transition: all 0.5s ease;
            }
        }

        a.header-toggle-link:first-of-type {
            @media (min-width: $screen-sm), #{$landscape-mobile} {
                padding-left: 0;
            }
        }

        .header-menu-position {
            $focus-underline-color: rgba($color-bright-turquoise, 0.7);
            $focus-underline-size: 2px;
            &:last-child {
                margin-left: 0;
            }

            @media (min-width: $screen-sm), #{$landscape-mobile} {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0 21px;
                padding: 0;
                position: relative;
                color: $color-ghost;
                transition: color 0.5s ease;
                text-decoration: none;

                &--active {
                    border-bottom: $focus-underline-size solid $focus-underline-color;
                    margin-bottom: -$focus-underline-size;
                }

                &::before {
                    display: none;
                }

                &:hover {
                    cursor: pointer;
                }

                &:after {
                    bottom: 0;
                    right: 55%;
                    left: 55%;
                    content: '';
                    height: $focus-underline-size;
                    position: absolute;
                    background-color: $focus-underline-color;
                    transition: all 0.3s ease-in-out 0s;
                }

                &:hover:after {
                    left: 0;
                    right: 0;
                }

                &:hover img {
                    filter: invert(74%) sepia(91%) saturate(1774%) hue-rotate(113deg) brightness(96%) contrast(108%)
                        opacity(0.7);
                }
            }
        }
    }

    .header-submenu {
        border-top: solid 2px rgba($color-dusty-grey, 0.2);
        transition: all 0.5s ease;
        padding: 12px 0 0;
        margin-top: 20px;

        @media (min-width: $screen-sm), #{$landscape-mobile} {
            display: none;

            &.active {
                position: absolute;
                z-index: 2;
                top: 48px;
                left: -11px;
                display: flex;
                flex-direction: column;
                padding: 32px 34px 24px;
                margin-top: 0;
                background-color: $color-mirage;
                border: solid 2px $color-bright-turquoise;
                border-radius: 5px;
                box-shadow: 12px 20px 20px 0 rgba(0, 0, 0, 0.22);
                text-align: right;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    height: 0;
                    width: 0;
                    border-color: transparent transparent $color-bright-turquoise transparent;
                    border-style: solid;
                    border-width: 0 7px 10px 7px;
                }

                &::before {
                    left: 9px;
                    top: -22px;
                    border-color: transparent transparent $color-mirage transparent;
                    border-width: 0 18px 24px 18px;
                }

                &:after {
                    left: 20px;
                    top: -10px;
                }

                a.header-submenu-position {
                    min-width: 128px;
                    width: 100%;
                    padding: 2px 48px 8px 0;
                    margin-bottom: 16px;
                    color: $color-bright-turquoise;
                    position: relative;

                    span {
                        text-wrap: none;
                    }

                    &[class*='icon-']::before {
                        font-size: 50px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &.icon-homepage::before {
                        font-size: 40px;
                        right: -5px;
                    }

                    &.icon-allreports::before,
                    &.icon-products::before,
                    &.icon-sectors::before,
                    &.icon-locations::before {
                        font-size: 30px;
                    }

                    &:hover {
                        color: $color-viridian;
                        text-decoration: underline;

                        &::before {
                            color: $color-viridian;
                            text-decoration: none;
                        }
                    }

                    &::before {
                        display: flex;
                        font-size: 30px;
                        margin: 0 -2px 0 0;
                        right: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (min-width: 1024px) {
            &.active {
                .header-submenu-position {
                    a {
                        padding-bottom: 8px;
                    }
                }
            }
        }

        @media (min-width: 1366px) {
            &.active {
                left: -18px;
                padding: 32px;

                &::before {
                    left: 18px;
                }

                &:after {
                    left: 29px;
                }

                .header-submenu-position {
                    min-width: 124.72px;

                    a {
                        padding-right: 54px;
                        padding-bottom: 8px;

                        &:before {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        @media (min-width: 1680px) {
            &.active {
                left: -26px;
                padding: 41px;

                .header-submenu-position {
                    a {
                        padding-bottom: 10px;

                        &::before {
                            margin: 0;
                            right: 0;
                            display: flex;
                        }
                    }
                }
            }
        }

        @media (min-width: 1900px) {
            display: none;

            &.active {
                padding: 41px;
                left: -54px;

                &::before {
                    left: 48px;
                }

                &:after {
                    left: 59px;
                }

                .header-submenu-position {
                    a {
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }
}

.HeaderNav--active {
    height: calc(100% - $headerHeight);
    background: linear-gradient(to top, $new-ui-color 65%, #0b0e13 100%);
    padding-top: 12px;
    padding-bottom: 40px;
    overflow-y: auto;

    @media only screen and (orientation: landscape) {
        background: linear-gradient(to top, $new-ui-color 40%, #0b0e13 100%);
    }

    &::before {
        background-color: $new-ui-color;
        content: '';
        display: block;
        left: 0;
        padding-bottom: 100vw;
        position: absolute;
        top: 250px;
        transform: rotate(-90deg) skewX(18deg) translateX(0);
        transform-origin: 0 0;
        width: 100vw;
        z-index: -1;

        @media only screen and (orientation: landscape) {
            top: 88vh;
            height: 180vh;
        }
    }
}

.HeaderToggle__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 25px;

    @media (min-width: $screen-sm), #{$landscape-mobile} {
        display: none;
    }
}

.HeaderToggle,
.HeaderToggle:before,
.HeaderToggle:after {
    border: solid 1px $color-white;
    width: 21px;
}

.HeaderToggle {
    position: relative;
    transition: 0.3s all;
}

.HeaderToggle:before,
.HeaderToggle:after {
    content: '';
    left: -1px;
    position: absolute;
    transition: 0.3s all;
}

.HeaderToggle:before {
    top: -7px;
}

.HeaderToggle:after {
    top: 5px;
}

.HeaderToggle--active {
    border: none;
    content: '';
    width: 17px;

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &::before,
    &::after {
        left: -2.5px;
        top: -1px;
        width: 21px;
    }
}

.HeaderServicesMenu {
    position: absolute;
    top: calc($headerHeight - 50px);
    right: -40px;
    display: none;
    cursor: default;

    @media (min-width: 1366px) {
        right: calc(50% - 102px);
    }

    &--active {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 16px 20px;
        border-radius: 6px;
        gap: 16px;
        background-color: $color-white;
        z-index: 2;
    }

    &__head {
        align-self: flex-start;
        color: #6d6e71;
        font-family: $font-family-regular;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }
}

.HeaderServicesMenu__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.HeaderServicesMenu__service {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #f1f1f2;
    border-radius: 4px;
    overflow: hidden;

    img {
        transform: scale(1.5);
        width: 100%;
    }

    &--active {
        background-color: $color-white;
        &:nth-of-type(1) {
            border: 1px solid #7b3aaa;
        }

        &:nth-of-type(2) {
            border: 1px solid #1e60b3;
        }

        &:nth-of-type(3) {
            border: 1px solid #7ac80f;
        }

        &:nth-of-type(4) {
            border: 1px solid $color-bright-turquoise;
        }
    }
}

.Header {
    height: $headerHeight;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 110;

    &__menu {
        display: flex;
        align-items: center;
    }

    &__divider {
        background-color: $headerDividerColor;
        width: 1px;
        align-self: stretch;
    }

    &__services {
        cursor: pointer;
        user-select: none;
        margin: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 1024px) {
            margin: 17px 24px;
        }

        &.hidden {
            display: none;
        }

        &__content {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            user-select: none;
        }
    }
}

.Header__container {
    align-items: center;
    background-color: $new-ui-color;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 8px 20px;
    transition: all 0.5s ease;

    &.transparent-version {
        background-color: inherit;
        margin-top: 7px;

        @media (min-width: $screen-sm), #{$landscape-mobile} {
            margin-top: 12px;
        }

        @media (min-width: $screen-1366) {
            margin-top: 22px;
        }

        @media (min-width: $screen-fhd) {
            margin-top: 42px;
        }
    }

    @media (min-width: $screen-sm), #{$landscape-mobile} {
        padding: 8px 22px;
    }

    @media (min-width: 1366px) {
        padding: 16px 108px;
    }

    @media (min-width: 1680px) {
        padding: 16px 132px;
    }

    @media (min-width: 1900px) {
        padding: 16px 156px;
    }

    .logo {
        height: 24px;
    }
}

.Header__logo {
    margin: 0 18px;
    display: flex;
    justify-content: space-between;
    color: $color-white;
    font-size: 14px;
    line-height: 1.4;

    @media (min-width: 1024px) {
        margin: 0 12px 0 32px;
    }

    @media (min-width: 1366px) {
        margin: 0 12px 0 48px;
    }

    @media (min-width: 1680px) {
        margin: 0 12px 0 64px;
    }
}

.Header__logo-image {
    margin-left: 10px;

    @media (min-width: $screen-sm), #{$landscape-mobile} {
        margin: 0 13px;
    }

    @media (min-width: 1366px) {
        margin: 0 13px;
    }

    @media (min-width: 1680px) {
        margin: 0 13px;
    }
}

.HeaderServices {
    display: none;

    &--active {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 2px solid #9993;
    }

    &__head {
        color: #00f6c9;
        font-family: $font-family-regular;
        font-weight: 400;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
    }

    .HeaderServicesMenu__service {
        background-color: #161b22;
    }
}

.header-search-field-background {
    display: none;
    position: fixed;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#252c36, 0.4);
    z-index: 100;

    .header-search-field {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 70vh;
        background: linear-gradient(to bottom, #252c36 80%, rgba(#252c36, 0));

        div.search {
            margin: 32px auto 0;

            @media (min-width: $screen-sm) {
                margin-top: 120px;
            }
        }

        form.search {
            margin: 0 32px;
        }
    }
}

.header-menu-search,
.header-menu-search-mobile {
    display: flex;
    margin-right: auto;
    margin-left: 24px !important;

    @media (min-width: 1366px) {
        margin-left: 0;
    }

    a {
        color: $color-ghost;
        text-decoration: none;
    }
}

.no-scroll {
    overflow: hidden;
}
