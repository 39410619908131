.dropdown-component {
    display: block !important;
    margin: 0 auto !important;
    width: 280px;

    &:not(.none-update) {
        .dropdown-menu {
            li {
                .checkbox {
                    .decorator {
                        background-color: $turquoise;
                        border-radius: 50%;
                        display: block;
                        height: 7px;
                        left: 2px;
                        position: absolute;
                        top: 2px;
                        width: 7px;
                    }
                }
            }
        }

        .dropdown-toggle {
            &.bs-placeholder,
            &.btn-default[data-toggle='dropdown'] {
                border-color: $turquoise;

                span.filter-option.center {
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }
}
