$dropdown-horizontal-margin: 13px;

.btn-group.bootstrap-select {
    float: none;
    position: relative;

    &:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
        width: 100%;

        @media #{$desktop-sm} {
            margin-bottom: 20px;
            max-width: 280px;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 0;
        }

        &.mobile-switcher {
            margin-bottom: 0;
        }
    }

    & > .dropdown-toggle {
        &.bs-placeholder,
        &.btn-default {
            background-color: $bunkerDark4;
            border: 1px solid $makoDark;
            border-radius: 4px;
            color: $iron;
            font-size: 12px;
            height: 32px;
            outline: none !important;
            padding-right: 15px;

            &:focus,
            &:active {
                box-shadow: none;
                outline: none !important;
            }
        }

        .filter-option {
            text-align: right;

            .checkbox,
            .square {
                display: none;
            }
        }

        .caret {
            left: 10px;
            right: inherit;
        }
    }

    &.open {
        .dropdown-toggle {
            border-bottom: 1px solid $tunaDark;
            border-color: $bunkerDark4;
            border-radius: 4px 4px 0 0;
            box-shadow: none;
        }
    }

    &.btn-group .dropdown-menu {
        background-color: $bunkerDark4;
        border: none;
        border-radius: 0 0 4px 4px;
        font-size: 14px;
        max-height: 200px;
        padding: 0;
        width: 100%;

        @media #{$desktop-sm} {
            max-height: 372px;
        }

        &.open {
            margin: 0;
            overflow: visible;
            position: absolute;
            z-index: 993;
        }

        &.inner {
            margin-bottom: 0;
            margin-left: -20px;
            margin-right: 0 !important;
            margin-top: 0;

            @supports (overflow: -webkit-marquee) and (justify-content: inherit) {
                margin-left: 0;
                margin-right: -15px !important;
                position: relative;

                //Safari 10.1+, the double media query is needed
                @media not all and (min-resolution: 0.001dpcm) {
                    @media screen {
                        margin-right: inherit !important;
                        position: inherit;
                    }
                }
            }
        }

        li {
            border-top: 1px solid $tunaDark;

            &:first-child {
                border-top: none;
            }

            a {
                -webkit-align-items: center;
                align-items: center;
                display: -webkit-flex;
                display: flex;
                outline: none;
                padding: 0 17px 0 12px;
                text-align: right;
                white-space: inherit;

                .checkbox {
                    background: $mirage;
                    border: 1px solid $ebonyClayLight;
                    border-radius: 3px;
                    display: inline-block;
                    height: 13px;
                    margin-bottom: 0;
                    margin-top: 0;
                    max-width: 13px;
                    position: relative;
                    vertical-align: middle;
                    width: 100%;
                }

                span.text {
                    color: $shuttleGray;
                    line-height: 21px;
                    margin-right: 20px;
                    padding: 8px 0;
                }

                &:active,
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            &.selected {
                a {
                    background-color: $bunkerDark4;

                    .checkbox {
                        display: block;

                        .decorator {
                            border-radius: 50%;
                            display: block;
                            height: 7px;
                            left: 2px;
                            position: absolute;
                            top: 2px;
                            width: 7px;
                        }
                    }

                    .check-mark {
                        display: none !important;
                    }
                }
            }

            &:hover {
                a {
                    background-color: $bunkerDark4;
                }
            }
        }
    }

    &.dropdown-center {
        float: none;
    }

    &.dropdown-left {
        float: left;
        margin-left: $dropdown-horizontal-margin;
    }

    &.dropdown-right {
        float: right;
        margin-right: $dropdown-horizontal-margin;
    }

    &.none-update {
        @media #{$desktop-sm} {
            margin-left: 6px;
            margin-right: 6px;
        }

        &:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
            @media #{$desktop-sm} {
                width: 160px;
            }
        }

        .dropdown-menu li.selected {
            .text {
                color: $brightTurquoise;
            }

            .checkbox:before {
                background-color: $brightTurquoise;
                border-radius: 50%;
                content: '';
                display: block;
                height: 7px;
                left: 2px;
                position: absolute;
                top: 2px;
                width: 7px;
            }
        }
    }

    @media (max-width: $screen-sm) {
        &.dropdown-left,
        &.dropdown-right {
            float: none;
            margin: 0;
        }

        &.dropdown-chart {
            margin-bottom: 20px;
        }
    }

    .nano > .nano-pane {
        background: $woodSmoke;
        left: 6px;
        margin-bottom: 12px;
        margin-top: 12px;
        opacity: 0.9 !important;
        right: inherit;
        width: 6px;
        z-index: 9999;

        & > .nano-slider {
            background: $sharkDark4;
            border-radius: 35px;
            opacity: 0.9;
            width: 6px;
        }
    }

    &.mobile-switcher {
        .dropdown-menu {
            li {
                &.selected {
                    a {
                        .text {
                            color: $turquoise !important;
                        }
                    }
                }

                a {
                    span.text {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
