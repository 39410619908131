.categories-tiles {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 0;
    width: 100%;

    .categories-container {
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-lg) {
            display: block;
            padding-left: 7vw;
            padding-right: 7vw;
            width: auto;
        }

        @media (min-width: $screen-1680) {
            padding-left: 2vw;
            padding-right: 2vw;
        }

        .owl-stage-outer {
            overflow: visible;
        }

        .owl-dots {
            margin-top: 10px;
            text-align: center;

            @media (min-width: $screen-sm) {
                margin-top: 1.8vh;
                margin-top: calc(var(--vh, 1vh) * 1.8);
            }

            .owl-dot {
                background: $mineShaft2;
                border-radius: 50%;
                display: inline-block;
                height: 10px;
                margin-left: 10px;
                width: 10px;

                &.active {
                    background: $elm2;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }

        .categories-thumbnail-container {
            display: block;
            margin-right: 0.7vw;
            text-align: center;

            @media (min-width: $screen-sm) {
                margin-right: 0;
            }

            @media (min-width: $screen-lg) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
            }
        }
    }

    .loadmore-categories {
        margin-top: 36px;
        position: relative;
        text-align: center;
        z-index: 1;

        @media (max-width: $screen-sm) {
            margin-top: 28px;
        }

        a {
            background: transparent;
            border: 2px solid $brightTurquoise;
            border-radius: $border-radius-btn;
            color: $brightTurquoise;
            font-size: 16px;
            line-height: 14px;
            padding: 4px 38px 8px;
            text-decoration: none;

            @include forAllTransition(0.2s);

            @media (min-width: $screen-sm) {
                padding: 12px 70px;
            }

            &:hover {
                background: $brightTurquoiseTransparent;
                color: $mirage;
            }
        }
    }

    .no-search-results {
        align-items: center;
        align-self: center;
        color: #fff;
        display: none;
        flex: 1;
        flex-direction: column;
        font-size: 25px;
        justify-content: center;
        justify-self: center;

        @media (min-width: $screen-sm) {
            font-size: 35px;
            margin: 120px 0;
        }

        .icon-search {
            display: block;
            font-size: 35px;

            @media (min-width: $screen-sm) {
                display: inline;
                margin: 19px;
            }
        }

        .clear-filters {
            display: flex;
            flex-direction: column;
            align-items: center;

            .clear-filters-text {
                font-size: 16px;
                text-align: center;
                line-height: 1.5;
                color: $osloGray;
                margin-top: 16px;
                margin-bottom: 28px;
                width: 100%;

                @media (min-width: $screen-sm) {
                    width: 420px;
                }
            }

            .clear-filters-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 175px;
                height: 44px;
                border-radius: 4px;
                box-shadow: 0 1px 6px 0 #00000026;
                background-color: $brightTurquoise;

                &:hover {
                    cursor: pointer;
                }

                .clear-filters-button-text {
                    font-size: 16px;
                    text-align: center;
                    font-weight: bold;
                    color: $mirage;
                }
            }
        }
    }

    @import 'TilesPagination';
}
