.radio-select {
    &.with-border {
        border-top: 1px solid $charcoalGrey;
    }

    @include landscape-sm {
        border: none;
        float: left;
    }

    @include landscape-lg {
        border: none;
        float: left;
    }
}

.radio-select-container {
    margin-top: 30px;
    margin-bottom: 14px;

    @media (min-width: $screen-lg) {
        margin-top: 15px;
    }

    @media (min-width: $screen-fm) {
        margin-top: 18px;
    }

    @media (min-width: $screen-hd) {
        margin-top: 22px;
    }

    @include landscape-sm {
        margin-top: 0;
    }

    @include landscape-lg {
        margin-top: 0;
    }

    .radio-title {
        text-align: right;
        font-size: 20px;
        color: $white;
        margin-bottom: 20px;
    }

    .radio-select-items {
        display: flex;
        flex-direction: column;
        margin: -8px 0;
    }

    .radio-select-item {
        display: flex;
        position: relative;
        align-items: center;
        font-family: 'DIN Next LT Arabic';
        font-size: 14px;
        font-weight: 500;
        color: $osloGray;
        margin: 8px 0;
    }

    .radio-select-input {
        position: absolute;
        visibility: hidden;
    }

    .radio-select-item label {
        display: flex;
        margin: 0;
        cursor: pointer;
        font-weight: 500;
        transition: all ease-in-out 0.2s;
    }

    .radio-select-input + label:before {
        height: 20px;
        width: 20px;
        margin-left: 8px;
        content: '';
        display: block;
        border: 1px solid $osloGray;
        border-radius: 50%;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
    }

    .radio-select-input + label:after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        border: 8px solid transparent;
        border-radius: 50%;
        background-color: $osloGray;
        background-clip: padding-box;
        content: '';
        cursor: pointer;
        transition: all ease-in-out 0.2s;
    }

    .radio-select-input:hover + label:before {
        border-color: $ghost;
    }

    .radio-select-input:hover + label:after {
        background-color: $ghost;
    }

    .radio-select-input:hover + label {
        color: $ghost;
    }

    .radio-select-input:checked + label:before {
        border-color: $brightTurquoise;
        cursor: initial;
    }

    .radio-select-input:checked + label:after {
        border: 4px solid transparent;
        cursor: initial;
        background-color: $brightTurquoise;
    }

    .radio-select-input:checked + label {
        color: $brightTurquoise;
        cursor: default;
    }
}
