.map-overlay {
    position: absolute;
    font-family: 'DIN Next LT Arabic', serif;
    line-height: 24px;
    text-align: center;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;

    @media (min-width: $screen-lg) {
        max-width: 240px;
    }

    .overlay-container {
        position: relative;
        top: 28px;
        width: 220px;
        border: 1px solid $brightTurquoise;
        background-color: $mirage;
        border-radius: 4px;
        padding: 8px 20px 12px;
        margin: 0 auto;
        box-shadow: 0 1px 3px rgba($black, 0.25);

        .overlay-value {
            font-size: 14px;
            color: $white;
        }

        .overlay-x {
            position: absolute;
            top: 0;
            left: 0;
            height: 14px;
            width: 14px;
            margin: 2px;
            background: url('frontend/img/icons/allReports/filters-tray-close.svg');
            background-size: 100%;
        }
    }
}

.map-overlay--invisible {
    opacity: 0;
}
