#d3plus_tooltip_id_line {
    display: block;
    font-family: 'DIN Next LT Arabic Medium', sans-serif;
    margin-top: -30px;
    max-width: 250px;
    width: 100%;

    .d3plus_tooltip_container {
        border-radius: 4px;
        padding: 16px 20px 19px;
        width: auto !important;

        .d3plus_tooltip_main_title,
        .d3plus_tooltip_year {
            color: $mirage;
            display: block;
            font-size: 14px;
            font-weight: 300;
            text-align: center;
        }

        .d3plus_tooltip_main_title {
            line-height: 24px;
            margin: 0 0 12px 0;
        }

        .d3plus_tooltip_year {
            line-height: 12px;
        }

        .d3plus_tooltip_content {
            -webkit-align-items: center;
            align-items: center;
            direction: rtl;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            margin-bottom: 5px;
            overflow: hidden;

            .d3plus_tooltip_decorator,
            .d3plus_tooltip_title,
            .d3plus_tooltip_data_value {
                display: inline-block;
                position: relative;
            }

            .d3plus_tooltip_title,
            .d3plus_tooltip_data_value {
                color: $paleSky;
                direction: rtl;
                font-size: 14px;
            }

            .d3plus_tooltip_decorator {
                border-radius: 2px;
                height: 10px;
                margin-left: 10px;
                max-width: 10px;
                width: 100%;
            }

            .d3plus_tooltip_title {
                margin-left: 12px;
                width: 100%;
            }

            .d3plus_tooltip_data_value {
                white-space: nowrap;
            }
        }
    }
}

.d3plus_tooltip_circle:after {
    background: inherit;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    content: '';
    height: 18px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 18px;
}

.custom-tooltip {
    background: $whiteGrey;
    border-radius: 5px;
    box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.5);
    padding: 19px 20px;
    position: absolute;
    text-transform: capitalize;
    z-index: 11;

    .icon-male:before {
        background-image: url('../img/icons/demography/male.svg');
        content: ' ';
        display: block;
        height: 28px;
        width: 29px;
    }

    .icon-female:before {
        background-image: url('../img/icons/demography/female.svg');
        content: ' ';
        display: block;
        height: 27px;
        width: 26px;
    }

    .icon-saudi:before {
        background-image: url('../img/icons/demography/saudi.svg');
        content: ' ';
        display: block;
        height: 27px;
        width: 49px;
    }

    .icon-nonsaudi:before {
        background-image: url('../img/icons/demography/nonsaudi.svg');
        content: ' ';
        display: block;
        height: 30px;
        width: 35px;
    }

    &::before {
        border-color: $whiteGrey transparent transparent transparent;
        border-style: solid;
        border-width: 10px 12px 0 12px;
        bottom: -10px;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 0;
    }

    figure {
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 9px;
        padding: 0;
        text-align: center;

        .tooltip-icon-name {
            border-radius: 75px;
            color: $mirage;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            margin-right: 10px;
            padding: 0 10px;
            text-align: center;
            width: 100%;
        }
    }

    .icon-occupation:before {
        background-image: url('../img/icons/demography/occupation.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: ' ';
        display: block;
        height: 30px;
        width: 30px;
    }

    .tooltip-row {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;

        .tooltip-row-title,
        .tooltip-row-value {
            font-size: 14px;
            line-height: 24px;
        }

        .tooltip-row-title {
            color: $paleSky;
            margin-left: 60px;
        }

        .tooltip-row-value {
            color: $black;
        }
    }
}
