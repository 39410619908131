.population-over-years-chart-container {
    svg.line-graph {
        path {
            &.color-saudi {
                stroke: $algaeGreen;
            }

            &.color-non-saudi {
                stroke: $lemon;
            }

            &.color-male {
                stroke: $darkSkyBlue;
            }

            &.color-female {
                stroke: $darkMauve;
            }

            &.color-total {
                stroke: $denim;
            }
        }
    }

    .categories-list {
        .color-saudi.active {
            .category-element-value {
                color: $algaeGreen;
            }

            .category-element-marker {
                background: $algaeGreen;
                border-color: $algaeGreen;
            }
        }

        .color-non-saudi.active {
            .category-element-value {
                color: $lemon;
            }

            .category-element-marker {
                background: $lemon;
                border-color: $lemon;
            }
        }

        .color-male.active {
            .category-element-value {
                color: $darkSkyBlue;
            }

            .category-element-marker {
                background: $darkSkyBlue;
                border-color: $darkSkyBlue;
            }
        }

        .color-female.active {
            .category-element-value {
                color: $darkMauve;
            }

            .category-element-marker {
                background: $darkMauve;
                border-color: $darkMauve;
            }
        }

        .color-total.active {
            .category-element-value {
                color: $denim;
            }

            .category-element-marker {
                background: $denim;
                border-color: $denim;
            }
        }
    }

    .price-highlight {
        .color-saudi {
            .price-highlight-indicator {
                background: $algaeGreen;
                border: solid 1px $algaeGreen;
            }
            .price-highlight-commodity-name {
                color: $algaeGreen;
            }
        }

        .color-non-saudi {
            .price-highlight-indicator {
                background: $lemon;
                border: solid 1px $lemon;
            }
            .price-highlight-commodity-name {
                color: $lemon;
            }
        }

        .color-male {
            .price-highlight-indicator {
                background: $darkSkyBlue;
                border: solid 1px $darkSkyBlue;
            }
            .price-highlight-commodity-name {
                color: $darkSkyBlue;
            }
        }

        .color-female {
            .price-highlight-indicator {
                background: $darkMauve;
                border: solid 1px $darkMauve;
            }
            .price-highlight-commodity-name {
                color: $darkMauve;
            }
        }

        .color-total {
            .price-highlight-indicator {
                background: $denim;
                border: solid 1px $denim;
            }
            .price-highlight-commodity-name {
                color: $denim;
            }
        }
    }
}
