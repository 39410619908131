.region-employees-summary,
.region-licenses-summary,
.region-private-establishments-summary {
    justify-content: flex-start;

    .stats-title {
        font-size: 20px;
        line-height: 1.35;
        text-align: right;
        color: $white;
        margin-bottom: 24px;
    }

    .summary-highlights {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .summary-total-highlight {
        .summary-highlight {
            margin-bottom: 32px;

            @media (min-width: $screen-sm) {
                margin-bottom: 64px;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 40px;
            }

            .summary-highlight-header {
                .value-number {
                    font-size: 48px;
                }
                .value-suffix {
                    font-size: 24px;
                }
            }
        }
    }

    .summary-highlight {
        width: 50%;
        margin-bottom: 28px;

        @media (min-width: $screen-sm) {
            margin-bottom: 20px;
            width: 25%;
        }

        @media (min-width: $screen-lg) {
            width: 50%;
            margin-bottom: 36px;
        }

        .summary-highlight-header {
            display: flex;
            align-items: baseline;
            font-weight: 500;
            line-height: 1;
            text-align: right;
            color: $white;

            .value-number {
                font-size: 24px;
                margin-left: 4px;
            }

            .value-suffix {
                font-size: 12px;
            }
        }

        .summary-highlight-description {
            display: flex;
            flex-direction: row;

            .summary-highlight-name {
                font-size: 14px;
                line-height: 1.56;
                text-align: right;
                color: $osloGray;
                line-height: 21px;

                @media #{$tablet} {
                    font-size: 16px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                }

                @media (min-width: $screen-fhd) {
                    font-size: 18px;
                }
            }

            .summary-highlight-icon {
                width: 28px;
                height: 28px;
                margin-left: 4px;
            }
        }
    }
}

.region-licenses-summary,
.region-private-establishments-summary {
    @media #{$standard-mobile} {
        margin-top: 0;
    }

    @media (min-width: $screen-lg) {
        min-height: 382px;
    }
}
