.search-dropdown-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);

    li {
        &:first-child {
            padding-top: 3px;

            @media (min-width: $screen-lg) {
                padding-top: 7px;
            }
        }

        &:last-child {
            padding-bottom: 3px;

            @media #{$desktop-sm} {
                padding-bottom: 12px;
            }
        }

        .search-dropdown-link {
            -webkit-align-items: center;
            align-items: center;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
            height: 32px;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            padding: 0 15px;
            text-decoration: none;

            @media #{$desktop-sm} {
                height: 39px;
                padding: 0 22px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
        .search-dropdown-categoryName {
            color: $brightTurquoise;
            font-size: 11px;

            @media #{$desktop-sm} {
                font-size: 14px;
            }

            @media (min-width: $screen-lg) {
                font-size: 12px;
            }

            @media (min-width: $screen-fm) {
                font-size: 14px;
            }
        }
        .search-dropdown-label {
            color: $white;
            font-size: 14px;

            @media #{$desktop-sm} {
                font-size: 18px;
            }

            @media (min-width: $screen-lg) {
                font-size: 16px;
            }

            @media (min-width: $screen-fm) {
                font-size: 18px;
            }

            @media (min-width: $screen-hd) {
                font-size: 20px;
            }
        }
        &.no-results {
            span {
                @extend .search-dropdown-link;
                -webkit-flex-direction: row;
                flex-direction: row;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
