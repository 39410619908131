.product-side-menu {
    .empty {
        margin: 32px 0;

        &__title {
            text-align: right;
            font-size: 20px;
            color: #fff;
            margin-bottom: 20px;
        }

        &__content {
            font-family: 'DIN Next LT Arabic Medium';
            font-size: 14px;
        }
    }
}
