.tornado-chart,
.tornado-chart-mobile {
    display: flex;
    flex-direction: column;

    .tornado-chart-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .x-axis {
            .tick {
                text {
                    fill: $osloGray;
                    font-family: 'DIN Next LT Arabic';
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                }

                line {
                    fill: $osloGray;
                }
            }
        }

        .domain {
            stroke: $osloGray;
        }

        rect {
            cursor: pointer;
        }
    }

    .tornado-chart-description {
        display: flex;
        flex-direction: column;

        .tornado-chart-label {
            display: flex;
            flex-direction: column;

            .tornado-chart-label-value {
                font-size: 12px;
                color: $brightTurquoise;
                line-height: 1.33;
                text-align: center;
            }

            .tornado-chart-label-age-group {
                text-align: center;
                font-size: 14px;
                line-height: 0.7;
                margin-bottom: 4px;
                color: $osloGray;
            }
        }
    }

    .tornado-chart-legend-item-container {
        display: flex;
        flex-direction: row-reverse;

        .tornado-chart-legend-item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-size: 14px;
            line-height: 1.43;
            cursor: pointer;

            .tornado-chart-legend-item-indicator {
                width: 15px;
                height: 5px;
                border-radius: 100px;
                margin: 0 8px;
            }
        }

        &.left {
            flex-direction: row;

            .tornado-chart-legend-item {
                flex-direction: row;
            }
        }

        &.female {
            color: $darkMauve;

            .tornado-chart-legend-item-indicator {
                background-color: $darkMauve;
            }
        }
        &.male {
            color: $darkSkyBlue;

            .tornado-chart-legend-item-indicator {
                background-color: $darkSkyBlue;
            }
        }
        &.saudi {
            color: $algaeGreen;

            .tornado-chart-legend-item-indicator {
                background-color: $algaeGreen;
            }
        }
        &.nonsaudi {
            color: $lemon;

            .tornado-chart-legend-item-indicator {
                background-color: $lemon;
            }
        }
    }
}

.tornado-chart {
    min-height: 540px;
    margin-bottom: 20px;
}

.tornado-chart-mobile {
    min-height: 296px;
}

.tile {
    .tornado-chart-mobile {
        height: 100%;
    }
}

.tornado-chart-container {
    @media #{$small-mobile} {
        .tornado-chart-container-wrapper {
            .main-panel {
                .mobile-column {
                    .graph-container {
                        .description {
                            margin-bottom: 8px;
                        }
                    }
                }

                .source-column {
                    padding-top: 0;
                }
            }
        }
    }
}

.tornado-chart-tooltip {
    background-color: $mirage;
    border: 1px solid;
    padding: 0;
    width: 198px;

    &.female {
        border-color: $darkMauve;

        .tooltip-arrow {
            border-color: transparent $darkMauve $darkMauve transparent;
        }
    }
    &.male {
        border-color: $darkSkyBlue;

        .tooltip-arrow {
            border-color: transparent $darkSkyBlue $darkSkyBlue transparent;
        }
    }
    &.saudi {
        border-color: $algaeGreen;

        .tooltip-arrow {
            border-color: transparent $algaeGreen $algaeGreen transparent;
        }
    }
    &.nonsaudi {
        width: 204px;
        border-color: $lemon;

        .tooltip-arrow {
            border-color: transparent $lemon $lemon transparent;
        }
    }

    .tooltip-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $charcoalGrey;
        padding: 8px 16px 8px 16px;

        .tooltip-icon {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }

        .tooltip-title {
            font-size: 14px;
            font-weight: 500;
            color: $white;
        }
    }

    .tooltip-row {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        padding: 12px 16px 0 16px;

        .tooltip-row-value {
            font-size: 20px;
            line-height: 1.2;
            color: $white;
            margin-left: 2px;
        }

        .tooltip-row-value-suffix {
            font-size: 14px;
            line-height: 1.29;
            color: $white;
            margin-left: 8px;
        }

        .tooltip-row-percentage {
            font-size: 14px;
            line-height: 1.29;
            color: $osloGray;
        }
    }

    .tooltip-percentage-label {
        font-size: 14px;
        line-height: 1.29;
        color: $osloGray;
        padding: 4px 16px 12px 16px;
    }

    .tooltip-arrow {
        bottom: -8px;
        height: 16px;
        width: 16px;
        margin-left: -5px;
        margin-top: auto;
        position: absolute;
        right: auto;
        top: auto;
        left: 50%;
        z-index: 1;
        border: 1px solid;
        background-color: $mirage;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
    }

    &:before {
        content: unset;
    }
}
