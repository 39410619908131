section.sector-partners {
    margin-bottom: 20px;
    box-sizing: border-box;

    .subtitle {
        margin-bottom: 40px;
        margin-top: 16px;

        @media (max-width: $screen-sm - 1) {
            margin-bottom: 16px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 64px;
            margin-top: 8px;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 96px;
            margin-top: 20px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        margin-top: 40px;

        #partners-slider:not(.tns-slider) {
            display: none;
        }

        .tns-outer {
            direction: ltr;
            width: 100%;

            .tns-slider {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tns-nav {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                margin-top: 20px;

                > button {
                    padding: 0;
                    border: none;
                    background: $mineShaft2;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 10px;
                    height: 8px;
                    width: 8px;

                    &.tns-nav-active {
                        background: $elm2;
                    }
                }
            }

            .tns-inner#partners-slider-iw {
                margin: 20px 0;

                @media (min-width: $screen-lg) {
                    margin: 0;
                }
            }

            .tns-item {
                img {
                    max-width: 200px;
                    max-height: 95px;

                    @media (min-width: $screen-lg) {
                        max-width: 200px;
                        max-height: 100px;
                    }
                }
            }
        }
    }
}
