div.search {
    margin: 0 auto;
    max-width: 691px;
    position: relative;
    z-index: 3;

    form.search {
        border: solid 2px $brightTurquoise;
        border-radius: 25px;
        height: 44px;
        margin: 0 32px;
        overflow: hidden;
        transition: all 0.5s ease-out;

        @media #{$landscape-mobile} {
            margin: auto;
            width: 330px;
        }

        @media #{$iphone5Horizontal} {
            width: 250px;
        }

        @media #{$desktop-sm} {
            height: 54px;
            margin: 0 16vw;
        }

        @media (min-width: $screen-lg) {
            margin: 0 7.46vw;
        }

        @media (min-width: $screen-fm) {
            margin: 0;
        }

        &.open {
            background-color: $mirage;
            border-radius: 10px;
            box-shadow: 12px 20px 20px 0 rgba(0, 0, 0, 0.22);

            &.results-1 {
                height: 82px;
            }

            &.results-2 {
                height: 114px;
            }

            &.results-3 {
                height: 146px;
            }

            @media #{$desktop-sm} {
                border-width: 4px;

                &.results-1 {
                    height: 112px;
                }

                &.results-2 {
                    height: 151px;
                }

                &.results-3 {
                    height: 190px;
                }
            }

            @media (min-width: $screen-lg) {
                &.results-1 {
                    height: 116px;
                }

                &.results-2 {
                    height: 155px;
                }

                &.results-3 {
                    height: 194px;
                }
            }
        }
    }
}
