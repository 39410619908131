$icon-font-path: 'bootstrap-sass/assets/fonts/bootstrap/';

@import 'bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss';

@import 'bootstrap-sass/assets/stylesheets/bootstrap/_normalize.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_print.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_glyphicons.scss';

@import 'bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_type.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_grid.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_forms.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_buttons.scss';

@import 'bootstrap-sass/assets/stylesheets/bootstrap/_button-groups.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_input-groups.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_navs.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_navbar.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_dropdowns.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_modals.scss';

@import 'bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss';

@import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
@import 'bootstrap-select/dist/css/bootstrap-select.css';
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'owl.carousel/dist/assets/owl.theme.default.css';
@import 'fullpage.js/dist/jquery.fullpage.css';
@import '~/node_modules/nanoscroller/bin/css/nanoscroller.css';
