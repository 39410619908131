@mixin flipTrain($turn) {
    @if $turn == forward {
        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    } @else if $turn == backward {
        -ms-filter: 'FlipH';
        filter: FlipH;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

@mixin legendColors($prefix, $chart-colors) {
    @each $color in $chart-colors {
        $i: index($chart-colors, $color) - 1;

        #{$prefix}-#{$i} {
            .legend-marker {
                background: $color;
            }
        }
    }
}
@mixin homepageLegendColors($legend-colors) {
    @each $color in $legend-colors {
        $i: index($legend-colors, $color);

        .category-element:nth-of-type(#{$i}) {
            color: $color;

            .category-element-marker {
                background-color: $color;
            }
        }
    }
}

@mixin categoryColors($prefix, $category-colors, $withAverage) {
    @each $color in $category-colors {
        $i: if($withAverage, index($category-colors, $color) - 1, index($category-colors, $color));

        #{$prefix}#{$i} {
            &.active {
                .category-element-value {
                    color: $color;
                }

                .category-element-icon::before {
                    color: $color;
                }

                .category-element-marker {
                    background: $color;
                    border-color: $color;
                }

                .l-shape {
                    background: $color;
                }

                .l-shape::after {
                    background: $color;
                }
            }
            &.hovered {
                .category-element-value {
                    color: $color;
                    opacity: 0.5;
                }

                .category-element-icon::before {
                    color: $color;
                    opacity: 0.5;
                }

                .category-element-marker {
                    background: $color;
                    border-color: $color;
                    opacity: 0.5;
                }

                .l-shape {
                    background: $color;
                    opacity: 0.5;
                }

                .l-shape::after {
                    background: $color;
                    opacity: 0.5;
                }
            }
        }
    }
}

@mixin radarColors($prefix, $category-colors, $isSVG: true) {
    @each $color in $category-colors {
        $i: index($category-colors, $color) - 1;

        @if $isSVG {
            #{$prefix}#{$i} {
                fill: $color;
                stroke: $color;
            }
        } @else {
            &#{$prefix}#{$i} {
                color: $color;
            }
        }
    }
}

@mixin lineColors($prefix, $category-colors) {
    @each $color in $category-colors {
        $i: index($category-colors, $color);
        #{$prefix}#{$i} {
            stroke: $color;
        }
    }
}

@mixin lineStatsColors($prefix, $category-colors) {
    @each $color in $category-colors {
        $i: index($category-colors, $color);
        #{$prefix}#{$i} {
            .category-element-value {
                color: $color;
            }

            .category-element-marker {
                background: $color;
                border-color: $color;
            }
        }
    }
}

@mixin priceHighlightColors($prefix, $class-prefix, $category-colors) {
    @each $color in $category-colors {
        $i: index($category-colors, $color);
        #{$prefix}#{$i} {
            .#{$class-prefix}-commodity-name {
                color: $color;
            }

            .#{$class-prefix}-indicator {
                background: $color;
                border: solid 1px $color;
            }
        }
    }
}

@mixin radarBackgroundColors($prefix, $category-colors) {
    @each $color in $category-colors {
        $i: index($category-colors, $color) - 1;

        &#{$prefix}#{$i} {
            background-color: $color;
        }
    }
}

@mixin landscape-sm {
    @media screen and (orientation: landscape) and (min-height: 319px) and (max-height: 374px) {
        @content;
    }
}

@mixin landscape-lg {
    @media screen and (orientation: landscape) and (min-height: 375px) and (max-height: 415px) {
        @content;
    }
}

@mixin clickarea($extraDistance) {
    bottom: -$extraDistance;
    left: -$extraDistance;
    position: absolute;
    right: -$extraDistance;
    top: -$extraDistance;
}

@mixin flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin native-element-reset() {
    box-sizing: border-box;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}
