.all-reports-container {
    .all-reports-desktop {
        display: flex;
        flex-direction: column;

        .all-reports-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .all-reports-main {
            display: flex;
            align-items: flex-start;

            .all-reports-categories {
                width: 100%;

                .categories-tiles {
                    .category-item.tile {
                        width: 50%;
                        padding-right: 36px;

                        @media (min-width: $screen-1680) {
                            width: 33.333%;
                        }

                        .category-content {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .all-reports-tablet {
        display: flex;
        flex-direction: column;

        .all-reports-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            margin-bottom: 24px;
        }

        .all-reports-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 44px;

            .all-reports-filters-button {
                width: 243px;
            }
        }

        .all-reports-filters {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .all-reports-main {
            .all-reports-categories {
                .categories-container {
                    .categories-thumbnail-container {
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .all-reports-mobile {
        display: flex;
        flex-direction: column;

        .all-reports-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            margin-bottom: 24px;
        }

        .all-reports-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 24px;

            .all-reports-filters-button {
                width: 100%;
                margin-bottom: 24px;
            }

            .all-reports-search {
                width: 100%;
            }
        }

        .all-reports-filters {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .all-reports-main {
            .all-reports-categories {
                .categories-container {
                    .categories-thumbnail-container {
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
