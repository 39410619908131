@import 'loader';
@import 'switcher';
@import 'tooltip';
@import 'dropdown';
@import 'legend';
@import 'mouse_Scroll';
@import 'stars';
@import 'heading';
@import 'components';
@import 'navigation';

.nopadding {
    padding: 0;
}

.container-fluid {
    padding-left: 65px;
    padding-right: 65px;
}

.hidden {
    display: none !important;
}

.round-btn {
    border: 2px solid $brightTurquoise;
    border-radius: $border-radius-btn;
    color: $brightTurquoise;
    display: block;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        border-color: $white;
        color: $white;
        cursor: pointer;

        @media #{$standard-mobile, $small-mobile} {
            border-color: $brightTurquoise;
            color: $brightTurquoise;
            cursor: inherit;
        }
    }

    &.disabled {
        border-color: $regentGray2;
        color: $regentGray2;
        cursor: default;
        opacity: 0.3;
    }
}
