.no-data-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0;
    background-color: rgba(26, 32, 39, 0.7);

    &__message {
        position: absolute;
        width: 272px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        border: solid 2px #fff;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 40px 33px 41px 34px;
    }

    &__text {
        font-family: 'DIN Next LT Arabic', sans-serif;
        font-size: 20px;
        line-height: 1;
        text-align: center;
        color: $white;
    }
}
