.sector-top {
    background: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
    padding-top: 40px;

    @include landscape-sm {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }

    @include landscape-lg {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }

    @include landscape-lg {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }

    @media (min-width: $screen-md) {
        .triangle {
            bottom: -12vw;
            height: 70vw;
            left: 0;
            position: absolute;
            transform: rotate(-90deg) skewY(-79deg);
            width: 70vw;
            z-index: -1;
            &::after {
                background: linear-gradient(-90deg, $bunkerDark5 0, $mirage 50%);
                bottom: 10px;
                content: '';
                left: 10px;
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
    }

    @media (min-width: $screen-lg) {
        align-items: center;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        padding: 40px 2.6% 0 4%;
    }

    @media (min-width: $screen-fm) {
        background: inherit;
        padding: 40px 3% 0;
        position: relative;

        &:before {
            display: none;
        }
    }

    @media (min-width: $screen-hd) {
        padding: 40px 6% 0;
    }

    .sector-summary {
        padding: 0 29px 0;
        position: relative;
        text-align: right;
        transform: translateY(-26px);
        z-index: 1;

        @media #{$iPhone5} {
            transform: translateY(-21px);
        }

        @media #{$standard-mobile} {
            padding: 0 28px 0;
        }

        @media (min-width: $screen-sm) {
            transform: translateY(-29px);
        }

        @supports (-webkit-overflow-scrolling: touch) {
            //iPhones, iPads
            transform: translateY(-2vh) translateY(-6px);

            // iPhone X
            @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
                transform: translateY(-2.5vh) translateY(-6px);
            }
        }

        @media #{$desktop-sm} {
            padding: 0 51px 0;
        }

        @media (width: $screen-mid) and (min-device-height: $screen-sm) {
            padding-top: 15px;
        }

        @media (min-width: $screen-lg) {
            align-items: center;
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 0;
            width: 64%;
        }
        @include landscape-sm {
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 18px 5% 10px;
            text-align: right;
            width: 45%;
        }
        @include landscape-lg {
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 40px 5% 10px;
            text-align: right;
            width: 45%;
        }

        .title {
            font-size: 19px;
            line-height: 1.37;
            margin-top: 12px;
            max-width: inherit;
            padding: 0;

            @media (min-width: $screen-360) {
                margin-top: 18px;
            }

            @media (min-width: $screen-8plus) {
                margin-top: 22px;
            }

            @media #{$desktop-sm} {
                font-size: 40px;
                line-height: 1.5;
                margin-top: 36px;
            }
            @media (min-width: $screen-lg) {
                font-size: 30px;
                line-height: 1.33;
                margin-left: 40px;
                margin-right: 0;
                margin-top: 38px;
                max-width: 468px;
            }
            @media (min-width: $screen-fm) {
                font-size: 48px;
                line-height: 1.46;
                margin-top: 36px;
                max-width: 737px;
            }

            @media (min-width: $screen-hd) {
                font-size: 60px;
                line-height: 1.33;
                margin-top: 42px;
                max-width: 912px;
            }
        }
        a {
            color: $brightTurquoise;

            @include forAllTransition(0.2s);

            &:hover {
                color: darken($brightTurquoise, 15%);
                text-decoration: none;
            }
        }
        .details {
            display: inline-block;
            margin-top: 8px;

            &::before {
                width: 14px;
                height: 14px;
                display: inline-block;
                margin-left: 8px;
                background-image: url('/frontend/img/icons/product/commodity-details.svg');
                content: '';
            }

            @media (min-width: $screen-sm) {
                margin-top: 12px;
            }
        }
        .subtitle {
            color: $osloGray;
            font-size: 13px;
            line-height: 1.54;
            margin: 11px auto 33px;
            padding: 0;

            @media (min-width: $screen-360) {
                font-size: 14px;
                margin: 20px auto 44px;
            }

            @media (min-width: $screen-8plus) {
                margin: 20px auto 57px;
            }

            @media (min-width: $screen-md) {
                font-size: 14px;
                max-width: 468px;
            }

            @media #{$desktop-sm} {
                font-size: 16px;
                line-height: 23px;
                margin: 30px auto 107px;
                max-width: 100%;

                &.smaller-margin {
                    margin-bottom: 47px;
                }
            }

            @media (width: $screen-mid) and (min-device-height: $screen-sm) {
                margin-bottom: 50px;
            }

            @media (min-width: $screen-lg) {
                font-size: 14px;
                line-height: 21px;
                margin: 30px 0 0;
                max-width: 484px;
            }

            @media (min-width: $screen-fm) {
                font-size: 16px;
                line-height: 23px;
                margin-top: 30px;
                max-width: 588px;
            }

            @media (min-width: $screen-hd) {
                font-size: 18px;
                line-height: 27px;
                margin-top: 36px;
            }
        }
    }
    .economy-stats,
    .product-page-stats,
    .demography-stats {
        transform: translateY(-26px);

        @media #{$iPhone5} {
            transform: translateY(-21px);
        }

        @media (min-width: $screen-sm) {
            transform: translateY(-29px);
        }

        @supports (-webkit-overflow-scrolling: touch) {
            //iPhones, iPads
            transform: translateY(-2vh) translateY(-6px);

            // iPhone X
            @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
                transform: translateY(-2.5vh) translateY(-6px);
            }
        }

        @media (min-width: $screen-lg) {
            width: 46%;
        }
        @include landscape-sm {
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 20px;
            width: 55%;
        }
        @include landscape-lg {
            -webkit-justify-content: space-around;
            justify-content: space-around;
            padding: 20px;
            width: 55%;
        }

        .highlights-buttons {
            margin-bottom: 0;
        }
    }

    // SPECIAL CLASS FOR TWO LINES

    &.double-line {
        .sector-summary {
            @media (min-width: $screen-360) {
                padding-top: 22px;
            }

            @media (min-width: $screen-8plus) {
                padding-top: 55px;
            }

            @media #{$desktop-sm} {
                padding-top: 93px;
            }

            @media (min-width: $screen-lg) {
                padding-top: 0;
            }

            .title {
                @media #{$desktop-sm} {
                    line-height: 1.25;
                }

                @media (min-width: $screen-lg) {
                    line-height: 1.33;
                }

                @media (min-width: $screen-fm) {
                    line-height: 1.46;
                }

                @media (min-width: $screen-hd) {
                    line-height: 1.33;
                    max-width: 912px;
                }
            }

            .subtitle {
                margin-bottom: 19px;

                @media (min-width: $screen-360) {
                    margin-bottom: 44px;
                }

                @media (min-width: $screen-8plus) {
                    margin-bottom: 55px;
                }

                @media #{$desktop-sm} {
                    margin-bottom: 95px;
                }

                @media (min-width: $screen-lg) {
                    font-size: 14px;
                    margin-bottom: 0;
                    max-width: 468px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                    max-width: 588px;
                }

                @media (min-width: $screen-hd) {
                    font-size: 18px;
                }
            }
        }
    }
}

.highlights {
    display: inherit;
    padding-top: 0 !important;

    .fp-tableCell {
        display: block;
    }
}
