.product-info-section {
    text-align: inherit;
    font-family: 'DIN Next LT Arabic Medium', sans-serif;
    padding-top: 170px;
    padding-right: 4em;
    height: 100vh;

    .header-png-pdf {
        .section-title.section-title {
            font-family: DINNextLTArabic, sans-serif;
            font-weight: 400;
        }
    }

    &__section-header {
        font-size: 18px;
        margin-bottom: 9px;
    }

    &__section-title {
        font-size: 24px;
        color: white;
        margin-bottom: 24px;
        margin-left: 80px;
        text-align: justify;
    }

    &__section-list {
        font-size: 16px;
        color: white;
        margin-top: 30px;
        margin-bottom: 30px;
        max-height: 900px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .details-section-text {
        color: $white;
        width: 40vw;
        line-height: 26px;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            top: 8px;
        }

        &.--header {
            padding-right: 16px;

            &::before {
                background-color: #fff;
                right: 0;
            }
        }

        &.--item {
            padding-right: 32px;
            color: rgb(129, 140, 154);

            &::before {
                background-color: transparent;
                border: 1px solid rgb(129, 140, 154);
                right: 16px;
            }
        }
    }

    li {
        margin-bottom: 8px;
        width: 850px;
    }

    &__section-source {
        font-size: 18px;
        margin-top: 10px;
    }

    a {
        color: $brightTurquoise;
    }
}
