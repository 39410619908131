.product-price-summary {
    .price-highlight {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-bottom: 20px;

        @media #{$tablet} {
            width: 25%;
            align-items: flex-start;
        }

        @include priceHighlightColors('.color-', 'price-highlight', $categories-colors-wo-average);

        .price-highlight-average {
            color: $white;

            .price-highlight-value {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2;
            }

            .price-highlight-suffix {
                font-size: 12px;
                font-weight: 500;
                line-height: 2;
            }
        }

        .price-highlight-name {
            display: flex;
            align-items: baseline;

            .price-highlight-commodity-name {
                font-size: 14px;
                line-height: 1.5;
                text-align: right;
                margin-right: 8px;
                margin-left: 8px;
            }
            .price-highlight-indicator {
                width: 15px;
                height: 10px;
                border-radius: 100px;
            }
        }

        .price-highlight-unit-value {
            margin-right: 24px;
            color: $osloGray;
            font-size: 14px;
        }
    }

    .commodities-annotation {
        font-size: 12px;
        line-height: 1.33;
        text-align: right;
        color: $osloGray;
    }

    @media #{$tablet} {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .stats-content {
            height: 100%;
        }

        .commodities-annotation {
            text-align: center;
            margin-bottom: 8px;
        }
    }

    @media (max-width: $screen-xs) {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .stats-content {
            height: 100%;
            margin-top: 24px;
        }

        .commodities-annotation {
            margin-bottom: 40px;
            text-align: center;
            font-size: 10px;
        }
    }
}

.consumer-price-chart-container {
    .product-price-summary {
        .price-highlight {
            margin-bottom: 40px;

            @media #{$tablet-small} {
                width: 160px;
                margin-left: 60px;
            }
        }
    }
}
