body.report {
    main {
        margin-top: 40px;
        overflow: hidden;

        @media (max-width: $screen-sm) {
            margin-top: 40px;
        }

        .content {
            section {
                background: linear-gradient(to top, $mirage 80%, $bunkerDark6 100%);
                padding: 36% 5% 0;

                @media #{$desktop-sm} {
                    padding: 15% 2% 24px;
                }

                &:not(.sector-title):not(.sector-summary):nth-child(3n + 1) {
                    background: linear-gradient(to top, $mirage 80%, $bunkerDark6 100%);
                    padding-top: 18%;

                    @media #{$desktop-sm} {
                        padding-top: 12%;
                        padding-top: 9%;
                    }

                    @media (min-width: $screen-fm) {
                        padding-top: 12%;
                    }
                }

                &:not(.sector-title):not(.sector-summary):nth-child(3n + 3) {
                    background: linear-gradient(to top, $mirage 75%, $bunkerDark6 110%);
                    padding-top: 18%;

                    @media #{$desktop-sm} {
                        padding-top: 9%;
                    }

                    @media (min-width: $screen-fm) {
                        padding-top: 13%;
                    }
                }

                &::before {
                    background-color: $mirage;
                    bottom: inherit;
                    content: '';
                    display: block;
                    left: 0;
                    padding-bottom: 100vw;
                    position: absolute;
                    top: 34vw;
                    -webkit-transform: rotate(-90deg) skewX(18deg) translateX(0);
                    transform: rotate(-90deg) skewX(18deg) translateX(0);
                    transform-origin: 0 0;
                    width: 100%;
                    z-index: 0;

                    @media #{$desktop-sm} {
                        padding-bottom: 45vw;
                        top: 0;
                        -webkit-transform: rotate(-30deg) skewX(50deg) skewY(30deg);
                        transform: rotate(-30deg) skewX(50deg) skewY(30deg);
                    }
                }

                &:first-of-type {
                    &::before {
                        background-color: $mirage;
                        top: 55vw;

                        @media #{$desktop-sm} {
                            padding-bottom: 45vw;
                            top: 0;
                            -webkit-transform: rotate(-30deg) skewX(50deg) skewY(30deg);
                            transform: rotate(-30deg) skewX(50deg) skewY(30deg);
                        }
                    }
                }

                &:nth-child(3n + 1) {
                    &::before {
                        top: 0;
                        -webkit-transform: rotate(-90deg) skewX(-18deg) translateX(0);
                        transform: rotate(-90deg) skewX(-18deg) translateX(0);

                        @media #{$desktop-sm} {
                            padding-bottom: 65vw;
                            position: absolute;
                            top: 0;
                            -webkit-transform: rotate(-30deg) skewX(50deg) skewY(60deg);
                            transform: rotate(-30deg) skewX(50deg) skewY(60deg);
                        }
                    }
                }

                &:nth-child(3n + 3) {
                    &::before {
                        top: 0;
                        -webkit-transform: rotate(-90deg) skewX(-18deg) translateX(0);
                        transform: rotate(-90deg) skewX(-18deg) translateX(0);

                        @media #{$desktop-sm} {
                            padding-bottom: 100vw;
                            position: absolute;
                            top: 22vw;
                            -webkit-transform: rotate(-90deg) skewX(12deg) translateX(0);
                            transform: rotate(-90deg) skewX(12deg) translateX(0);
                        }
                    }
                }

                &:nth-child(1) {
                    &::before {
                        display: block;
                        padding-bottom: 100vw;
                        top: 50px;
                        -webkit-transform: rotate(-90deg) skewX(-10deg) translateX(0);
                        transform: rotate(-90deg) skewX(-10deg) translateX(0);
                    }
                }
            }

            .subtitle {
                font-size: 18px;
                margin: 20px auto;

                @media #{$desktop-sm} {
                    margin-bottom: 20px;
                    margin-top: 0;
                }

                @media (min-width: $screen-fm) {
                    margin: 20px auto 30px;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .switcher-content,
            .dropdown-chart {
                max-width: 280px;
                padding: 0;

                @media #{$desktop-sm} {
                    max-width: none;
                }
            }

            .chart-content {
                margin-top: 130px;

                @media (max-width: $screen-sm) {
                    margin-top: 0;
                }
            }

            .source {
                clear: both;
                color: $ghost;
                font-family: 'DIN Next LT Arabic Light', sans-serif;
                font-size: 12px;
                font-weight: 300;
                line-height: 17px;
                margin-bottom: 0;
                padding-top: 13px;
                text-align: center;

                @media (min-width: $screen-lg) {
                    padding-top: 10px;
                }

                @media (min-width: $screen-fm) {
                    font-size: 16px;
                    line-height: 23px;
                    padding-top: 28px;
                }

                a {
                    color: $ghost;

                    @include forAllTransition(0.3s);

                    &:hover {
                        color: darken($ghost, 15%);
                        text-decoration: none;
                    }
                }
            }
        }

        @import 'report/_top';
    }
}
@import 'report/_pngheader';
@import 'product/_pdfinfosection';
