.multiline {
    .border-grid {
        line.border-line {
            shape-rendering: crispEdges;
            stroke: $regentGray2;
            stroke-width: 1;
        }
    }

    path.line {
        fill: none;
        stroke-linecap: round;
        stroke-width: 2;
    }

    path.mouse-line {
        fill: none;
        opacity: 0;
        stroke: $black;
        stroke-width: 15;
    }

    path.mouse-line:hover {
        cursor: pointer;
    }

    .axis {
        text {
            fill: $regentGray2;
            font-family: 'DIN Next LT Arabic';
            font-size: 14px;
            font-weight: 200;
            letter-spacing: 0;
            stroke: none;

            @media (max-width: $screen-sm) {
                font-size: 11px;
            }
        }

        .tick {
            text {
                dy: 0.71em;
                fill: $osloGray;
                y: 6;
            }

            path,
            line {
                fill: none;
                stroke: $darkBlackBlue;
                stroke-width: 1;
            }
        }
    }

    .x.axis {
        line {
            fill: none;
            stroke: none;
        }

        .domain {
            fill: none;
            stroke: none;
        }
    }

    .focus {
        line.hover-line {
            shape-rendering: crispEdges;
            stroke: $OuterSpace;
            stroke-dasharray: none;
            stroke-width: 1px;
        }
        circle {
            cursor: pointer;
            stroke: $Azure;
            stroke-width: 3px;
        }
    }
}

.sector-multichart-narrow {
    .btn-group.bootstrap-select.dropdown-left.dropdown-chart {
        @media (min-width: $screen-xs) {
            margin-bottom: 10px;
            margin-left: 0;
        }

        @media (min-width: $screen-sm) {
            margin-bottom: 20px;
            margin-left: 23px;
        }

        @media (min-width: $screen-mid) {
            margin-bottom: 10px;
            margin-left: 30px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 10px;
            margin-left: 36px;
        }
    }
}

// styles used in tiles
.small-graph {
    .multiline {
        .y-1 {
            .tick {
                text {
                    font-size: 12px;
                }

                line {
                    stroke: $black;
                    stroke-width: 2px;
                }
            }
        }

        .x {
            .tick {
                text {
                    font-size: 14px;
                }
            }
        }

        .y-2 {
            display: none;
        }

        path.domain {
            display: none;
        }
    }
}
