.region-commercial-establishments-summary {
    @media #{$standard-mobile} {
        margin-top: 0;
    }

    .summary-highlights {
        align-content: baseline;
        margin-bottom: 0;

        @media (min-width: $screen-lg) {
            min-height: 400px;
        }

        .summary-highlight {
            margin-bottom: 16px;

            @include priceHighlightColors('.color-', 'summary-highlight', $categories-colors-wo-average);

            .summary-highlight-header {
                display: flex;
                align-items: baseline;
                margin-bottom: 8px;

                .summary-highlight-indicator {
                    width: 16px;
                    height: 10px;
                    margin-left: 8px;
                    border-radius: 5px;
                }

                .summary-highlight-commodity-name {
                    font-size: 20px;
                    line-height: 1.4;
                    text-align: right;
                }
            }

            .summary-highlight-description-container {
                display: flex;
                flex-direction: row;

                .summary-highlight-description {
                    display: flex;
                    flex-direction: column;

                    .summary-highlight-description-value {
                        width: 120px;
                        font-weight: 500;
                        text-align: right;
                        color: $white;

                        .value-number {
                            font-size: 20px;
                            line-height: 1.2;
                        }

                        .value-suffix {
                            font-size: 12px;
                            line-height: 2;
                        }
                    }

                    .summary-highlight-description-label {
                        font-size: 14px;
                        text-align: right;
                        color: $osloGray;
                    }
                }
            }

            @media #{$small-mobile} {
                margin-bottom: 0;
            }

            @media (min-width: $screen-sm) {
                margin-bottom: 32px;
            }

            @media #{$tablet-large} {
                &:not(:last-of-type) {
                    padding-left: 0;
                    margin-left: 20px;
                }
            }

            @media (min-width: $screen-1920) {
                .summary-highlight-header {
                    margin-bottom: 16px;
                }

                .summary-highlight-description-container {
                    .summary-highlight-description-value {
                        width: 170px;

                        .value-number {
                            font-size: 24px;
                            line-height: 1;
                        }
                    }

                    .summary-highlight-description-label {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
