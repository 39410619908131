.details-section {
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 40px - 56px);
    padding: 16px 16px 24px 56px;
    border-top: 1px solid $charcoalGrey;
    border-radius: 8px;
    -webkit-appearance: none;
    box-shadow: 0 -8px 22px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 -8px 22px 0 rgba(0, 0, 0, 0.2);
    background-color: $mirage;
    font-size: 14px;
    color: $white;
    z-index: 10;
    overflow: auto;
    opacity: 0.5;

    &::-webkit-scrollbar {
        @media (hover: hover) and (pointer: fine) {
            width: 4px;
            height: 4px;
        }
    }

    &::-webkit-scrollbar-track {
        @media (hover: hover) and (pointer: fine) {
            border-radius: 2px;
            box-shadow: none;
            -webkit-box-shadow: none;
            background-color: $charcoalGrey;
        }
    }

    &::-webkit-scrollbar-thumb {
        @media (hover: hover) and (pointer: fine) {
            border-radius: 2px;
            background-color: $brightTurquoise;
            box-shadow: none;
            -webkit-box-shadow: none;
        }
    }

    @media (min-width: $screen-sm) {
        top: 40px;
        right: unset;
        left: -100%;
        max-width: 50vw;
        min-width: 25vw;
        height: unset;
        max-height: calc(100% - 40px);
        padding: 20px 36px 24px 68px;
        border-right: 1px solid $charcoalGrey;
        border-bottom: 1px solid $charcoalGrey;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        -webkit-appearance: none;
        box-shadow: 8px 0 22px 0 rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 8px 0 22px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
    }

    @media (min-width: $screen-lg) {
        max-width: 40vw;
    }

    a {
        color: $brightTurquoise;

        @include forAllTransition(0.2s);

        &:hover {
            color: darken($brightTurquoise, 15%);
            text-decoration: none;
        }
    }
}

.details-section-fade {
    &::before {
        height: 40px;
        position: fixed;
        left: 0;
        right: 0;
        margin-top: -16px;
        border-radius: 8px;
        background-image: linear-gradient(to top, transparent, $mirage);
        content: '';
        transition: all 0.2s ease-in-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        @media (min-width: $screen-sm) {
            top: 40px;
        }
    }
}

.details-close-underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1a2027;
    opacity: 0.5;
    z-index: 5;
}

.details-close-button {
    left: 16px;
    width: 24px;
    height: 24px;
    position: fixed;
    background: url('/frontend/img/icons/close-x.svg');
    filter: invert(58%) sepia(6%) saturate(271%) hue-rotate(179deg) brightness(89%) contrast(88%);
    cursor: pointer;

    @media (min-width: $screen-sm) {
        left: 20px;
    }
}

.details-section-source {
    margin: 0;
    margin-top: auto;
    padding-top: 24px;
}

.details-section-header {
    margin-bottom: 8px;
    color: $osloGray;
}

.details-section-title {
    margin-bottom: 20px;
    font-family: 'DIN Next LT Arabic Medium';
    font-size: 16px;
}

.details-section-list {
    margin: 0;
    padding-inline-start: 16px;
    color: $osloGray;
    font-family: 'DIN Next LT Arabic Medium';

    &.level-2 {
        font-family: 'DIN Next LT Arabic';
    }

    .details-section-text {
        color: $white;
    }

    li {
        margin-bottom: 8px;
    }
}
