.graph-legend {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-around;
    font-size: 12px;
    color: #fff;
    margin-top: 8px;
    align-self: center;
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: $screen-360) {
        display: none;
    }

    @media #{$tablet} {
        margin-top: 20px;
        margin-bottom: 28px;
    }

    @media (min-width: $screen-sm) {
        width: 480px;
    }

    .legend-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 150px;

        @media (min-width: $screen-mid) {
            margin-bottom: 20px;
        }
    }

    .legend-colors {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-left: 4px;

        &.export-legend-color-0 {
            background-color: #b5efc7;
        }

        &.export-legend-color-1 {
            background-color: #69df8d;
        }

        &.export-legend-color-2 {
            background-color: #1dcf53;
        }

        &.export-legend-color-3 {
            background-color: #239c4e;
        }

        &.export-legend-color-4 {
            background-color: #276846;
        }

        &.import-legend-color-0 {
            background-color: #beedff;
        }

        &.import-legend-color-1 {
            background-color: #8addff;
        }

        &.import-legend-color-2 {
            background-color: #00b6ff;
        }

        &.import-legend-color-3 {
            background-color: #0d94ce;
        }

        &.import-legend-color-4 {
            background-color: #1c6486;
        }

        &.not-available {
            background-color: #2b343e;
        }
    }
}
