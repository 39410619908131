.mouse-scroll {
    padding: 20px 0;
    text-align: center;
    width: 100%;
    z-index: 3;

    @media (max-height: $screen-height-s), (min-width: $screen-mid) {
        padding: 4px;
    }

    @media (height: $screen-height-m) {
        padding: 0;
    }

    .arrow-scroll {
        display: block;
        margin: 0 auto 10px;
        position: relative;
        transform: none;
        width: 24px;
        z-index: 2;

        &-clickarea {
            @include clickarea(15px);
            z-index: 3;
        }

        @media (min-width: $screen-fm) {
            border: 2px solid $brightTurquoise;
        }

        &::before {
            border-radius: 14px;
            bottom: -2px;
            content: '';
            left: -1px;
            position: absolute;
            right: -1px;
            top: -1px;

            @media (min-width: $screen-fm) {
                bottom: -3px;
                left: -2px;
                right: -2px;
                top: -2px;
            }
        }

        @media (max-height: $screen-height-l) {
            margin: 0 auto 4px;
        }

        @media (height: $screen-height-m) {
            margin: 4px auto 2px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
