.map-tooltip {
    box-shadow: 0 1px 3px rgba($black, 0.25);
    display: block;
    font-family: 'DIN Next LT Arabic', serif;
    font-size: 12px;
    font-weight: 200;
    margin-top: -30px;
    pointer-events: none;
    position: absolute;
    z-index: 2000;
    max-width: 232px;

    &.import,
    &.occupation,
    &.total,
    &.licenses,
    &.employees {
        .tooltip-container {
            border-color: $darkSkyBlue;
        }
        .tooltip-arrow {
            border-color: $darkSkyBlue;
        }
    }

    &.export,
    &.economic,
    &.saudi {
        .tooltip-container {
            border-color: $algaeGreen;
        }

        .tooltip-arrow {
            border-color: $algaeGreen;
        }
    }

    .tooltip-container {
        border: 1px solid;
        background-color: $mirage;
        border-radius: 4px;

        .tooltip-header {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 16px;
            border-bottom: 1px solid $charcoalGrey;

            img {
                height: 16px;
                margin-left: 8px;
            }

            .tooltip-main-title {
                color: $white;
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-top: -2px;
            }
        }

        .tooltip-data {
            padding: 0 16px;
            margin-top: 12px;

            .tooltip-value {
                font-size: 20px;
                color: $white;
            }

            .tooltip-suffix {
                font-size: 14px;
                color: $white;
                margin-right: 3px;
            }

            .tooltip-percentage {
                font-size: 14px;
                color: $osloGray;
                margin-right: 3px;
            }
        }

        .tooltip-label {
            padding: 0 16px;
            font-size: 14px;
            color: $osloGray;
            margin-bottom: 16px;
            margin-top: 5px;
        }
    }

    .tooltip-arrow {
        bottom: -5px;
        box-shadow: rgba($black, 0.25) 0 1px 3px;
        height: 10px;
        left: 50%;
        margin-left: -5px;
        margin-top: auto;
        position: absolute;
        right: auto;
        top: auto;
        width: 10px;
        z-index: 1;
        border: 1px solid;
        background-color: $mirage;
        border-top: none;
        border-left: none;
    }
}
