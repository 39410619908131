.line-chart-container,
.spending-chart-container,
.prices-chart-container,
.consumer-price-chart-container,
.wholesale-price-chart-container,
.local-production-chart-container,
.population-over-years-chart-container,
.commercial-establishments-chart-container {
    .main-panel {
        @media (min-width: $screen-lg) {
            display: block;
            margin-left: 42px;
        }
        @media (min-width: $screen-fm) {
            margin-left: 63px;
        }

        @media (min-width: $screen-hd) {
            margin-left: 66px;
        }

        .graph-content {
            transform: translateY(-26px);

            @media (min-width: $screen-sm) {
                transform: inherit;
            }
        }

        .chart-content {
            @media (min-width: $screen-lg) {
                top: calc(50% - 10px);
            }
        }

        .chart-column,
        .slider-column {
            @media (min-width: $screen-lg) {
                float: left;
                width: 521px;
            }

            @media (min-width: $screen-fm) {
                width: 630px;
            }

            @media (min-width: $screen-hd) {
                width: 748px;
            }

            .range-input {
                @media #{$ipad-small} {
                    margin-bottom: 12px;
                }

                @media (min-width: $screen-lg) {
                    width: 100%;
                }

                &::after {
                    @media (min-width: $screen-lg) {
                        max-width: 492px;
                    }

                    @media (min-width: $screen-fm) {
                        max-width: 600px;
                    }

                    @media (min-width: $screen-hd) {
                        max-width: 718px;
                    }
                }
            }

            .range-labels {
                @media (min-width: $screen-lg) {
                    max-width: 481px;
                }

                @media (min-width: $screen-fm) {
                    max-width: 594px;
                }

                @media (min-width: $screen-hd) {
                    margin-right: 2px;
                    max-width: 716px;
                }
            }
        }

        .basic-column-description {
            @media #{$ipad-small} {
                margin-bottom: 0;
            }

            @media (min-width: $screen-lg) {
                margin-bottom: 26px;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 44px;
            }

            @media (min-width: $screen-hd) {
                margin-bottom: 46px;
            }
        }

        .summary-column {
            .stats-content {
                @media (min-width: $screen-lg) {
                    -webkit-flex-direction: row;
                    flex-direction: row;
                }
            }
        }

        .slider-column {
            width: 100%;

            @media #{$ipadHorizontal} {
                -webkit-align-self: center;
                align-self: center;
                margin-bottom: 40px;
                margin-left: auto;
                margin-right: auto;
            }

            .year-dropdown .dropdown-toggle {
                @media #{$small-mobile} {
                    transform: translateY(-7px);
                }
            }
        }

        &.with-dialog {
            svg.line-graph .axis .tick {
                text,
                line {
                    opacity: 0.3;
                }
            }

            .static-x-axis-container {
                opacity: 0.3;
            }
        }

        .mobile-column {
            height: 100%;

            @media (min-width: $screen-sm) {
                height: inherit;
            }

            .summary-container {
                .year-dropdown {
                    span.filter-option.center {
                        color: $aquamarine2;
                    }

                    .text.decorator {
                        color: $turquoise !important;
                    }
                }
            }

            .owl-dots {
                margin-top: 0;

                @media (min-width: $screen-sm) {
                    margin-top: inherit;
                    transform: inherit;
                }
            }

            .graph-container {
                .static-x-axis-container {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 5px;
                    padding-left: 9px;
                    position: relative;
                    width: 279px;
                    z-index: 2;

                    @media #{$mobileHorizontal} {
                        margin-right: 80px;
                        width: 474px;
                    }

                    @media #{$iphone8Horizontal} {
                        margin-right: 40px;
                        width: 590px;
                    }

                    @media (min-width: $screen-360) {
                        padding-left: 30px;
                        width: 300px;
                    }

                    @media (min-width: $screen-sm) {
                        margin-top: 0;
                        padding-left: 0;
                        width: 270px;
                    }
                }
            }
        }
    }

    .product-line-chart-wrapper {
        .category-lists {
            .categories-list {
                @include categoryColors('.color-', $product-categories-colors, false);
            }
        }

        svg.line-graph {
            @include lineColors('path.color-', $product-categories-colors);
        }

        .line-of-business-summary .value.color-1,
        .radar-summary .value.color-1 {
            color: $darkSkyBlue;
        }
    }
}
