.other-countries {
    font-size: 12px;
    margin-bottom: 8px;

    .value {
        font-weight: 500;
        color: $white;
    }

    .label {
        color: $osloGray;
    }
}
