@import '_title';
@import '_summary';

.sector-top {
    @media #{$small-mobile} {
        max-height: 436px;
    }

    max-height: none;
    min-height: 72vh;
    position: relative;

    @media #{$desktop-sm} {
        height: auto;
        max-height: 800px;
        min-height: 0;
    }

    @media (min-width: $screen-md) {
        @media (max-height: 640px) {
            max-height: 800px;
        }
    }

    @media (min-width: $screen-lg) {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        max-height: 570px;
    }

    @media (min-width: $screen-fm) {
        -webkit-justify-content: space-around;
        justify-content: space-around;
        max-height: 800px;
    }

    @media (min-width: $screen-hd) {
        max-height: 864px;
    }
}
