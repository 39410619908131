svg.line-graph {
    overflow: visible;

    path.category {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 3;
        &.hovered {
            cursor: pointer;
            stroke-opacity: 0.5;
            stroke-width: 4;
        }
        &.disabled {
            stroke: $gray22;
            stroke-opacity: 0.5;
            stroke-width: 0;

            @media (min-width: $screen-sm) {
                stroke-width: 2;
            }
        }
    }

    .domain {
        display: none;
    }

    @include lineColors('path.color-', $categories-colors-wo-average);
    .axis {
        .tick {
            text {
                dy: 0.71em;
                fill: $osloGray;
                font-family: 'DIN Next LT Arabic Medium';
                font-size: 12px;
                y: 6;

                @media (min-width: $screen-sm) {
                    font-size: 16px !important;
                }

                @media (min-width: $screen-lg) and (max-width: $screen-fm) {
                    font-size: 14px !important;
                }

                @media (min-width: $screen-hd) {
                    font-size: 18px !important;
                }

                &:not([fill]) {
                    @media (min-width: $screen-sm) {
                        font-size: 12px !important;
                    }

                    @media (min-width: $screen-lg) and (max-width: $screen-fm) {
                        font-size: 10px !important;
                    }

                    @media (min-width: $screen-hd) {
                        font-size: 14px !important;
                    }
                }
            }
            path,
            line {
                fill: none;
                stroke: $black;
                stroke-width: 1;
            }
        }
    }
    .year-indicator {
        line {
            display: none;

            @media (min-width: $screen-sm) {
                display: initial;
                stroke: $aquamarine2;
                stroke-dasharray: 5;
                stroke-width: 1;
            }
        }
    }
}

.line-chart.chart-container.sector-level-2 {
    @include lineColors('path.color-', $radar-chart-homepage-colors-group-3);
}

._chart_mg_add[data-is-trade-report='1'] {
    .chart-column,
    .mobile-column {
        .line-chart.chart-container .line-graph {
            @include lineColors('.color-', $trade-report-line-chart-colors);
        }
    }
}
