.highlights.png {
    .highlights-descriptions.png {
        overflow: visible;
        flex-wrap: wrap;
        width: 93vw;
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        li {
            max-width: 450px;
        }

        .highlights-description {
            font-size: 3rem;
            flex-direction: column;
            padding: 3rem 1rem;

            img.png {
                width: 64px;
                height: 51.2px;
                object-fit: contain;
            }
            .highlights-description-value {
                display: block;
                align-items: baseline;
                margin-bottom: 0;
                font-size: 104px;

                @media (min-width: $screen-hd) {
                    font-size: 104px;
                    line-height: 1.15;
                }

                .value-suffix {
                    margin-bottom: 14px;

                    font-size: 48px;
                    line-height: 1.25;
                }
            }

            .highlights-description-text {
                color: $white;
                font-size: 24px;
                line-height: 1.71;

                @media (min-width: $screen-fhd) {
                    max-width: 760px;
                    font-size: 24px;
                    line-height: 1.5;
                }
            }
        }
    }
}
