.tile {
    float: right;
    margin-top: 3vh;
    margin-top: calc(var(--vh, 1vh) * 3);
    padding: 0 4.5vw;

    @media #{$landscape-mobile} {
        margin-top: 12.18vh;
        margin-top: calc(var(--vh, 1vh) * 12.18);
        padding: 0 3.8vw;
    }

    @media #{$desktop-sm} {
        margin-top: 2.18vh;
        margin-top: calc(var(--vh, 1vh) * 2.18);
        padding: 0 3.8vw;
    }

    @media (min-width: $screen-lg) {
        float: none;
        margin-top: 60px;
        padding: 0;
    }

    @media (min-width: $screen-1366) {
        padding: 0 0.3vw 3.75vh;
        padding: 0 0.3vw calc(var(--vh, 1vh) * 3.75);
    }

    @media (min-width: $screen-fhd) {
        padding: 0 2.08vw 3.75vh;
        padding: 0 2.08vw calc(var(--vh, 1vh) * 3.75);
    }

    .category-content {
        background: $mirage;
        border-radius: 5px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        height: 425px;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        position: relative;
        text-decoration: none;
        width: 89.5vw;
        z-index: 0;

        @media (min-width: $screen-xs) {
            height: 460px;
        }

        @media (min-width: $screen-sm) {
            width: 42.3vw;
            height: 604px;
        }

        @media (min-width: $screen-lg) {
            min-height: unset;
            width: 516px;
        }

        @media (min-width: $screen-fm) {
            height: 700px;
        }

        .icon {
            width: auto;
            height: 46px;
            position: absolute;
            top: -23px;
            margin-right: 30px;

            @media (min-width: $screen-sm) {
                height: 70px;
                top: -35px;
            }

            @media (min-width: $screen-lg) {
                margin-right: 50px;
            }
        }

        .chart-and-legend-container {
            -webkit-align-items: center;
            align-items: center;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            height: 100%;
            -webkit-justify-content: flex-end;
            justify-content: flex-end;

            @media (min-width: $screen-1680) {
                padding: 0 32px;
            }

            .graph-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;

                .y-1 .tick line {
                    stroke-width: 1px;
                }

                @media (min-width: $screen-lg) {
                    height: unset;

                    .radar-chart {
                        margin: 35px 0 50px;
                    }

                    .line-graph {
                        margin: 10px 0 15px;
                    }
                }
            }

            &.product {
                .graph-content {
                    align-items: center;

                    .chart-container {
                        height: 244px;
                        width: 259px;

                        @media (min-width: $screen-lg) {
                            height: 300px;
                            width: 412px;
                        }

                        @media (min-width: $screen-fm) {
                            height: 385px;
                        }

                        @media (min-width: $screen-1680) {
                            width: 100%;
                        }
                    }
                }
            }

            &.location {
                .graph-content {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        .report-titles {
            margin-top: 40px;
            padding: 0 30px;
            text-align: right;

            @media (min-width: $screen-lg) {
                margin-top: 60px;
                min-height: 0;
                padding: 0 32px;
            }

            @media (min-width: $screen-fm) {
                font-size: 30px;
                margin-top: 45px;
            }
        }

        .report-title {
            color: $white;
            font-size: 18px;
            line-height: 1.39;

            @media #{$desktop-sm} {
                font-size: 24px;
                line-height: 1.5;
            }

            @media (min-width: $screen-lg) {
                font-size: 20px;
            }

            @media (min-width: $screen-fm) {
                font-size: 30px;
            }
        }

        .category-title {
            display: none;
            font-family: 'DIN Next LT Arabic';
            font-size: 14px;
            line-height: 1.5;
            color: $osloGray;

            @media (min-width: $screen-sm) {
                font-size: 16px;
            }
        }

        .report-link,
        .report-link::after,
        .report-link-content {
            @include forAllTransition(0.2s);

            display: none;

            @media (min-width: $screen-lg) {
                display: inline-block;
                font-size: 18px;
                line-height: 1.67;
                text-align: left;
            }

            @media (min-width: $screen-fm) {
                font-size: 24px;
                font-weight: 300;
                line-height: normal;
            }
        }

        .report-link {
            color: $brightTurquoise;

            @media (min-width: $screen-lg) {
                margin-left: 50px;
                margin-bottom: 35px;
            }

            .icon-arrow {
                @include forAllTransition(0.2s);
                font-size: 12px;
                margin-right: 7px;

                &:hover {
                    margin-right: 15px;
                }

                &::before {
                    display: none;
                }
            }

            .report-link-content:hover {
                color: darken($brightTurquoise, 15%);
                margin-left: 8px;
            }
        }
    }

    &[data-category='location'] {
        .graph-content {
            margin-top: 15px;
            padding-bottom: 45px;

            @media (min-width: $screen-sm) {
                margin-top: 35px;
            }

            @media (min-width: $screen-lg) {
                height: 100% !important;
                margin-top: 70px;
            }

            @media (min-width: $screen-fm) {
                margin-bottom: 20px;
            }
        }
    }
}
