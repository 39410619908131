.stories {
    .container-fluid {
        &.home {
            .arrow-scroll {
                margin-top: 140px;

                &:active {
                    margin-top: 142px;
                }
            }
        }
    }
}
