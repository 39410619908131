.commercial-establishments-chart-container {
    @media #{$small-mobile} {
        .chart-container-wrapper {
            .main-panel {
                .mobile-column {
                    .graph-container {
                        .chart-container {
                            height: 235px;
                            min-height: 235px;
                        }

                        .graph-content {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }

    @media #{$standard-mobile} {
        .chart-container-wrapper {
            .main-panel {
                .source-column {
                    padding-top: 13px;
                }
            }
        }
    }
}
