.header-png-pdf {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    top: 40px;
    left: 98px;

    img {
        object-fit: contain
    }
    .header-logo {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        position: absolute;
        left: 0;

        .logo {
            display: flex;
            flex-direction: column-reverse;
        }

        .zadd-logo {
            margin-left: 40px;
            border-left: 1.5px solid white;
            padding-left: 40px;
        }
    }

    .header-section {
        border-bottom: solid 2px #2aefc3;
        width: fit-content;
        max-width: 60vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-bottom: 16px;
        padding-left: 8em;
        padding-right: 4em;
        align-items: center;

        .section-title {
            color: white;
            font-size: 30px;
            margin-right: 16px;
        }

        .icon {
            height: 54px;
            margin-right: 80px;
            margin-left: 20px;
        }
    }
}
