.category-filter {
    overflow: visible;
    padding-bottom: 22px;
    position: relative;

    @include landscape-sm {
        border-left: 1px solid $charcoalGrey;
        float: right;
        padding-left: 20px;
    }

    @include landscape-lg {
        border-left: 1px solid $charcoalGrey;
        float: right;
        padding-left: 20px;
    }

    .category-list-heading {
        color: $white;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 22px;
        margin-top: 0;
        text-align: right;
        white-space: nowrap;

        @media #{$desktop-sm} {
            margin-bottom: 32px;
        }

        @media (min-width: $screen-lg) {
            margin-bottom: 18px;
        }

        @media (min-width: $screen-fm) {
            margin-bottom: 24px;
        }

        @media (min-width: $screen-hd) {
            margin-bottom: 20px;
        }
    }

    .category-lists {
        .categories-list {
            list-style-type: none;
            margin: 0;
            max-height: 284px;
            padding-right: 0;
            position: relative;

            @media #{$small-mobile} {
                max-height: 33vh;
            }

            @media #{$tablet} {
                max-height: 500px;
            }

            @media (min-width: $screen-lg) {
                max-height: 200px;
            }

            @media (min-width: $screen-fm) {
                max-height: 222px;
            }

            @media (min-width: $screen-hd) {
                max-height: 220px;
            }

            @include categoryColors('.color-', $categories-colors, true);

            .category-element {
                -webkit-align-items: flex-start;
                align-items: flex-start;
                -webkit-align-self: baseline;
                align-self: baseline;
                cursor: pointer;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
                font-size: 14px;
                justify-content: flex-end;
                line-height: 2.31;
                padding-bottom: 13.5px;
                position: relative;
                width: 173px;

                @media #{$standard-mobile} {
                    padding-bottom: 14px;
                    width: 192px;
                }

                @media (min-width: $screen-sm) {
                    font-size: 16px;
                    line-height: 1.8;
                    width: inherit;
                }

                @media (min-width: $screen-lg) and (max-width: $screen-fm) {
                    font-size: 14px;
                }

                @media (min-width: $screen-hd) {
                    font-size: 18px;
                    line-height: 2.06;
                }

                &.disabled {
                    cursor: auto;

                    .category-element-value,
                    .category-element-icon,
                    .category-element-marker {
                        opacity: 0.5;
                    }
                }

                &.only-active,
                &.non-interactive {
                    cursor: auto;
                }

                .category-element-value {
                    color: $osloGray;
                    line-height: 1.57;
                    transition: 0.2s all ease-in-out;

                    @media (min-width: $screen-sm) and (max-width: $screen-lg - 1) {
                        line-height: 1.38;
                    }

                    @media (min-width: $screen-hd) {
                        line-height: 1.22;
                    }
                }

                .category-element-marker {
                    border: 1px solid $osloGray;
                    border-radius: 100px;
                    height: 10px;
                    margin-left: 10px;
                    margin-top: 7px;
                    min-width: 15px;
                    transition: 0.2s all ease-in-out;

                    @media (min-width: $screen-fm) {
                        margin-top: 9px;
                    }

                    @media (min-width: $screen-hd) {
                        margin-top: 8px;
                    }
                }

                //styles for Subsector Report

                &.has-border {
                    align-items: center;
                    border-top: 1px solid $charcoalGrey;
                    margin-bottom: 0;
                    min-height: 118px;

                    @media (min-width: $screen-sm) {
                        min-height: 162px;
                    }

                    @media (min-width: $screen-lg) {
                        min-height: 108px;
                    }

                    @media (min-width: $screen-1680) {
                        min-height: 123px;
                    }

                    @media (min-width: $screen-fhd) {
                        min-height: 133.8px;
                    }

                    &:first-of-type {
                        border-top: none;
                    }
                }

                .category-element-icon {
                    font-size: 40px;
                    margin-left: 10px;
                    z-index: -1;

                    @media (min-width: $screen-sm) {
                        font-size: 45px;
                        margin-left: 12px;
                    }

                    @media (min-width: $screen-lg) {
                        font-size: 42px;
                        margin-left: 10px;
                    }

                    @media (min-width: $screen-1680) {
                        font-size: 45px;
                    }

                    @media (min-width: $screen-fhd) {
                        margin-left: 15px;
                    }

                    &::before {
                        color: $osloGray;
                    }
                }

                &:nth-of-type(2) {
                    .category-element-icon {
                        font-size: 50px;

                        @media (min-width: $screen-sm) {
                            font-size: 55px;
                        }

                        @media (min-width: $screen-lg) {
                            font-size: 50px;
                        }

                        @media (min-width: $screen-1680) {
                            font-size: 55px;
                        }
                    }
                }

                &:nth-of-type(3) {
                    .category-element-icon {
                        font-size: 42px;
                    }
                }
            }
        }
    }

    .nano {
        width: calc(100% + 15px);

        @media #{$desktop-sm} {
            width: calc(100% + 30px);
        }

        @media (min-width: $screen-lg) {
            width: calc(100% + 15px);
        }

        .nano-content {
            margin-left: -15px;
            margin-right: 0 !important;
            width: 100%;

            @-moz-document url-prefix() {
                width: calc(100% + 15px);
            }

            &:focus {
                outline: none;
            }
        }

        & > .nano-pane {
            background: $brightGray2;
            left: 0;
            right: inherit;
            width: 4px;

            & > .nano-slider {
                background: $brightTurquoise3;
                border-radius: 5px;
                margin: 0;
                width: 4px;
            }
        }
    }

    .message-container {
        left: 0;
        position: absolute;
        right: 0;
        top: 34px;

        @media (min-width: $screen-sm) {
            left: inherit;
            right: -184px;
            top: 0;
        }

        &.hidden {
            display: none;
        }

        .triangle {
            background-color: $cabaret;
            border-radius: 3px;
            height: 20px;
            left: 50%;
            margin-left: 10px;
            position: absolute;
            top: -5px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 20px;

            @media (min-width: $screen-sm) {
                left: 0;
                top: 4px;
            }
        }

        .message {
            background-color: $cabaret;
            border-radius: 3px;
            color: $white;
            display: inline-block;
            font-size: 14px;
            padding: 5px 13px 5px 13px;
            position: relative;
            white-space: nowrap;

            @media (min-width: $screen-sm) {
                left: 13px;
                top: 0;
            }
        }
    }

    .category-disabled-message-container {
        left: auto;
        right: auto;
        top: 100%;
        z-index: 1;

        @media (min-width: $screen-sm) {
            left: 100%;
            top: auto;
        }

        .triangle {
            margin-left: -10px;

            @media (min-width: $screen-sm) {
                margin-left: 10px;
                top: 7px;
            }
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}

.line-chart-container {
    .category-lists {
        .categories-list {
            @include categoryColors('.color-', $categories-colors-wo-average, false);
        }
    }
}

.chart-side-menu.sector-level-2 .category-lists .categories-list {
    @include categoryColors('.color-', $radar-chart-homepage-colors-group-3, false);
}

._chart_mg_add:not([data-is-sector='1']) {
    .rectangle-container {
        padding-bottom: 16px;
        padding-top: 0;

        .category-filter {
            padding: 0;

            .category-lists {
                overflow: visible;

                .categories-list {
                    overflow: visible;

                    &.max-height {
                        max-height: 100vh;
                    }
                }
            }
        }

        .category-list-heading {
            margin-bottom: 0;
            margin-top: 30px;

            @media (min-width: $screen-1680) {
                margin-top: 38px;
            }

            @media (min-width: $screen-fhd) {
                margin-top: 48px;
            }
        }
    }

    .sidemenu-column .chart-side-menu .aside-arrow {
        @media (min-width: $screen-lg) {
            display: block;
        }
    }
}

._chart_mg_add[data-is-lvl3-sub-sector='1']:not([data-is-trade-report='1']) {
    .rectangle-container {
        padding-right: 22px;

        @media (min-width: $screen-sm) {
            padding-right: 20px;
            padding-top: 5px;
        }

        @media (min-width: $screen-1680) {
            padding-bottom: 10px;
            padding-right: 28px;
        }

        @media (min-width: $screen-fhd) {
            padding-left: 25px;
        }

        .category-filter {
            .category-list-heading {
                color: $white;
                margin-bottom: 0;
                margin-top: 0;
                position: absolute;
                top: 202px;

                @media (min-width: $screen-sm) {
                    top: 190px;
                }
            }

            .category-lists {
                .category-element {
                    min-height: 164px;
                    width: 95%;

                    &.level-3 span {
                        transition-duration: 0s;
                    }

                    @media (min-width: $screen-sm) {
                        min-height: 152px;
                    }

                    &:nth-of-type(2) .category-element-icon,
                    .category-element-icon {
                        font-size: 43px;
                        margin-left: 12px;

                        @media (min-width: $screen-sm) {
                            margin-left: 18px;
                        }

                        @media (min-width: $screen-lg) {
                            font-size: 44px;
                            margin-left: 14px;
                        }

                        @media (min-width: $screen-1680) {
                            margin-left: 18px;
                        }
                    }

                    .category-element-icon {
                        display: flex;
                        justify-content: center;

                        .icon-svg {
                            max-height: 58px;
                            pointer-events: none;
                            user-select: none;
                            width: 48px;

                            @media (min-width: $screen-lg) {
                                max-height: 60px;
                                width: 50px;
                            }
                        }
                    }

                    .category-element-value {
                        align-items: center;
                        display: flex;
                        line-height: 1.71;
                        min-height: 72px;

                        @media (min-width: $screen-sm) {
                            line-height: 1.88;
                        }

                        @media (min-width: $screen-lg) {
                            line-height: 1.71;
                        }

                        @media (min-width: $screen-1680) {
                            line-height: 1.88;
                        }

                        @media (min-width: $screen-fhd) {
                            line-height: 1.67;
                        }
                    }

                    &:nth-child(2) {
                        padding-top: 78px;

                        @media (min-width: $screen-sm) {
                            padding-top: 90px;
                        }

                        @media (min-width: $screen-lg) {
                            min-height: 220px;
                            padding-top: 48px;
                        }

                        @media (min-width: $screen-1680) {
                            min-height: 195px;
                            padding-top: 52px;
                        }
                    }
                }
            }
        }
    }
}

._chart_mg_add[data-is-trade-report='1'] {
    .rectangle-container {
        padding-right: 25px;

        @media (min-width: $screen-fm) {
            padding-right: 33px;
        }

        .category-filter {
            .category-list-heading {
                margin-bottom: 12px;

                @media (min-width: $screen-sm) {
                    margin-bottom: 9px;
                }

                @media (min-width: $screen-lg) {
                    margin-bottom: 8px;
                }

                @media (min-width: $screen-fm) {
                    margin-bottom: 14px;
                }

                @media (min-width: $screen-hd) {
                    margin-bottom: 18px;
                }
            }

            .category-lists {
                @include categoryColors('.color-', $trade-report-radar-chart-colors, true);

                overflow: visible;

                .categories-list {
                    overflow: visible;

                    .category-element {
                        min-height: 75px;
                        padding-right: 12px;
                        width: 206px;

                        @media (min-width: $screen-sm) {
                            min-height: 112px;
                            padding-right: 22px;
                            width: 250px;
                        }

                        @media (min-width: $screen-lg) {
                            min-height: 85px;
                            padding-right: 12px;
                            width: 206px;
                        }

                        @media (min-width: $screen-fm) {
                            min-height: 83px;
                            padding-right: 22px;
                            width: 250px;
                        }

                        @media (min-width: $screen-hd) {
                            min-height: 95px;
                        }

                        &:nth-of-type(1) {
                            .category-element-icon {
                                font-size: 31px;
                            }
                        }

                        &:nth-of-type(2) {
                            .category-element-icon {
                                font-size: 38.605px;
                            }
                        }

                        &:nth-of-type(3) {
                            .category-element-icon {
                                font-size: 32.4px;
                            }
                        }

                        &:nth-of-type(4) {
                            .category-element-icon {
                                font-size: 30.23px;
                            }
                        }

                        .category-element-icon {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}

.foreign-trade-report,
.region-report {
    .category-list-heading {
        margin-top: 30px !important;
        margin-bottom: 25px !important;
    }
}
